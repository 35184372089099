import styled from 'styled-components';
import { createGlobalStyle } from 'styled-components/macro';
import { Z_INDEX_LAYERS } from '../../componentUI';
import { adaptive, truthfulAdaptive } from '../../componentUI/screen';

export const BaseContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 5fr;
  width: 100%;

  ${truthfulAdaptive.maxWidth.desktopS} {
    grid-template-columns: none;
    width: 100vw;
  }
`;

export const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 0;
  max-width: 1536px;
  width: 100%;

  ${adaptive.minWidth.mega} {
    justify-self: center;
  }
`;

export const BreadCrumbsWrapper = styled.div<{ isSummaryBlock: boolean | undefined }>`
  padding: ${({ isSummaryBlock }) => (isSummaryBlock ? '0 40px 0 40px' : '20px 40px 0 40px')};
`;

export const ContentContainer = styled.div<{ isSummaryBlock: boolean | undefined; isNotDvh: boolean | undefined }>`
  display: flex;
  flex-direction: column;

  padding: ${({ isSummaryBlock }) => (isSummaryBlock ? '0 0 0 0' : '24px 40px 40px 40px')};

  ${adaptive.maxWidth.desktopS} {
    padding: 16px;
    height: ${({ isNotDvh }) => (isNotDvh ? 'auto' : '100dvh')};
  }
`;

export const Overlay = styled.div<{ isColumnMenuOpen: boolean }>`
  position: fixed;
  inset: 0;
  z-index: ${Z_INDEX_LAYERS.menusLayer.overlayLayer};

  display: ${({ isColumnMenuOpen }) => (isColumnMenuOpen ? 'block' : 'none')};

  background: rgba(0, 0, 0, 0.29);

  overflow-y: scroll;
`;

export const GlobalStyleFromPage = createGlobalStyle`
  body {
    overflow: hidden;
  }
`;

import { useQuery } from '@tanstack/react-query';
import { useDispatch } from 'react-redux';

import { QUERY_KEY } from '../../../common';
import { addToast } from '../../../redux/reducers/toast-reducer';
import { getBrandsList } from '../brands-api';

type ErrorType = {
  response: {
    data: {
      message: string;
    };
  };
};

export const useGetBrandsListQuery = () => {
  const dispatch = useDispatch();

  return useQuery({
    queryKey: [QUERY_KEY.useGetBrandsListQuery],
    queryFn: async () => {
      const { data } = await getBrandsList({});

      return data;
    },
    // список брендов статичен, перезапрос не требуется
    cacheTime: Infinity,
    staleTime: Infinity,

    onError: (error: ErrorType) => {
      dispatch(
        addToast({
          text: error?.response?.data?.message || 'Something is wrong, please try again.',
        }),
      );
    },
  });
};

import React from 'react';

import { COLOR_MAP } from '../../../../componentUI/colorsMap';
import { TEXT_SIZE, TEXT_WEIGHT, Text } from '../../../../componentUI/simple/Text';
import { PasswordStrength, ValidPassText } from './components';

import { ThemeEnum } from '../../CheckPassword';

import {
  DescriptionConditionsStyled,
  VisualizationPassSecurityStyled,
  WrapperDescriptionConditionsStyled,
  WrapperPasswordStrengthStyled,
} from './styled';

type Props = {
  infoValidPass: {
    isLength: boolean;
    isNumber: boolean;
    isSpecialSymbol: boolean;
    isLowerCase: boolean;
    isUpperCase: boolean;
  };
  password: string;
  designTheme: ThemeEnum;
};

const themeSettings = {
  black: COLOR_MAP.text.black,
  white: COLOR_MAP.text.white,
};

export const VisualizationPassSecurity = ({ infoValidPass, password, designTheme }: Props) => {
  const { isLength, isNumber, isSpecialSymbol, isLowerCase, isUpperCase } = infoValidPass;

  return (
    <VisualizationPassSecurityStyled>
      <WrapperDescriptionConditionsStyled>
        <Text size={TEXT_SIZE.sub} weight={TEXT_WEIGHT.bold} colorText={themeSettings[designTheme]}>
          Password must contain at least:
        </Text>

        <DescriptionConditionsStyled>
          <div>
            <ValidPassText designTheme={designTheme} verificationStatus={isLength} messageText="8 characters" />
            <ValidPassText designTheme={designTheme} verificationStatus={isNumber} messageText="One number" />
            <ValidPassText
              designTheme={designTheme}
              verificationStatus={isSpecialSymbol}
              messageText="One special character !@#$%^&"
            />
          </div>

          <div>
            <ValidPassText
              designTheme={designTheme}
              verificationStatus={isLowerCase}
              messageText="One lowercase letter"
            />
            <ValidPassText
              designTheme={designTheme}
              verificationStatus={isUpperCase}
              messageText="One uppercase letter"
            />
          </div>
        </DescriptionConditionsStyled>
      </WrapperDescriptionConditionsStyled>

      <WrapperPasswordStrengthStyled>
        <Text size={TEXT_SIZE.sub} weight={TEXT_WEIGHT.bold} colorText={themeSettings[designTheme]}>
          Password strength:
        </Text>

        <PasswordStrength password={password} />
      </WrapperPasswordStrengthStyled>
    </VisualizationPassSecurityStyled>
  );
};

import { AuthPageType } from '../../../../common/constants';

// обозначение страниц на которых встречается элемент AuthenticationsTablet
const { all, photosNeeded, underReview, completed, drafts, dashboard } = AuthPageType;

const locale: { [key: string]: string } = {
  [all]: 'All',
  [completed]: 'Completed',
  [underReview]: 'Under review',
  [photosNeeded]: 'Photos needed',
  [drafts]: 'Drafts',
  [dashboard]: 'Dashboard',
};

export { locale };

/* eslint-disable react/no-array-index-key */
import React from 'react';

import { COLOR_MAP } from '../../../../componentUI/colorsMap';
import { SecondaryLinkButton } from '../../../../componentUI/simple/Button';
import { TEXT_WEIGHT, Text } from '../../../../componentUI/simple/Text';
import { TITLE_SIZE, TITLE_WEIGHT, Title } from '../../../../componentUI/simple/Title';

import { useMatchMedia } from '../../../../hooks';

import { CreditIconV2 } from '../../../../common/icons/SvgInline/SvgIcons_10';

import {
  CircleMarkerOldBalanceStyled,
  ConvertedBalanceModalStyled,
  InformationalTextStyled,
  SeparatorBalanceStyled,
  SeparatorFormStyled,
  WrapperBonusStyled,
  WrapperConvertBalanceBonusElementStyled,
  WrapperConvertBalanceElementStyled,
  WrapperConvertCreditStyled,
  WrapperConvertTotalBalanceElementStyled,
  WrapperNewBalanceStyled,
  WrapperOldBalanceStyled,
  WrapperTitleStyled,
  stylesForButtons,
} from './styled';
import { Props } from './types';

export const ConvertedBalanceModalNewUser = ({ PromoPackagesActivateData }: Props) => {
  const { isMobile } = useMatchMedia();

  const { packages, totalCredits } = PromoPackagesActivateData;

  return (
    <ConvertedBalanceModalStyled>
      <WrapperTitleStyled>
        <CreditIconV2 />
        <Title weight={TITLE_WEIGHT.normal} size={TITLE_SIZE.h5}>
          Welcome to the LegitGrails Platform! Your authentications will be converted to the LegitGrails Credits, our
          unified internal currency.
        </Title>
      </WrapperTitleStyled>

      <SeparatorFormStyled />

      <WrapperConvertCreditStyled>
        {packages.map((element, index) => {
          const { brandType, answerTime, volume, price, total } = element;

          return brandType.toLowerCase() !== 'bonus' ? (
            <WrapperConvertBalanceElementStyled key={index}>
              <WrapperOldBalanceStyled>
                <CircleMarkerOldBalanceStyled />
                <Text>
                  {brandType}, {answerTime}
                </Text>
                <Text weight={TEXT_WEIGHT.bold}>{volume}</Text>
              </WrapperOldBalanceStyled>

              {!isMobile && <SeparatorBalanceStyled />}

              <WrapperNewBalanceStyled>
                <Text>
                  &nbsp;&nbsp;=&nbsp;&nbsp;{price}&nbsp;&nbsp;x&nbsp;&nbsp;{volume}&nbsp;&nbsp;=&nbsp;&nbsp;
                  <strong>{total}</strong>&nbsp;&nbsp;LG&nbsp;&nbsp;Credits
                </Text>
              </WrapperNewBalanceStyled>
            </WrapperConvertBalanceElementStyled>
          ) : (
            <>
              <WrapperBonusStyled>
                <SeparatorFormStyled />
                <WrapperConvertBalanceBonusElementStyled>
                  <Text colorText={COLOR_MAP.accent.green}>+ Conversion bonus</Text>

                  <SeparatorBalanceStyled />

                  <Text colorText={COLOR_MAP.accent.green}>
                    <strong>{total}</strong> LG Credits
                  </Text>
                </WrapperConvertBalanceBonusElementStyled>
              </WrapperBonusStyled>
            </>
          );
        })}
      </WrapperConvertCreditStyled>

      <WrapperConvertTotalBalanceElementStyled>
        <Text weight={TEXT_WEIGHT.bold}>Total you will get</Text>

        <SeparatorBalanceStyled />

        <Text>
          <strong>{totalCredits}</strong> LG Credits
        </Text>
      </WrapperConvertTotalBalanceElementStyled>

      <InformationalTextStyled>
        <Text colorText={COLOR_MAP.text.lightBlue}>
          Don&apos;t worry, you can use your credits as usual for submitting authentications!
        </Text>
      </InformationalTextStyled>

      <SecondaryLinkButton
        style={stylesForButtons}
        isStretch
        href="https://legitgrails.zendesk.com/hc/en-us/articles/18187613171345"
        target="_blank"
        rel="noreferrer"
      >
        LEARN MORE
      </SecondaryLinkButton>
    </ConvertedBalanceModalStyled>
  );
};

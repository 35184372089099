import { createAction, createReducer } from '@reduxjs/toolkit';

export const setProductTypes = createAction('SET_PRODUCT_TYPES');
export const setProduct = createAction('SET_PRODUCT');
export const setProducts = createAction('SET_PRODUCTS');
export const setResultStatuses = createAction('SET_RESULT_STATUSES');
export const setAnglesList = createAction('SET_ANGLES_LIST');
export const setBrandsList = createAction('SET_BRANDS');
export const setCheckStatuses = createAction('SET_CHECK_STATUSES');

const initialState = {
  types: [],
  product: null,
  products: { data: null, isError: false, isLoading: false, isLoaded: false },
  resultStatuses: null,
  anglesList: null,
  brandsList: null,
  brandId: null,
  checkStatuses: null,
  categoryList: null,
};

export const productReducer = createReducer(initialState, (builder) => {
  builder
    .addCase('SET_PRODUCT_TYPES', (state = initialState, action) => {
      state.types = action.payload;
    })
    .addCase('SET_PRODUCT', (state = initialState, action) => {
      state.product = action.payload;
    })
    .addCase('SET_PRODUCTS', (state = initialState, action) => {
      // подпорка т.к. в миллионе мест сабмитят null - сбрасывам на первоначальное состояние
      if (action.payload === null) {
        state.products = { data: null, isError: false, isLoading: false, isLoaded: false };
        return;
      }
      state.products = action.payload;
    })
    .addCase('SET_RESULT_STATUSES', (state = initialState, action) => {
      state.resultStatuses = action.payload;
    })
    .addCase('SET_ANGLES_LIST', (state = initialState, action) => {
      state.anglesList = action.payload;
    })
    .addCase('SET_BRANDS', (state = initialState, action) => {
      state.brandsList = action.payload;
    })
    .addCase('SET_CHECK_STATUSES', (state = initialState, action) => {
      state.checkStatuses = action.payload;
    });
});

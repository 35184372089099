import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { useMatchMedia } from '../../hooks';

import { COLOR_MAP } from '../../componentUI/colorsMap';
import { TAG_NAME } from '../../componentUI/constants';
import { TEXT_SIZE, TEXT_WEIGHT, Text } from '../../componentUI/simple/Text';

import { LinkStyle, WrapperArrow, WrapperBreadCrumbs, WrapperLinksContainer } from './styled';

const RightArrow = () => {
  return (
    <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M2.5 6H9.5" stroke="#AAAAAA" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M6 2.5L9.5 6L6 9.5" stroke="#AAAAAA" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
};

const pageName: { [key: string]: string } = {
  dashboard: 'Dashboard',
  authentications: 'Authentications',
  card: 'Order details',
  all: 'All',
  'photos-needed': 'Photos needed',
  'under-review': 'Under review',
  completed: 'Completed',
  'account-settings': 'Settings',
  'general-info': 'General info',
  'billing-info': 'Billing info',
  'receipts-and-invoices': 'Receipts & Invoices',
  discounts: 'Discounts ',
  subscriptions: 'LegitGrails+',
  certificate: 'Certificate',
  notifications: 'Notifications',
  'top-up': 'Add Balance',
  success: 'Add Balance',
  unsuccess: 'Add Balance',
  authenticate: 'New Authentication',
  'business-verification': 'Business Verification Form',
  'guarantee-request': 'Financial guarantee request',
  transactions: 'Transactions',
  balance: 'Balance',
};

const getNumberLinks = (arrNameBreadCrumbs: Array<string>) => {
  if (arrNameBreadCrumbs.includes('dashboard') || arrNameBreadCrumbs.includes('card')) {
    return arrNameBreadCrumbs.length - 1;
  }
  return arrNameBreadCrumbs.length;
};

export const BreadCrumbs = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { isMobile } = useMatchMedia();

  const isDashboardPage = pathname.includes('dashboard');

  const arrNameBreadCrumbs = pathname.split('/').slice(1);

  const hideDashboardBreadcrumb = isMobile && isDashboardPage;

  const clickHandler = (indexUrl: number, allUrl: number) => {
    if (indexUrl + 1 === allUrl) {
      return;
    }
    navigate('/authentications/all');
  };

  return (
    <WrapperBreadCrumbs>
      {!hideDashboardBreadcrumb && (
        <Text
          onClick={() => navigate('/')}
          tag={TAG_NAME.span}
          colorText={COLOR_MAP.text.black}
          size={TEXT_SIZE.sub}
          weight={TEXT_WEIGHT.normal}
          style={{ cursor: 'pointer' }}
        >
          Dashboard
        </Text>
      )}

      {arrNameBreadCrumbs.map((element, index) => {
        const numberLinksValue = getNumberLinks(arrNameBreadCrumbs);

        return (
          Boolean(numberLinksValue) &&
          pageName[element] && (
            <WrapperLinksContainer key={element}>
              <WrapperArrow>
                <RightArrow key={element} />
              </WrapperArrow>

              <LinkStyle
                style={{ cursor: index + 1 !== numberLinksValue ? 'pointer' : '' }}
                onClick={() => clickHandler(index, numberLinksValue)}
              >
                <Text
                  tag={TAG_NAME.span}
                  colorText={COLOR_MAP.text.darkGrey}
                  size={TEXT_SIZE.sub}
                  weight={TEXT_WEIGHT.normal}
                >
                  {pageName[element]}
                </Text>
              </LinkStyle>
            </WrapperLinksContainer>
          )
        );
      })}
    </WrapperBreadCrumbs>
  );
};

import React from 'react';

import { useGoogleAnalytics } from '../../../../../analytics';

import { useMatchMedia } from '../../../../../hooks';

import { TermsBlock } from '../../../../../components';

import { COLOR_MAP } from '../../../../../componentUI/colorsMap';
import { Picture } from '../../../../../componentUI/complex/Picture';
import { Text } from '../../../../../componentUI/simple/Text';

import { email24logo } from '../../../assets';

import { LogoBlock, TitleBlock } from '../..';

import { ContainerStyled, SeparatorStyled, WrapperMainStyled, WrapperTextStyled } from './styled';

export const ScreenSignUpEmailSuccess = () => {
  useGoogleAnalytics();
  const { isMobile } = useMatchMedia();

  return (
    <ContainerStyled>
      <LogoBlock />

      <WrapperMainStyled>
        <Picture url={email24logo} width={isMobile ? 180 : 225} height={isMobile ? 160 : 200} alt="email logo" />

        <WrapperTextStyled>
          <TitleBlock titleText="Excellent!" subtitleText="You are now registered" />

          <SeparatorStyled />

          <Text colorText={COLOR_MAP.text.white}>
            We sent you an email to confirm your email address, please check your inbox.
            <br />
            You can do this within 24 hours.
          </Text>
        </WrapperTextStyled>
      </WrapperMainStyled>

      <TermsBlock />
    </ContainerStyled>
  );
};

import Cookies from 'js-cookie';
import { instance } from '../axios-instance';
// eslint-disable-next-line import/no-cycle
import {
  addCertificateOfCardProductResponseType,
  addCertificateOfCardProductType,
  AuthenticationPriceRequestBody,
  AuthenticationPriceResponse,
  CancelUnableProvideRequestType,
  CreateDraftDataType,
  CreateDraftRequestType,
  CreateProductRequestType,
  CreateProductResponseType,
  DeleteDraftRequestType,
  GetCheckStatusesResponse,
  GetFreeCustomCertificatesResponseType,
  GetProductResponseType,
  GetProductsRequestType,
  GetProductTypeRequestType,
  GetProductTypeResponseType,
  GetProductTypesRequestType,
  GetProductTypesResponseType,
  GetStatisticsResponse,
  ModelsListResponse,
  ProductsResponseType,
  ResultStatusType,
  UpdateCertificatePathRequestType,
  UpdateDraftRequestType,
  UpdateProductRequestType,
  UpdateProductResponseType,
  UploadRequestBody,
} from './type';

export const getProductTypes = (data: GetProductTypesRequestType) => {
  return instance.get<GetProductTypesResponseType>(
    `product-types?page=${data.page}&limit=${data.limit}&id=${data.currentProductTypeId}`,
    {
      headers: { Authorization: `Bearer ${Cookies.get('jwt')}` },
    },
  );
};

export const getProductTypeById = (data: GetProductTypeRequestType) => {
  const { productTypeId, brandId } = data;

  return instance.get<GetProductTypeResponseType>(`product-types/${productTypeId}?brandId=${brandId}`, {
    headers: { Authorization: `Bearer ${Cookies.get('jwt')}` },
  });
};

export const getProduct = (id: string) => {
  return instance.get<GetProductResponseType>(`products/${id}`, {
    headers: { Authorization: `Bearer ${Cookies.get('jwt')}` },
  });
};

export const getProducts = (data: GetProductsRequestType) => {
  return instance.post<ProductsResponseType>(
    'products/get-products',
    { page: 1, limit: 100, ...data },
    { headers: { Authorization: `Bearer ${Cookies.get('jwt')}` } },
  );
};

export const getCSV = (data: Partial<GetProductsRequestType>) => {
  return instance.post<{ data: string }>(
    'products/get-csv',
    { ...data },
    { headers: { Authorization: `Bearer ${Cookies.get('jwt')}` } },
  );
};

export const getResultsStatuses = () => {
  return instance.get<{ data: Array<ResultStatusType> }>('result-statuses', {
    params: { page: 1, limit: 100 },
    headers: { Authorization: `Bearer ${Cookies.get('jwt')}` },
  });
};

export const getAllAngles = () => {
  return instance.get('angles', {
    params: { page: 1, limit: 100 },
    headers: { Authorization: `Bearer ${Cookies.get('jwt')}` },
  });
};

export const addCertificateOfCardProduct = (data: addCertificateOfCardProductType) => {
  return instance.patch<addCertificateOfCardProductResponseType>('products/add-certificate', data, {
    headers: { Authorization: `Bearer ${Cookies.get('jwt')}` },
  });
};

export const updateProduct = ({ productId, dataRequest }: UpdateProductRequestType) => {
  return instance.patch<UpdateProductResponseType>(`products/${productId}`, dataRequest, {
    headers: { Authorization: `Bearer ${Cookies.get('jwt')}` },
  });
};

export const getCategoryList = (id: string) => {
  return instance.get(`brands/${id}`, {
    headers: { Authorization: `Bearer ${Cookies.get('jwt')}` },
  });
};

export const getStatistics = (headers = {}) => {
  return instance.get<GetStatisticsResponse>('products/get-dashboard', {
    params: { ...headers },
    headers: { Authorization: `Bearer ${Cookies.get('jwt')}` },
  });
};

export const getCheckStatuses = () => {
  return instance.get<GetCheckStatusesResponse>('check-statuses', {
    headers: { Authorization: `Bearer ${Cookies.get('jwt')}` },
  });
};

// @ts-ignore
export const getModelsList = (brandId, productTypeId, page?, limit?, sort?, search?) => {
  return instance.get<ModelsListResponse>('models', {
    params: { limit: limit || 600, page: page || 1, sort, search, brandId, productTypeId },
    headers: { Authorization: `Bearer ${Cookies.get('jwt')}` },
  });
};

export const getFreeCustomCertificates = () => {
  return instance.get<GetFreeCustomCertificatesResponseType>('/products/count-used-free-custom-certificates', {
    headers: { Authorization: `Bearer ${Cookies.get('jwt')}` },
  });
};

export const uploadRequestPhoto = (data: UploadRequestBody) => {
  return instance.patch('products/upload-requested-photos', data, {
    headers: { Authorization: `Bearer ${Cookies.get('jwt')}` },
  });
};

export const cancelUnableProvide = ({ productId }: CancelUnableProvideRequestType) => {
  return instance.patch(
    'products/cancel/unable-provide',
    { id: productId },
    {
      headers: { Authorization: `Bearer ${Cookies.get('jwt')}` },
    },
  );
};

export const getAuthenticationPrice = (data: AuthenticationPriceRequestBody) => {
  return instance.get<AuthenticationPriceResponse>('/products/get-authentication-price', {
    params: { ...data },
    headers: { Authorization: `Bearer ${Cookies.get('jwt')}` },
  });
};

export const addQrLabelProduct = (id: string) => {
  return instance.patch(
    '/products/add-item-label',
    { productId: id },
    {
      headers: { Authorization: `Bearer ${Cookies.get('jwt')}` },
    },
  );
};

export const updateCertificatePath = (body: UpdateCertificatePathRequestType) => {
  return instance.patch<{}>('/products/update-certificate', body, {
    headers: { Authorization: `Bearer ${Cookies.get('jwt')}` },
  });
};

export const createProduct = (data: CreateProductRequestType) => {
  return instance.post<CreateProductResponseType>('/products', data, {
    headers: { Authorization: `Bearer ${Cookies.get('jwt')}` },
  });
};

export const createDraft = (data: CreateDraftRequestType) => {
  return instance.post<CreateDraftDataType>('/products/create-draft', data, {
    headers: { Authorization: `Bearer ${Cookies.get('jwt')}` },
  });
};

export const updateDraft = (data: UpdateDraftRequestType) => {
  return instance.patch<{}>('/products/update-draft', data, {
    headers: { Authorization: `Bearer ${Cookies.get('jwt')}` },
  });
};

export const deleteDraft = (data: DeleteDraftRequestType) => {
  return instance.delete<{}>('/products/delete-draft', {
    params: { ids: data },
    headers: { Authorization: `Bearer ${Cookies.get('jwt')}` },
  });
};

import { EmptyAuthentications, EmptyDraft } from '../../../../common/images/NoRecordsFound';
import { PresetValuesTypes } from './types';

const PRESET_VALUES: PresetValuesTypes = {
  drafts: {
    picture: EmptyDraft,
    text: 'Looks like you don’t have any saved authentication requests in draft',
  },
  all: {
    picture: EmptyAuthentications,
    text: 'Looks like you don’t have any authentications here',
  },
  'photos-needed': {
    picture: EmptyAuthentications,
    text: 'Looks like you don’t have any authentications here',
  },
  'under-review': {
    picture: EmptyAuthentications,
    text: 'Looks like you don’t have any authentications here',
  },
  completed: {
    picture: EmptyAuthentications,
    text: 'Looks like you don’t have any authentications here',
  },
};

export { PRESET_VALUES };

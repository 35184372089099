import { uploadPhotoForProduct } from '../../api';
import { NotificationType } from '../../componentUI/types';
import { setErrors, setStatusCode } from '../reducers';
import { addToast } from '../reducers/toast-reducer';

export const uploadPhotoForProductThunk = (data, count, idx) => async (dispatch) => {
  const formData = new FormData();
  formData.append('productId', data.productId);
  formData.append('angleId', data.angleId);
  formData.append('photo', data.file);
  formData.append('isAdditional', data.isAdditional);
  // TODO

  return uploadPhotoForProduct(formData)
    .then((response) => {
      if (idx + 1 === count) {
        dispatch(setStatusCode(response.status));
      }
    })
    .catch(() => {
      dispatch(setErrors({ page: 'upload-photos', error: { index: idx } }));
    });
};

export const uploadPhotosForProductThunk = (photos, id) => async (dispatch) => {
  Promise.all(
    photos.map((photo) => {
      const formData = new FormData();
      formData.append('productId', id);
      formData.append('angleId', photo.angleId);
      formData.append('photo', photo.file);
      formData.append('isAdditional', false);

      return uploadPhotoForProduct(formData);
    }),
  )
    .then(() => {
      dispatch(setStatusCode(201));
      dispatch(addToast({ type: NotificationType.Success, text: 'Photos have been submitted' }));
    })
    .catch(() => {
      dispatch(addToast({ text: 'Something went wrong, please try uploading photos again' }));
    });
};

import styled from 'styled-components';

import { COLOR_MAP } from '../../componentUI/colorsMap';
import { truthfulAdaptive } from '../../componentUI/screen';
import { Text } from '../../componentUI/simple/Text';

export const ItemPag = styled(Text).attrs({ colorText: COLOR_MAP.controls.clickableNormal })<{ isActive?: boolean }>`
  ${({ isActive }) => isActive && 'pointer-events: none;'}

  padding: 10px 14.5px;

  border: 2px solid ${COLOR_MAP.controls.clickableNormal};

  border-radius: 2px;

  &:hover {
    cursor: pointer;
    border: 2px solid ${COLOR_MAP.controls.clickableHover};
  }

  ${({ isActive }) =>
    isActive &&
    `border: 2px solid ${COLOR_MAP.controls.clickableDisabled}; color: ${COLOR_MAP.controls.clickableDisabled};`}
`;

export const PaginationWrapper = styled.div`
  width: 100%;

  display: flex;
  justify-content: space-between;

  ${truthfulAdaptive.maxWidth.mobile} {
    flex-direction: column;
    justify-content: flex-end;
    gap: 20px;
  }
`;

export const PaginationSelectWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;

  ${truthfulAdaptive.maxWidth.mobile} {
    flex-direction: row-reverse;
  }
`;

export const PaginationItemsWrapper = styled.div`
  display: flex;
  gap: 15px;

  ${truthfulAdaptive.maxWidth.mobile} {
    justify-content: flex-end;
  }
`;

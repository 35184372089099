import styled, { CSSProperties } from 'styled-components';
import { COLOR_MAP } from '../../../../../componentUI/colorsMap';
import { truthfulAdaptive } from '../../../../../componentUI/screen';

const ElementTablePartIsDesktopStyled = styled.div<{ isDraftPage: boolean; isFinGuardActive: boolean }>`
  padding: 15px 8px;
  display: grid;
  grid-template-columns: ${({ isDraftPage }) =>
    isDraftPage
      ? 'minmax(0px, 32px) minmax(0px, 32px) minmax(118px, 138px) repeat(auto-fit, minmax(0px, auto));'
      : 'minmax(0px, 32px) minmax(118px, 138px) repeat(auto-fit, minmax(0px, auto));'};

  align-items: center;
  gap: 10px;

  border-bottom: 1px solid ${COLOR_MAP.accent.lightGrey};
  cursor: pointer;

  ${({ isFinGuardActive }) => isFinGuardActive && `background-color: ${COLOR_MAP.accent.green}0f;`};
`;

const CheckboxTitleStyled = styled.div`
  height: 100%;
  width: 32px;

  display: flex;
  align-items: center;
`;

const ImgTitleStyled = styled.div`
  height: 100%;
  width: 100%;
`;

const OrderNumberTitleStyled = styled.div`
  height: 100%;
  width: 100%;

  display: flex;
  align-items: center;

  white-space: nowrap;
`;

const StatusTitleStyled = styled.div`
  height: 100%;
  width: 120px;

  display: flex;
  align-items: center;

  ${truthfulAdaptive.maxWidth.desktop} {
    width: clamp(62px, 5.2vw, 120px);
  }
`;

const DateTitleStyled = styled.div`
  height: 100%;
  width: 75px;

  display: flex;
  align-items: center;
`;

const BrandTitleStyled = styled.div`
  height: 100%;
  width: clamp(165px, calc(10.2vw + 12px), 214px);

  display: flex;
  align-items: center;

  ${truthfulAdaptive.maxWidth.desktop1600} {
    width: clamp(138px, 8.6vw, 165px);
  }
  ${truthfulAdaptive.maxWidth.desktop1440} {
    width: clamp(108px, 7.5vw, 138px);
  }
  ${truthfulAdaptive.maxWidth.desktop} {
    width: clamp(62px, 5.2vw, 108px);
  }
`;

const ModelTitleStyled = styled.div`
  height: 100%;
  width: clamp(165px, calc(10.2vw + 12px), 214px);

  display: flex;
  align-items: center;

  ${truthfulAdaptive.maxWidth.desktop1600} {
    width: clamp(138px, 8.6vw, 165px);
  }
  ${truthfulAdaptive.maxWidth.desktop1440} {
    width: clamp(108px, 7.5vw, 138px);
  }
  ${truthfulAdaptive.maxWidth.desktop} {
    width: clamp(62px, 5.2vw, 108px);
  }
`;

const SKUTitleStyled = styled.div`
  height: 100%;
  width: clamp(165px, calc(8.6vw + 10px), 182px);

  display: flex;
  align-items: center;

  ${truthfulAdaptive.maxWidth.desktop1600} {
    width: clamp(138px, 8.6vw, 165px);
  }
  ${truthfulAdaptive.maxWidth.desktop1440} {
    width: clamp(108px, 7.5vw, 138px);
  }
  ${truthfulAdaptive.maxWidth.desktop} {
    width: clamp(62px, 5.2vw, 108px);
  }
`;

const AnswerTitleStyled = styled.div`
  height: 100%;
  width: 100px;

  display: flex;
  align-items: center;
`;

const OutcomeTitleStyled = styled.div`
  height: 100%;
  width: 100px;

  display: flex;
  align-items: center;
`;

const ActionTitleStyled = styled.div`
  height: 100%;
  width: 128px;

  display: flex;
  align-items: center;
`;

const WrapperSkipOutcomeIconStyled = styled.div`
  display: flex;
  align-items: center;
`;

const stylesForText: CSSProperties = {
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
};

export {
  ActionTitleStyled,
  AnswerTitleStyled,
  BrandTitleStyled,
  CheckboxTitleStyled,
  DateTitleStyled,
  ElementTablePartIsDesktopStyled,
  ImgTitleStyled,
  ModelTitleStyled,
  OrderNumberTitleStyled,
  OutcomeTitleStyled,
  SKUTitleStyled,
  StatusTitleStyled,
  WrapperSkipOutcomeIconStyled,
  stylesForText,
};

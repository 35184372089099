import styled from 'styled-components';
import { COLOR_MAP } from '../../../../componentUI/colorsMap';

const OrderStatisticsStyled = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

const NumberBlock = styled.div`
  padding: 3px 8px;

  border: 1px solid ${COLOR_MAP.text.grey};
  border-radius: 12px;
`;

const ItemWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;

  cursor: pointer;
`;

const PointSeparator = styled.div`
  position: relative;
  top: 8px;

  width: 100%;

  border-bottom: 1px dashed ${COLOR_MAP.text.darkGrey};
`;

// без данной обертки иконка стрелки съезжает
const WrapperRightArrowStyled = styled.div`
  width: 8%;
`;

export { ItemWrapper, NumberBlock, OrderStatisticsStyled, PointSeparator, WrapperRightArrowStyled };

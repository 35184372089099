import React from 'react';
import { useNavigate } from 'react-router-dom';

import { useGoogleAnalytics } from '../../../../../analytics';

import { useMatchMedia } from '../../../../../hooks';

import { TermsBlock } from '../../../../../components';

import { COLOR_MAP } from '../../../../../componentUI/colorsMap';
import { Picture } from '../../../../../componentUI/complex/Picture';
import { Button } from '../../../../../componentUI/simple/Button';
import { Text } from '../../../../../componentUI/simple/Text';

import { emailLogo } from '../../../assets';

import { LogoBlock, TitleBlock } from '../..';

import { ContainerStyled, SeparatorStyled, WrapperButtonStyled, WrapperMainStyled, WrapperTextStyled } from './styled';

export const ScreenSignUpEmailConfirmed = () => {
  useGoogleAnalytics();
  const navigate = useNavigate();
  const { isMobile } = useMatchMedia();

  return (
    <ContainerStyled>
      <LogoBlock />

      <WrapperMainStyled>
        <Picture url={emailLogo} width={isMobile ? 180 : 225} height={isMobile ? 160 : 200} alt="email logo" />

        <WrapperTextStyled>
          <TitleBlock titleText="Perfect!" subtitleText="Your email address now confirmed" />

          <SeparatorStyled />

          <Text colorText={COLOR_MAP.text.white}>We’re so glad, that you’re on board! Let’s start right now.</Text>
        </WrapperTextStyled>

        <WrapperButtonStyled>
          <Button
            isStretch
            onClick={() => {
              navigate('/signin');
            }}
          >
            GO TO SIGN IN
          </Button>
        </WrapperButtonStyled>
      </WrapperMainStyled>

      <TermsBlock />
    </ContainerStyled>
  );
};

import { SortingFilesIndexesType } from './getImgFilesIndexes';

export const getProblematicIndexes = (orderIndexes: SortingFilesIndexesType[]) => {
  const indexNotes = orderIndexes.filter((element) => {
    if (element.missingPhoto || element.unclearPhoto) {
      return true;
    }
    return false;
  });

  return indexNotes;
};

import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';
import styled from 'styled-components';
import { Text } from '../../simple/Text';
import { COLOR_MAP } from '../../colorsMap';
import { LoaderIcon } from '../../../common/icons/SvgInline/SvgIcons_11';

const OverlayWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(255, 255, 255, 0.85);
  z-index: 1000;
  overflow: hidden;
  cursor: wait;
`;

const UploadingContainer = styled.div`
  display: inline-flex;
  padding: 8px 16px;
  align-items: center;
  gap: 8px;
`;

// Add this style to your global styles or in a <style> tag in your HTML
const globalStyle = `
.no-scroll {
  overflow: hidden !important;
  height: 100vh;
}
`;

export const UploadingPhotoOverlay = () => {
  useEffect(() => {
    // Add the style tag when the component mounts
    const styleTag = document.createElement('style');
    styleTag.innerHTML = globalStyle;
    document.head.appendChild(styleTag);

    // Add the class to body when the component mounts
    document.body.classList.add('no-scroll');

    // Clean up when the component unmounts
    return () => {
      document.body.classList.remove('no-scroll');
      document.head.removeChild(styleTag);
    };
  }, []);

  return ReactDOM.createPortal(
    <OverlayWrapper>
      <UploadingContainer>
        <LoaderIcon />
        <Text colorText={COLOR_MAP.text.darkGrey}>Uploading photo ...</Text>
      </UploadingContainer>
    </OverlayWrapper>,
    document.body,
  );
};

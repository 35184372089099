import React from 'react';
import { useNavigate } from 'react-router-dom';

import { BellNotification, Logo, LogoPlus } from '../../../../components';

import { useMatchMedia } from '../../../../hooks';

import { COLOR_MAP } from '../../../../componentUI/colorsMap';
import { TertiaryButton } from '../../../../componentUI/simple/Button';
import { Text, TEXT_WEIGHT } from '../../../../componentUI/simple/Text';
import { SkeletonBasic } from '../../../../componentUI/skeleton';

import { DefaultAvatar } from '../../../../common/icons/SvgInline/SvgIcons_3';
import { SettingIcon } from '../../../../common/icons/SvgInline/SvgIcons_6';
import { CloseCrossIcon } from '../../../../common/icons/SvgInline/SvgIcons_9';

import {
  AvatarUserStyled,
  InfoUserStyled,
  stylesForTitle,
  TitleIsSidebarStyled,
  TitleStyled,
  WrapperCloseCrossIconStyled,
  WrapperSettingStyled,
} from './styled';
import { Props } from './types';

export const Title = ({
  closeHandler,
  isSubscriptions,
  isSidebar,
  hasNotification,
  userAvatarImg,
  userAccountName,
  isLoadingAccountInfo,
}: Props) => {
  const { isDesktopS } = useMatchMedia();
  const navigate = useNavigate();

  const closeDropDownMenu = () => {
    navigate('/account-settings/general-info');
    if (isSidebar && closeHandler) {
      closeHandler();
    }
  };

  return (
    <TitleStyled>
      {!isDesktopS && (
        <>
          {isSubscriptions && <LogoPlus />}

          {!isSubscriptions && <Logo />}
        </>
      )}

      {isDesktopS && (
        <WrapperSettingStyled onClick={closeDropDownMenu}>
          {!isLoadingAccountInfo && (
            <InfoUserStyled>
              {userAvatarImg ? <AvatarUserStyled src={userAvatarImg} alt="avatar" /> : <DefaultAvatar size="40" />}

              <Text style={stylesForTitle} weight={TEXT_WEIGHT.bold} colorText={COLOR_MAP.text.white}>
                {userAccountName}
              </Text>
            </InfoUserStyled>
          )}

          {isLoadingAccountInfo && (
            <InfoUserStyled>
              <SkeletonBasic width="40px" height="40px" bgColor={COLOR_MAP.controls.skeleton.content2} />
              <SkeletonBasic width="150px" height="22px" bgColor={COLOR_MAP.controls.skeleton.content2} />
            </InfoUserStyled>
          )}

          <TertiaryButton
            style={{ padding: '0' }}
            colorsMap={{
              clickable: `${COLOR_MAP.controls.fillNormal}`,
              clickableHover: `${COLOR_MAP.controls.strokeHover}`,
              clickablePressed: `${COLOR_MAP.controls.strokeActive}`,
              clickableDisabled: `${COLOR_MAP.controls.fillDisabled}`,
            }}
            backgroundColor="transparent"
          >
            <SettingIcon size="28" />
          </TertiaryButton>
        </WrapperSettingStyled>
      )}

      {isSidebar && isDesktopS && (
        <TitleIsSidebarStyled>
          <BellNotification fill={COLOR_MAP.controls.textAndIcon} hasNotification={hasNotification} />

          <WrapperCloseCrossIconStyled onClick={closeHandler}>
            <CloseCrossIcon />
          </WrapperCloseCrossIconStyled>
        </TitleIsSidebarStyled>
      )}
    </TitleStyled>
  );
};

import { getAllAngles, getProduct, getProducts, getResultsStatuses } from '../../api';
import { setAnglesList, setProduct, setProducts, setResultStatuses } from '../reducers/product-reducer';
import { addToast } from '../reducers/toast-reducer';

// export const getProductTypesThunk = (page, limit) => async (dispatch) => {
//   try {
//     const response = await getProductTypes(page, limit);
//     dispatch(setProductTypes(response.data.data));
//   } catch (error) {
//     dispatch(addToast({ text: error?.response?.data?.message }));
//   }
// };

export const getProductThunk = (id) => async (dispatch) => {
  try {
    const response = await getProduct(id);
    dispatch(setProduct(response.data));
  } catch (error) {
    dispatch(addToast({ text: error?.response?.data?.message }));
  }
};

export const getProductsThunk = (data, callback) => async (dispatch) => {
  dispatch(setProducts({ data: null, isError: false, isLoading: true, isLoaded: false }));
  try {
    const response = await getProducts(data);
    dispatch(setProducts({ data: response.data.entities, isError: false, isLoading: false, isLoaded: true }));

    if (callback) {
      callback(Boolean(response.data.entities.length));
    }
  } catch (error) {
    dispatch(setProducts({ data: null, isError: true, isLoading: false, isLoaded: false }));
    if (callback) {
      callback(false);
    }
  }
};

export const getResultsStatusesThunk = () => async (dispatch) => {
  try {
    const response = await getResultsStatuses();
    dispatch(setResultStatuses(response.data.data));
    return response.data.data;
  } catch (error) {
    dispatch(addToast({ text: error?.response?.data?.message }));
  }
};

export const getAnglesListThunk = () => async (dispatch) => {
  try {
    const response = await getAllAngles();
    dispatch(setAnglesList(response.data.entities));
  } catch (error) {
    dispatch(addToast({ text: error?.response?.data?.message }));
  }
};

// export const updateProductThunk = (productId, updatedData) => async (dispatch) => {
//   try {
//     const response = await updateProduct(productId, updatedData);
//     dispatch(setProduct(response.data));
//     dispatch(
//       addToast({
//         text: 'Changes successfully saved',
//         type: NotificationType.Success,
//       }),
//     );
//   } catch (error) {
//     dispatch(addToast({ text: error?.response?.data?.message }));
//   }
// };

// export const getBrandsListThunk = (page, limit) => async (dispatch) => {
//   try {
//     const response = await getBrandsList(page, limit);
//     dispatch(setBrandsList(response.data.entities));
//   } catch (error) {
//     // console.log(error);
//   }
// };

import React from 'react';
import { useNavigate } from 'react-router-dom';

import { Text, TEXT_SIZE, TEXT_WEIGHT } from '../../componentUI/simple/Text';

import { LogoPlusStyled } from './styled';

export const LogoPlus = () => {
  const navigate = useNavigate();

  return (
    <LogoPlusStyled onClick={() => navigate('/')}>
      <Text weight={TEXT_WEIGHT.bold} size={TEXT_SIZE.sub}>
        LegitGrails+
      </Text>
    </LogoPlusStyled>
  );
};

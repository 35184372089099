import styled, { CSSProperties } from 'styled-components';
import { adaptive } from '../../componentUI/screen';
import { TEXT_WEIGHT } from '../../componentUI/simple/Text';

export const PhotosContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const ButtonContainer = styled.div`
  align-self: end;

  ${adaptive.maxWidth.mobile} {
    display: flex;
    width: 100%;

    align-self: auto;

    & button {
      width: 100%;
    }
  }
`;

export const stylesForButtons: CSSProperties = {
  textTransform: 'uppercase',
  fontWeight: TEXT_WEIGHT.bold,
};

export const WrapperInstructionsBlock = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

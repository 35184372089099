import React from 'react';
import { useNavigate } from 'react-router-dom';

import { useGetStatistics } from '../../../../api/product/hooks/useGetStatistics';
import { COLOR_MAP } from '../../../../componentUI/colorsMap';
import { StatusIndicator } from '../../../../componentUI/simple/StatusIndicator';
import { Text, TEXT_SIZE } from '../../../../componentUI/simple/Text';
import { Title, TITLE_SIZE, TITLE_WEIGHT } from '../../../../componentUI/simple/Title';

import { RightArrow } from '../../../../common/icons/SvgInline/SvgIcons_7';

import { OrderStatisticsSkeleton } from './skeleton';
import { ItemWrapper, NumberBlock, OrderStatisticsStyled, PointSeparator, WrapperRightArrowStyled } from './styled';

import { DataItemsType, DesignType, DoNotShowType, Props } from './types';
import { OrderStatisticsExternalSkeleton } from './skeleton/OrderStatisticsExternalSkeleton';

const DATA_ITEMS: Array<DataItemsType> = [
  {
    sortId: 3,
    technicalNameItem: 'photosNeed',
    publicNameItem: 'Photos needed',
    linkItem: '/authentications/photos-needed',
    statusIndicatorItem: 'orange',
  },
  {
    sortId: 2,
    technicalNameItem: 'underReview',
    publicNameItem: 'Under review',
    linkItem: '/authentications/under-review',
    statusIndicatorItem: 'blue',
  },
  {
    sortId: 1,
    technicalNameItem: 'completed',
    publicNameItem: 'Completed',
    linkItem: '/authentications/completed',
    statusIndicatorItem: 'green',
  },
  {
    sortId: 4,
    technicalNameItem: 'drafts',
    publicNameItem: 'Drafts',
    linkItem: '/authentications/drafts',
    statusIndicatorItem: 'grey20',
  },
];

// функция убирает статистику которую показывать не надо
const getDoNotShowFilterDataStatisticsOrder = (
  dataItems: Array<DataItemsType>,
  doNotShow: DoNotShowType | undefined,
) => {
  if (doNotShow) {
    return dataItems.filter((dataItemsElement) => {
      return !doNotShow.includes(dataItemsElement.technicalNameItem);
    });
  }

  return dataItems;
};

const changingOrderElements = (dataItems: Array<DataItemsType>, dataDesign: DesignType) => {
  if (dataDesign === 'dashboard') {
    return dataItems.sort((a, b) => a.sortId - b.sortId);
  }
  return dataItems;
};

// компонент отрисовал статистику по заказам пользователя

// описание пропсов
// closeHandler - закрыть боковое меня если открыто;
// doNotShow - исключить переданные значения из показа;
// idExternalData - если данные о статистики поступают из вне отключает запрос данных внутри компонента;
// externalData - внешние данные о статистики.

export const OrderStatistics = ({
  closeHandler,
  doNotShow,
  idExternalData = false,
  externalData,
  externalIsLoading,
  design,
}: Props) => {
  const navigate = useNavigate();

  const { data: dataStatistics, isLoading: isLoadingStatistics } = useGetStatistics(idExternalData);

  if (isLoadingStatistics) {
    return <OrderStatisticsSkeleton />;
  }

  if (externalIsLoading) {
    return <OrderStatisticsExternalSkeleton />;
  }

  const statisticsOrder = idExternalData ? externalData : dataStatistics;

  const doNotShowFilterDataItems = getDoNotShowFilterDataStatisticsOrder(DATA_ITEMS, doNotShow);

  return (
    <OrderStatisticsStyled>
      {statisticsOrder &&
        changingOrderElements(doNotShowFilterDataItems, design).map(
          ({ technicalNameItem, publicNameItem, linkItem, statusIndicatorItem }) => {
            return (
              <ItemWrapper
                key={technicalNameItem}
                onClick={() => {
                  navigate(linkItem);

                  if (closeHandler) {
                    closeHandler();
                  }
                }}
              >
                <StatusIndicator bgc={COLOR_MAP.accent[statusIndicatorItem]} />

                {design === 'dashboard' && (
                  <>
                    <Text style={{ whiteSpace: 'nowrap' }} colorText={COLOR_MAP.text.darkGrey}>
                      {publicNameItem}
                    </Text>

                    <PointSeparator />

                    <Title colorText={COLOR_MAP.text.darkGrey} size={TITLE_SIZE.h5} weight={TITLE_WEIGHT.normal}>
                      {statisticsOrder[technicalNameItem]}
                    </Title>
                    <WrapperRightArrowStyled>
                      <RightArrow color={COLOR_MAP.accent.gold} size="16px" />
                    </WrapperRightArrowStyled>
                  </>
                )}

                {design === 'colum' && (
                  <>
                    <Text colorText={COLOR_MAP.text.grey}>{publicNameItem}</Text>

                    <NumberBlock>
                      <Text size={TEXT_SIZE.annotation} colorText={COLOR_MAP.text.grey}>
                        {statisticsOrder[technicalNameItem]}
                      </Text>
                    </NumberBlock>
                  </>
                )}
              </ItemWrapper>
            );
          },
        )}
    </OrderStatisticsStyled>
  );
};

import React from 'react';
import styled from 'styled-components';

import { COLOR_MAP } from '../../colorsMap';
import { supportHover } from '../../screen';
import { BUTTON_SIZE, SIZE_STYLE } from './constants';
import { PropsButton } from './types';
import { commonStyles } from './utils';

const ButtonComponent = styled.button<PropsButton>`
  ${commonStyles};

  display: flex;
  justify-content: center;
  align-items: center;
  gap: 4px;
  padding: 0;

  border: none;
  background-color: transparent;

  ${({ size }) => SIZE_STYLE[size]};
  font-family: Montserrat, sans-serif;
  color: ${COLOR_MAP.controls.dark.clickable};

  user-select: none;

  & path {
    stroke: ${COLOR_MAP.controls.dark.clickable};
  }

  ${supportHover} {
    &:hover {
      color: ${COLOR_MAP.controls.dark.clickableHover};
      & path {
        stroke: ${COLOR_MAP.controls.dark.clickableHover};
      }
    }
  }

  &:active {
    color: ${COLOR_MAP.controls.dark.clickablePressed};
    & path {
      stroke: ${COLOR_MAP.controls.dark.clickablePressed};
    }
  }

  &:disabled,
  &:disabled:active,
  &:disabled:hover {
    color: ${COLOR_MAP.controls.dark.clickableDisabled};

    cursor: not-allowed;
    & path {
      stroke: ${COLOR_MAP.controls.dark.clickableDisabled};
    }
  }
`;

export const DarkButton = ({
  size = BUTTON_SIZE.small,
  isStretch = false,
  disabled = false,
  children,
  ...props
}: Partial<PropsButton>) => {
  return (
    <ButtonComponent size={size} isStretch={isStretch} disabled={disabled} {...props}>
      {typeof children === 'string' ? children : children}
    </ButtonComponent>
  );
};

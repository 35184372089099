import styled from 'styled-components';

const CancellationOrderStyled = styled.div`
  max-width: 420px;

  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
`;

const WrapperTextStyled = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;

  text-align: center;
`;

const ContainerTextStyled = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;

  text-align: center;
`;

export { CancellationOrderStyled, ContainerTextStyled, WrapperTextStyled };

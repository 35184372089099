export const TEXT_SIZE = {
  title: 'title',
  regular: 'regular',
  sub: 'sub',
  annotation: 'annotation',
  button: 'button',
} as const;

export const TEXT_WEIGHT = {
  bold: '700',
  semibold: '600',
  normal: '400',
} as const;

export const SIZE_STYLE: Record<keyof typeof TEXT_SIZE, string> = {
  title: 'font-size: 20px; line-height: normal;', // Text title
  regular: 'font-size: 16px; line-height: 22px;', // Text regular
  sub: 'font-size: 13px; line-height: 18px;', // Text sub
  annotation: 'font-size: 11px; line-height: 15px;', // Text annotation
  button: 'font-size: 12px; font-style: normal; line-height: normal;',
};

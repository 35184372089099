import styled from 'styled-components';

const TitleBlockStyled = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  gap: 8px;
`;

export { TitleBlockStyled };

import { createAction, createReducer } from '@reduxjs/toolkit';
import { NotificationType } from '../../componentUI/types';

type ToastItem = {
  id: string;
  text?: string | Array<string>;
  type?: NotificationType;
};

export const addToast = createAction<{ text?: string | Array<string>; type?: NotificationType }>('ADD_TOAST');
export const deleteToast = createAction<{ id: string }>('DELETE_TOAST');

const initialState: { toasts: Array<ToastItem> } = {
  toasts: [],
};

export const toastReducer = createReducer(initialState, (builder) => {
  builder
    .addCase('ADD_TOAST', (state = initialState, action) => {
      if (Array.isArray(action.payload.text)) {
        const formattedString = action.payload.text.map((element: string) => {
          return `${element}\n`;
        });
        state.toasts = [...state.toasts, { id: crypto.randomUUID(), text: formattedString.join(' ') }];
      } else {
        state.toasts = [
          ...state.toasts,
          { id: crypto.randomUUID(), text: 'Server error. Please try again.', ...action.payload },
        ];
      }
    })
    .addCase('DELETE_TOAST', (state = initialState, action) => {
      state.toasts = state.toasts.filter((message) => message.id !== action.payload.id);
    });
});

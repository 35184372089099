/* eslint-disable react-hooks/exhaustive-deps */
import React, { ChangeEvent, useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';

import { addToast } from '../../../../redux/reducers/toast-reducer';

import { useMatchMedia, useStyledModal } from '../../../../hooks';

import { checkingFileSize, inputPhotoClear, isFileTypeAllowed } from '../../../../helpers';

import { COLOR_MAP } from '../../../../componentUI/colorsMap';
import { SecondButton, TertiaryButton } from '../../../../componentUI/simple/Button';
import { Checkbox } from '../../../../componentUI/simple/Checkbox';
import { SimpleNotification } from '../../../../componentUI/simple/SimpleNotification';
import { Text, TEXT_SIZE } from '../../../../componentUI/simple/Text';

import { UploadPhotoIcon } from '../../../../common/icons/SvgInline/SvgIcons_1';

import { SimpleNotificationType } from '../../../../componentUI/types';

import { UploadingNewRequestedPhoto } from './components';
import {
  CommentDescriptionStyled,
  CommentWrapperStyled,
  DescriptionProblemPhotosStyled,
  ImageIndexStyled,
  InformationPhotoStyled,
  InformationWrapperStyled,
  MainContainerStyled,
  PhotoCardV2Styled,
  ProblemWrapperStyled,
  stylesForButtons,
  UploadPhotosWrapperStyled,
  WrapperLickStyled,
  WrapperSecondButton,
} from './styled';
import { Props } from './types';
import { ViewingExamplePhotos } from '../../../../componentUI/complex/ViewingExamplePhotos';
import { AlertTriangleIcon, HelpCircleIcon } from '../../../../common/icons/SvgInline/SvgIcons_11';

// ФУНКЦИОНАЛ setAllNotPhoto ОТКЛЮЧЕН

// максимальное количество загружаемых фотографий
const MAXIMUM_NUMBER_PHOTOS = 5;
// максимальный размер загруженной фотографии в мегабайтах
const MAXIMUM_PHOTO_SIZE = 10;

/** Компонент отрисовывает весь блок индексов, которые используются
 *  для дозагрузки в них фотографий заказа */

export const IndexRequiredPhoto = ({
  problematicIndex,
  setAllUploadedPhotos,
  // setAllNotPhoto,
  setAllUploadedPhotosIndex,
}: Props) => {
  const {
    productId,
    name,
    comment,
    problems,
    unclearPhoto,
    unclearPhotoUrl,
    missingPhoto,
    fileImage,
    imageSamples,
    angleId,
  } = problematicIndex;

  const dispatch = useDispatch();
  const { isMobile } = useMatchMedia();
  const [Modal, toggleModal] = useStyledModal();

  // отслеживаем состояние isLoading запроса useUpdatePhotoProductMutate из модуля ниже
  const [isLoadingUploadedPhotos, setIsLoadingUploadedPhotos] = useState(false);

  // состояние чекбокса "I don't have a photo of this detail" текущего индекса
  const [isNotPhoto, setIsNotPhoto] = useState(false);

  // id фотографий загруженных в индекс, параметр необходим для
  // корректного подсчета загруженных фотографий в индексе
  // и показа функционала Checkbox "I don't have a photo of this detail"
  const [indexUploadedPhotos, setIndexUploadedPhotos] = useState<Array<string>>([]);

  // количество фотографий выбранных пользователем в данный момент, в индекс ещё не загружены
  const [files, setFiles] = useState<File[]>([]);

  const inputRef = useRef<HTMLInputElement>(null);

  // условие при котором показывается функционал Checkbox "I don't have a photo of this detail"
  const isShowCheckbox = indexUploadedPhotos.length <= 0 && files.length <= 0;

  // если количество загруженных фото превышает константу  делаем
  // кнопку Upload new photo недоступной и другие условия
  const isDisabledBtnUploadPhoto =
    indexUploadedPhotos.length >= MAXIMUM_NUMBER_PHOTOS || isNotPhoto || isLoadingUploadedPhotos;

  // индикаторы ошибок проверки выбранных фото
  const [isErrorCheckingFileSize, setIsErrorCheckingFileSize] = useState(false);
  const [isErrorLimitUploadedImages, setIsErrorLimitUploadedImages] = useState(false);
  const [isErrorPromiseUploadingPhoto, setIsErrorPromiseUploadingPhoto] = useState(false);
  const [isErrorWrongFile, setIsErrorWrongFile] = useState(false);

  // setAllUploadedPhotos - если чекбокс установлен то поле photos не добавляется, а поле
  // isUnableProvide меняет значение на true
  // setAllUploadedPhotosIndex - если чекбокс установлен то это аналогично загруженным фото
  // setAllIsNotPhoto - изменяем состояние чекбокса isNotPhoto для этого индекса в общем списке AllIsNotPhoto
  const handleCheckboxClick = (isCheck: boolean) => {
    if (!isCheck) {
      setAllUploadedPhotos((prev) => prev.filter((element) => element.angleId !== angleId));

      setAllUploadedPhotosIndex((prev) => {
        return { ...prev, [angleId]: isCheck };
      });

      // setAllNotPhoto((prev) => {
      //   return { ...prev, [angleId]: isCheck };
      // });

      return;
    }

    if (isCheck) {
      setAllUploadedPhotos((prev) => [...prev, { angleId, isUnableProvide: isCheck }]);

      setAllUploadedPhotosIndex((prev) => {
        return { ...prev, [angleId]: isCheck };
      });

      // setAllNotPhoto((prev) => {
      //   return { ...prev, [angleId]: isCheck };
      // });
    }
  };

  const handleButtonClick = () => {
    if (inputRef.current) {
      inputRef.current.click();
    }
  };

  // проверяем выбранные файлы по условиям и добавляем их к общему списку
  // ранее выбранный файлов, если есть, для кнопки
  const onChangeButton = async (event: ChangeEvent<HTMLInputElement>) => {
    event.preventDefault();

    if (event.target.files) {
      // получаем количество выбранных фотографий пользователей сейчас и складываем их
      // с загруженными фотографиями им ранее в этом индексе
      let currentNumberUploadedFiles = files.length + indexUploadedPhotos.length;

      const filteredFiles = Array.from(event.target.files).filter((file) => {
        if (!checkingFileSize(file, MAXIMUM_PHOTO_SIZE)) {
          setIsErrorCheckingFileSize(true);
          return false;
        }

        if (currentNumberUploadedFiles >= MAXIMUM_NUMBER_PHOTOS) {
          setIsErrorLimitUploadedImages(true);
          return false;
        }

        if (!isFileTypeAllowed(file)) {
          setIsErrorWrongFile(true);
          return false;
        }

        currentNumberUploadedFiles++;
        return true;
      });

      setFiles((prevFiles) => [...prevFiles, ...filteredFiles]);
    }
  };

  // блок отвечающий за показ ошибок загрузки фотографии в индекс пользователю
  // если выполнено одно из условий
  useEffect(() => {
    if (isErrorCheckingFileSize) {
      dispatch(
        addToast({
          text: 'The allowed photo size of 10 megabytes has been exceeded',
        }),
      );
      inputPhotoClear(inputRef);
      setIsErrorCheckingFileSize(false);
    }

    if (isErrorLimitUploadedImages) {
      dispatch(
        addToast({
          text: 'The limit of uploaded photos has been exceeded',
        }),
      );
    }

    if (isErrorPromiseUploadingPhoto) {
      dispatch(
        addToast({
          text: 'One or more photos could not be uploaded.',
        }),
      );
      setIsErrorPromiseUploadingPhoto(false);
    }

    if (isErrorWrongFile) {
      dispatch(
        addToast({
          text: 'Invalid file type. Only .png, .heif/.heic and .jpg are allowed.',
        }),
      );
      setIsErrorWrongFile(false);
    }
  }, [isErrorCheckingFileSize, isErrorLimitUploadedImages, isErrorPromiseUploadingPhoto, isErrorWrongFile]);

  // // при инициализации компонента устанавливаем флаг allNotPhoto в значение false
  // useEffect(() => {
  //   setAllNotPhoto((prev) => {
  //     return { ...prev, [angleId]: false };
  //   });
  // }, []);

  // отслеживаем присутствует ли в данном индексе хотя бы одно загруженное фото
  // при инициализации компонента т.к. indexUploadedPhotos === 0 присваивается значение false
  useEffect(() => {
    if (indexUploadedPhotos.length > 0) {
      setAllUploadedPhotosIndex((prev) => {
        return { ...prev, [angleId]: true };
      });
      return;
    }

    if (indexUploadedPhotos.length <= 0) {
      setAllUploadedPhotosIndex((prev) => {
        return { ...prev, [angleId]: false };
      });
    }
  }, [indexUploadedPhotos]);

  // const testExamples = ['https://placehold.co/560x560', 'https://placehold.co/880x320', 'https://placehold.co/320x880'];

  return (
    <PhotoCardV2Styled>
      <MainContainerStyled hasComment={!!comment}>
        <InformationPhotoStyled>
          {unclearPhoto && (
            <ImageIndexStyled
              height={isMobile ? 128 : 120}
              width={isMobile ? 128 : 120}
              src={unclearPhotoUrl || ''}
              alt="index unclear"
            />
          )}

          {missingPhoto && (
            <ImageIndexStyled
              height={isMobile ? 128 : 120}
              width={isMobile ? 128 : 120}
              src={fileImage[0].mainUrl || fileImage[0].reserveUrl || ''}
              alt="index missing"
            />
          )}

          <InformationWrapperStyled>
            <Text>{name}</Text>

            {imageSamples && imageSamples.length > 0 && (
              <WrapperLickStyled>
                <Modal onClose={toggleModal} isPhoto>
                  <ViewingExamplePhotos FilesImages={imageSamples} />
                </Modal>
                <TertiaryButton onClick={toggleModal}>
                  <HelpCircleIcon />
                  See image sample
                </TertiaryButton>
              </WrapperLickStyled>
            )}

            {missingPhoto && <SimpleNotification type={SimpleNotificationType.Bad} text="Missing photo" />}
            {/* {unclearPhoto && <SimpleNotification type={SimpleNotificationType.Bad} text="Unclear photo" />} */}

            {problems && problems.length > 0 && (
              <ProblemWrapperStyled>
                {problems.map((element) => {
                  return (
                    <DescriptionProblemPhotosStyled key={element}>
                      <AlertTriangleIcon />
                      <Text colorText={COLOR_MAP.text.orange}>{element}</Text>
                    </DescriptionProblemPhotosStyled>
                  );
                })}
              </ProblemWrapperStyled>
            )}
          </InformationWrapperStyled>
        </InformationPhotoStyled>

        {comment && (
          <CommentWrapperStyled>
            <Text size={TEXT_SIZE.sub}>Authenticator&apos;s comment</Text>
            <CommentDescriptionStyled>
              <Text>{comment}</Text>
            </CommentDescriptionStyled>
          </CommentWrapperStyled>
        )}

        <UploadPhotosWrapperStyled>
          <UploadingNewRequestedPhoto
            inputRef={inputRef}
            angleId={angleId}
            productId={productId}
            files={files}
            setFiles={setFiles}
            setAllUploadedPhotos={setAllUploadedPhotos}
            setIndexUploadedPhotos={setIndexUploadedPhotos}
            setIsErrorLimitUploadedImages={setIsErrorLimitUploadedImages}
            setIsLoadingUploadedPhotos={setIsLoadingUploadedPhotos}
          />
        </UploadPhotosWrapperStyled>

        {isShowCheckbox && (
          <Checkbox
            label="I don't have a photo of this detail"
            checked={isNotPhoto}
            onChange={() => {
              setIsNotPhoto((prev) => {
                handleCheckboxClick(!prev);
                return !prev;
              });
            }}
          />
        )}
      </MainContainerStyled>

      <WrapperSecondButton>
        <input
          type="file"
          multiple
          accept=".heic,.png,.heif,.jpg,.jpeg,.webp,.avif"
          ref={inputRef}
          onChange={onChangeButton}
          style={{ display: 'none' }}
        />

        <SecondButton
          isStretch
          style={stylesForButtons}
          onClick={handleButtonClick}
          disabled={isDisabledBtnUploadPhoto}
        >
          <UploadPhotoIcon size="16" />
          Upload new photo
        </SecondButton>
      </WrapperSecondButton>
    </PhotoCardV2Styled>
  );
};

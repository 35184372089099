/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { ResultStatusName } from '../../../../../api/product/type';
import { SkipOutcomeIcon } from '../../../../../common/icons/SvgInline/SvgIcons_1';
import { Checkbox } from '../../../../../componentUI/simple/Checkbox';
import { TEXT_SIZE, Text } from '../../../../../componentUI/simple/Text';
import { NotificationType } from '../../../../../componentUI/types';
import {
  dataDotFormatter,
  getActionInfo,
  getImageOrder,
  getNumberOrder,
  getOutcomeInfo,
  getStatusProductInfo,
} from '../../../../../helpers';
import { addToast } from '../../../../../redux/reducers/toast-reducer';
import { changeStatusCheckbox } from '../../../helpers';
import { ImgTitleFinGStyled } from '../../ImgTitleFinGStyled';
import {
  ActionTitleStyled,
  AnswerTitleStyled,
  BrandTitleStyled,
  CheckboxTitleStyled,
  DateTitleStyled,
  ElementTablePartIsDesktopStyled,
  ImgTitleStyled,
  ModelTitleStyled,
  OrderNumberTitleStyled,
  OutcomeTitleStyled,
  SKUTitleStyled,
  StatusTitleStyled,
  stylesForText,
} from './styled';
import { Props } from './types';

const { DRAFT } = ResultStatusName;

export const ElementTablePartIsDesktop = ({
  order,
  openOrderCard,
  DeleteDraftToggleModal,
  setIdProduct,
  isPreFilledOrder,
  tableColumn,
  setAllChecksDraftsElements,
  allChecksDraftsElements,
  currentPage,
}: Props) => {
  const { draft, order: orderColumn, status, date, brand, model, sku, answer, outcome, action } = tableColumn;

  const dispatch = useDispatch();

  // заказ является черновиком
  const isDraft = order.systemStatus === DRAFT;

  // проверяем находиться ли пользователь на странице с drafts
  // функционал актуален только для табличного представления заказов
  const isDraftPage = currentPage === 'drafts';

  // хранит текст для копирования в буфер обмена
  const [isCopyTextBuffer, getIsCopyTextBuffer] = useState(false);

  // добавляем новое состояние чекбокса Draft в стейт setAllChecksDraftsElements
  // при создании записи заказа
  useEffect(() => {
    setAllChecksDraftsElements((prev) => {
      return { ...prev, [order.id]: false };
    });
  }, []);

  useEffect(() => {
    if (isCopyTextBuffer) {
      dispatch(
        addToast({
          text: 'Order number copied.',
          type: NotificationType.Success,
        }),
      );

      getIsCopyTextBuffer(false);
    }
  }, [isCopyTextBuffer]);

  const orderImg = getImageOrder(order.photoUrl, false);
  const orderNumber = getNumberOrder(order.orderNumber, false, getIsCopyTextBuffer, order.isFinancialGuaranteeActive);
  const orderStatus = getStatusProductInfo(order.status, false, false);
  const orderDate = isPreFilledOrder ? null : dataDotFormatter(order.createdAt);
  const orderBrand = order.brand;
  const orderModel = order.modelName;
  const orderSKU = order.sku;
  const orderAnswer = order.answerTime;
  const orderOutcome = getOutcomeInfo(order.checkStatus, false, false);
  const orderAction = getActionInfo({
    setIdProduct,
    OpenDeletePhotoModal: DeleteDraftToggleModal,
    certificateAvailable: order.certificateAvailable,
    systemStatus: order.systemStatus,
    checkStatus: order.checkStatus,
    publicId: order.publicId,
    isBigSize: false,
    id: order.id,
    isFinGuardActive: order.isFinancialGuaranteeActive,
  });

  return (
    <ElementTablePartIsDesktopStyled
      isDraftPage={isDraftPage}
      onClick={() => {
        openOrderCard(isDraft, order.id);
      }}
      isFinGuardActive={order.isFinancialGuaranteeActive}
    >
      {draft && (
        <CheckboxTitleStyled onClick={(event) => event.stopPropagation()}>
          <Checkbox
            onChange={() => {
              changeStatusCheckbox(setAllChecksDraftsElements, order.id);
            }}
            checked={(allChecksDraftsElements && allChecksDraftsElements[order.id]) || false}
          />
        </CheckboxTitleStyled>
      )}

      {order.isFinancialGuaranteeActive ? (
        <ImgTitleFinGStyled>{orderImg}</ImgTitleFinGStyled>
      ) : (
        <ImgTitleStyled>{orderImg}</ImgTitleStyled>
      )}

      {orderColumn && <OrderNumberTitleStyled>{orderNumber || <SkipOutcomeIcon />}</OrderNumberTitleStyled>}

      {status && <StatusTitleStyled>{orderStatus}</StatusTitleStyled>}

      {date && (
        <DateTitleStyled>
          {orderDate ? <Text size={TEXT_SIZE.sub}>{orderDate}</Text> : <SkipOutcomeIcon />}
        </DateTitleStyled>
      )}

      {brand && (
        <BrandTitleStyled>
          {orderBrand ? (
            <Text style={stylesForText} size={TEXT_SIZE.sub}>
              {orderBrand}
            </Text>
          ) : (
            <SkipOutcomeIcon />
          )}
        </BrandTitleStyled>
      )}

      {model && (
        <ModelTitleStyled>
          {orderModel ? (
            <Text style={stylesForText} size={TEXT_SIZE.sub}>
              {orderModel}
            </Text>
          ) : (
            <SkipOutcomeIcon />
          )}
        </ModelTitleStyled>
      )}

      {sku && (
        <SKUTitleStyled>
          {orderSKU ? (
            <Text style={stylesForText} size={TEXT_SIZE.sub}>
              {orderSKU}
            </Text>
          ) : (
            <SkipOutcomeIcon />
          )}
        </SKUTitleStyled>
      )}

      {answer && (
        <AnswerTitleStyled>
          {orderAnswer ? <Text size={TEXT_SIZE.sub}>{orderAnswer}</Text> : <SkipOutcomeIcon />}
        </AnswerTitleStyled>
      )}

      {outcome && <OutcomeTitleStyled>{orderOutcome}</OutcomeTitleStyled>}

      {action && <ActionTitleStyled>{orderAction ? <>{orderAction}</> : <SkipOutcomeIcon />}</ActionTitleStyled>}
    </ElementTablePartIsDesktopStyled>
  );
};

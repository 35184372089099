import styled from 'styled-components';

import { COLOR_MAP } from '../../../../componentUI/colorsMap';

const TitleWrapperStyled = styled.div<{ isOnClick?: boolean | undefined }>`
  display: grid;
  grid-template-columns: auto auto 1fr auto;
  align-items: center;

  cursor: ${({ isOnClick }) => (isOnClick ? 'pointer' : '')};
`;

const WrapperIconStyled = styled.div`
  padding: 0 10px 0 0;

  display: flex;
  align-items: center;
`;

const SelectionIndicatorStyled = styled.div<{ bgColorPoint: string; isActiveLink: boolean }>`
  position: relative;
  right: 28px;

  height: 28px;
  width: 6px;

  border-top-right-radius: 2px;
  border-bottom-right-radius: 2px;
  background-color: ${({ bgColorPoint, isActiveLink }) =>
    isActiveLink ? COLOR_MAP.controls.clickableNormal : bgColorPoint};
`;

const CreditsStyled = styled.div<{ isActiveLink: boolean }>`
  padding: 5px 10px 5px 5px;

  display: flex;
  gap: 5px;

  border-radius: 16px;

  border: 1px solid
    ${({ isActiveLink }) => (isActiveLink ? `${COLOR_MAP.accent.darkGrey}` : `${COLOR_MAP.accent.lightGrey}`)};
`;

export { CreditsStyled, SelectionIndicatorStyled, TitleWrapperStyled, WrapperIconStyled };

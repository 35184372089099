export const getTypesOfProduct = (state) => {
  return state.products.types;
};

export const takeProduct = (state) => {
  return state.products.product;
};

export const takeProducts = (state) => {
  return state.products.products;
};

// TODO-evgrashin выпилить с заменой на запрос через query useResultStatuses
export const takeResultStatuses = (state) => {
  return state.products.resultStatuses;
};

export const takeAnglesList = (state) => {
  return state.products.anglesList;
};

export const takeBrandsList = (state) => {
  return state.products.brandsList;
};

/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { useGoogleAnalytics } from '../../../../analytics';
import { useConfirmEmailQuery } from '../../../../api/auth/hooks';
import { Loader } from '../../../../components';

export const ConfirmEmailRedesign = () => {
  useGoogleAnalytics();
  const navigate = useNavigate();

  const { hash: hashConfirmEmail } = useParams();

  const {
    isError: isErrorConfirmEmail,
    isLoading: isLoadingConfirmEmail,
    isSuccess: isSuccessConfirmEmail,
  } = useConfirmEmailQuery(hashConfirmEmail, Boolean(hashConfirmEmail));

  useEffect(() => {
    if (isSuccessConfirmEmail) {
      return navigate('/confirm-email/EmailConfirmed');
    }

    if (isErrorConfirmEmail) {
      return navigate('/confirm-email/EmailError');
    }
  }, [isSuccessConfirmEmail, isErrorConfirmEmail]);

  if (isLoadingConfirmEmail) {
    return <Loader style={{ margin: '45vh auto' }} />;
  }
};

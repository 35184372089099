/* eslint-disable react-hooks/exhaustive-deps */
import { useMutation } from '@tanstack/react-query';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { COLOR_MAP } from '../../componentUI/colorsMap';

import { TEXT_SIZE, Text } from '../../componentUI/simple/Text';
import { Title } from '../../componentUI/simple/Title';

import { CloseIcon } from '../../common/icons/SvgInline/SvgIcons_2';
import { NoNotifications } from '../../common/icons/SvgInline/SvgIcons_3';
import { DoubleCheck } from '../../common/icons/SvgInline/SvgIcons_4';
import { SettingIcon } from '../../common/icons/SvgInline/SvgIcons_6';
import { BUTTON_SIZE, Button, TertiaryButton } from '../../componentUI/simple/Button';

import { NotificationsSkeleton } from './skeleton';

import { NotificationPageType, SettingPageType } from '../../common/constants';

import { markedReadNotification, useNotificationMutate } from '../../api/notifications';

import { ChosenTabBlock, NotificationCard } from './components';
import {
  CloseIconWrapper,
  NoNotificationsWrapper,
  NotificationMessagesWrapper,
  NotificationWrapper,
  TitleWrapper,
  TopBarWrapper,
  stylesForButton,
  stylesForTertiaryButton,
} from './styled';

type Props = {
  changeShowNotification: () => void;
};

const { notViewed } = NotificationPageType;
const { notifications } = SettingPageType;

export const Notification = ({ changeShowNotification }: Props) => {
  const navigate = useNavigate();

  const [currentTab, setCurrentTab] = useState<NotificationPageType>(notViewed);

  const { mutate, data, isLoading } = useNotificationMutate();

  const { mutate: notificationAllReadMarked } = useMutation({
    mutationFn: markedReadNotification,
    onSuccess: () => {
      mutate(notViewed);
    },
  });

  useEffect(() => {
    mutate(currentTab);
  }, [currentTab]);

  return (
    <NotificationWrapper>
      <TitleWrapper>
        <Title>Notifications</Title>

        <CloseIconWrapper onClick={changeShowNotification}>
          <CloseIcon size="24" />
        </CloseIconWrapper>
      </TitleWrapper>

      <TopBarWrapper>
        <ChosenTabBlock tap={currentTab} setCurrentTab={setCurrentTab} />

        {currentTab === notViewed && (
          <TertiaryButton
            style={stylesForTertiaryButton}
            size={BUTTON_SIZE.extraSmall}
            onClick={() => notificationAllReadMarked('')}
          >
            <DoubleCheck />
            Mark all as read
          </TertiaryButton>
        )}
      </TopBarWrapper>

      {isLoading && <NotificationsSkeleton />}

      {data?.notifications?.length !== 0 ? (
        <NotificationMessagesWrapper>
          {data?.notifications?.map((product) => {
            return (
              <NotificationCard
                key={product.id}
                product={product}
                description={product.text}
                title={product.title}
                changeShowNotification={changeShowNotification}
                currentTab={currentTab}
              />
            );
          })}
        </NotificationMessagesWrapper>
      ) : (
        <NoNotificationsWrapper>
          <NoNotifications />
          <Text style={{ textAlign: 'center' }}>
            It looks like you have viewed all the notifications and don&apos;t have any new ones!
          </Text>
        </NoNotificationsWrapper>
      )}

      <Button
        style={stylesForButton}
        colorIcon={COLOR_MAP.controls.clickableNormal}
        onClick={() => navigate(`/account-settings/${notifications}`)}
      >
        <SettingIcon size="16" color={COLOR_MAP.controls.clickableNormal} />
        <Text size={TEXT_SIZE.sub} colorText={COLOR_MAP.text.brandColor}>
          Notifications settings
        </Text>
      </Button>
    </NotificationWrapper>
  );
};

import styled from 'styled-components';
import { COLOR_MAP } from '../../colorsMap';
import { notificationColors, SimpleNotificationType } from '../../types';

export const NotificationWrapper = styled.div<{ type: SimpleNotificationType }>`
  max-width: 180px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 8px 10px;
  gap: 6px;

  border-radius: 4px;

  background-color: ${({ type }) => notificationColors[type]};

  & path {
    stroke: ${COLOR_MAP.controls.textAndIcon};
  }
`;

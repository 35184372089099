import React from 'react';

import { COLOR_MAP } from '../../componentUI/colorsMap';
import { LinkClassic } from '../../componentUI/simple/Link';
import { TEXT_SIZE, Text } from '../../componentUI/simple/Text';

import { TermsBlockStyled } from './styled';

export const TermsBlock = () => {
  const currentYear = new Date().getFullYear();

  return (
    <TermsBlockStyled>
      <Text size={TEXT_SIZE.annotation} colorText={COLOR_MAP.text.darkGrey}>
        {currentYear} © LegitGrails
      </Text>

      <LinkClassic size={TEXT_SIZE.annotation} href="/conditions" target="_blank" rel="noopener noreferrer">
        Terms & Conditions
      </LinkClassic>
    </TermsBlockStyled>
  );
};

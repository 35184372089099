import React from 'react';
import { useNavigate } from 'react-router-dom';

import { LogoStyled } from './styled';

export const Logo = () => {
  const navigate = useNavigate();

  return <LogoStyled onClick={() => navigate('/')} />;
};

import React from 'react';

import { COLOR_MAP } from '../../../../componentUI/colorsMap';
import { TAG_NAME } from '../../../../componentUI/constants';
import { TEXT_SIZE, TEXT_WEIGHT, Text } from '../../../../componentUI/simple/Text';
import { TITLE_SIZE, Title } from '../../../../componentUI/simple/Title';

import { FilterChevronIcon, FilterIcon } from '../../../../common/icons/SvgInline/SvgIcons_11';

import { ButtonFilterStyled, CountStyled, FirstBlockStyled, WrapperFilterChevronIcon } from './styled';
import { Props } from './types';

/** Стилизация кнопки Filter на странице со списком заказов */

// numberFiltersPage - количество выбранных фильтров
// isFilterCounter - если количество выбранных фильтров больше нуля
// то показываем счетчик

export const ButtonFilter = ({ setPressedFilterButton, pressedFilterButton, idFiltersPage }: Props) => {
  const isFilterCounter = idFiltersPage.length > 0;

  return (
    <ButtonFilterStyled onClick={() => setPressedFilterButton((prevState) => !prevState)}>
      <FirstBlockStyled>
        <FilterIcon />

        <Title colorText={COLOR_MAP.text.brandColor} size={TITLE_SIZE.h6} tag={TAG_NAME.h6}>
          Filters
        </Title>

        {isFilterCounter && (
          <CountStyled>
            <Text size={TEXT_SIZE.annotation} colorText={COLOR_MAP.text.white} weight={TEXT_WEIGHT.bold}>
              {idFiltersPage.length}
            </Text>
          </CountStyled>
        )}
      </FirstBlockStyled>

      <WrapperFilterChevronIcon pressedFilterButton={pressedFilterButton}>
        <FilterChevronIcon />
      </WrapperFilterChevronIcon>
    </ButtonFilterStyled>
  );
};

import React from 'react';
import { COLOR_MAP } from '../../colorsMap';
import { simpleNotificationIcons, SimpleNotificationProps, SimpleNotificationType } from '../../types';
import { Text } from '../Text';
import { NotificationWrapper } from './styled';

export const SimpleNotification = ({ type = SimpleNotificationType.Default, text, style }: SimpleNotificationProps) => {
  const Icon = simpleNotificationIcons[type];

  return (
    <NotificationWrapper type={type} style={style}>
      {Icon && <Icon />}
      <Text colorText={COLOR_MAP.text.white}>{text}</Text>
    </NotificationWrapper>
  );
};

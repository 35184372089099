/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import Select from 'react-select';

import { useCountriesQuery } from '../../../../../../api/countries/hooks';
import { useCountryStatesQuery } from '../../../../../../api/countryStates/hooks';

import { COLOR_MAP } from '../../../../../../componentUI/colorsMap';
import { ErrorInputReactSelectBlock } from '../../../../../../componentUI/simple/ErrorInputReactSelectBlock';
import { SkeletonBasic } from '../../../../../../componentUI/skeleton';

import { CountryType, StateType } from '../../types';

import { locale } from './locale';
import { Props, TypeBusinessType } from './types';

const { accountType, USA_ID } = locale;

/** Отрисовывает поля Country и Type of business */
export const AdditionalBasicInfo = ({
  setFormData,
  setCountryError,
  setStateError,
  setBusinessError,
  setIsEU,
  setPostalRegex,
  isBusinessAccount,
}: Props) => {
  const { data: dataCountries, isLoading: isLoadingCountries, isError: isErrorCountries } = useCountriesQuery();

  const [selectedCountry, setSelectedCountry] = useState<CountryType | null>(null);
  const [typeBusiness, setTypeBusiness] = useState<TypeBusinessType | null>(null);

  const [typeBusinessError, setTypeBusinessError] = useState(false);
  const [selectedStateError, setSelectedStateError] = useState(false);

  const isSelectCountryUSA = selectedCountry?.value === USA_ID;

  const {
    data: dataCountryStates,
    isLoading: isLoadingCountryStates,
    isError: isErrorCountryStates,
  } = useCountryStatesQuery(selectedCountry?.value || '', isSelectCountryUSA);

  const [selectedState, setSelectedState] = useState<StateType | null>(null);
  const [selectedCountryError, setSelectedCountryError] = useState(false);

  // передаем актуальные данные которые ввел пользователь
  // в поля input в родительский компонент в виде одного объекта
  useEffect(() => {
    setFormData((prev) => {
      return {
        ...prev,
        country: selectedCountry,
        typeBusiness: typeBusiness?.value || '',
        countryStateId: selectedState?.value || '',
      };
    });
  }, [selectedCountry, typeBusiness, selectedState]);

  // проверяем корректность ввода данных и если они не корректны
  // передаем буллевый флаг ошибки в родительский компонент
  useEffect(() => {
    if (!selectedCountry?.value) {
      setCountryError(true);
      return;
    }

    setCountryError(false);
  }, [selectedCountry]);

  useEffect(() => {
    if (!typeBusiness?.value) {
      setBusinessError(true);
      return;
    }

    setBusinessError(false);
  }, [typeBusiness]);

  useEffect(() => {
    if (isSelectCountryUSA) {
      if (!selectedState?.value) {
        setStateError(true);
        return;
      }
      setStateError(false);
    }

    if (!isSelectCountryUSA) {
      setSelectedState(null);
      setStateError(false);
    }
  }, [isSelectCountryUSA, selectedState]);

  if (isLoadingCountries) {
    return (
      <>
        <SkeletonBasic width="100%" height="38px" bgColor={COLOR_MAP.controls.skeleton.content2} />
        <SkeletonBasic width="100%" height="38px" bgColor={COLOR_MAP.controls.skeleton.content2} />
      </>
    );
  }

  return (
    <>
      <ErrorInputReactSelectBlock isError={selectedCountryError} errorMessage="Please select a country">
        <Select
          placeholder="Country *"
          options={dataCountries}
          value={selectedCountry}
          isDisabled={isLoadingCountries || isErrorCountries}
          onChange={(options) => {
            if (options) {
              setSelectedCountry(options);

              setIsEU(options.isEU);
              setPostalRegex(options.postalRegex);

              setSelectedCountryError(!options.value);
            }
          }}
        />
      </ErrorInputReactSelectBlock>

      {isSelectCountryUSA && (
        <ErrorInputReactSelectBlock isError={selectedStateError} errorMessage="Please select a state">
          <Select
            placeholder="States *"
            options={dataCountryStates}
            value={selectedState}
            isDisabled={isLoadingCountryStates || isErrorCountryStates}
            onChange={(options) => {
              if (options) {
                setSelectedState(options);
                setSelectedStateError(!options.value);
              }
            }}
          />
        </ErrorInputReactSelectBlock>
      )}

      {isBusinessAccount && (
        <ErrorInputReactSelectBlock isError={typeBusinessError} errorMessage="Please select a type of business">
          <Select
            placeholder="Type of business *"
            options={accountType}
            value={typeBusiness}
            onChange={(options) => {
              if (options) {
                setTypeBusiness(options);

                setTypeBusinessError(!options.value);
              }
            }}
          />
        </ErrorInputReactSelectBlock>
      )}
    </>
  );
};

/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable max-len */
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useUserCertificateTemplatesQuery } from '../../api/certificates/hooks/useUserCertificateTemplatesQuery';
import { useFreeCustomTemplate } from '../../api/product/hooks/useFreeCustomTemplate';
import { useGetUserSubscriptions } from '../../api/subscriptions/hooks/useGetUserSubscriptions';
import { ArrowDownCircleIcon } from '../../common/icons/SvgInline/SvgIcons_10';
import { COLOR_MAP } from '../../componentUI/colorsMap';
import { TAG_NAME } from '../../componentUI/constants';
import { SecondButton } from '../../componentUI/simple/Button';
import { Text, TEXT_WEIGHT } from '../../componentUI/simple/Text';
import { Title, TITLE_SIZE } from '../../componentUI/simple/Title';
import {
  AddCertificateFromCard,
  CertificateFreeInfo,
  CertificateTemplate,
  IncludeQrLabel,
  RadioChipsCertificate,
} from './components';
import {
  CUSTOM_CERTIFICATE,
  FINANCIAL_GUARANTEE_CERTIFICATE,
  STANDARD_CERTIFICATE,
} from './components/RadioChipsCertificate/data';
import { locale } from './locale';
import { CertificateInfoSkeleton } from './skeleton';
import { CertificateWrapper, SeparatorStyled, WrapperIconStyled, WrapperSecondButtonStyle } from './styled';
import { Props } from './types';

// isOpenDropDownList - используется в карточке заказа, где не надо показывать меню выбора сертификата

export const CertificateInfo = ({
  selectedCertificateType,
  setSelectedCertificateType,
  userCertificateTemplateId,
  setUserCertificateTemplateId,
  itemLabelNeeded,
  setItemLabelNeeded,
  setIsOpenCertificateInfoBtn,
  onRefetch,
  isGoodBalance,
  isOpenDropDownList,
  idCard,
  isFinancialGuaranteeUsed,
  costCustomCertificate,
}: Props) => {
  const {
    data: templateData,
    isLoading: isLoadingTemplate,
    isSuccess: isSuccessTemplate,
  } = useUserCertificateTemplatesQuery();

  const {
    data: templateFree,
    isLoading: isLoadingTemplateFree,
    isSuccess: isSuccessTemplateFree,
  } = useFreeCustomTemplate();

  const {
    data: userSubscriptions,
    isLoading: isLoadingUserSubscriptions,
    isSuccess: isSuccessUserSubscriptions,
  } = useGetUserSubscriptions();

  const navigate = useNavigate();

  const [isIssueCertificate, setIsIssueCertificate] = useState(false);
  const [isMissingCertificateTemplates, setIsMissingCertificateTemplates] = useState(false);
  const [isDisabledRadioChips, setIsDisabledRadioChips] = useState(false);

  const isSelectCustomCertificate = selectedCertificateType === CUSTOM_CERTIFICATE;
  const isSelectStandardCertificate = selectedCertificateType === STANDARD_CERTIFICATE;
  const isSelectFinancialGuaranteeCertificate = selectedCertificateType === FINANCIAL_GUARANTEE_CERTIFICATE;

  // если сертификат не выбран или выбран стандарт Standard
  // бэк ждет в переменной userCertificateTemplateId значение null
  useEffect(() => {
    if (isSelectStandardCertificate) {
      setUserCertificateTemplateId(null);
    }
  }, [selectedCertificateType]);

  // если у пользователя нет шаблонов сертификатов или не хватает баланса то
  // принудительно устанавливаем "стандартный" тип сертификата
  // показываем информационное сообщение и блокируем кнопку выбора типа сертификата
  useEffect(() => {
    if (isSuccessTemplate && templateData.length === 0) {
      setUserCertificateTemplateId(null);
      setSelectedCertificateType(STANDARD_CERTIFICATE);
      setIsDisabledRadioChips(true);
      setIsMissingCertificateTemplates(true);
      return;
    }

    setIsDisabledRadioChips(false);
    setIsMissingCertificateTemplates(false);
  }, [isSuccessTemplate]);

  // проверка на возможно выпустить сертификат, если хватает баланса
  // и есть "бесплатные" сертификаты или же хватает баланса и есть подписка
  useEffect(() => {
    if (isSuccessTemplateFree && isSuccessUserSubscriptions) {
      const isFree = templateFree.used < templateFree.limit;
      const isSubscriptions = userSubscriptions.result;
      setIsIssueCertificate((isGoodBalance && isFree) || (isGoodBalance && isSubscriptions));
    }
    setIsIssueCertificate(false);
  }, [isGoodBalance, isSuccessTemplateFree, isSuccessUserSubscriptions]);

  // устанавливаем сертификат который выбран "по умолчанию", сначала проверяем есть ли предустановленный, потом
  // устанавливаем выбранный пользователем "по умолчанию" в настройках и если такого нет, то первый из полученного
  // массива шаблонов. Значение устанавливается каждый раз когда пользователь перевыбирает тип сертификата
  useEffect(() => {
    if (
      isSuccessTemplate &&
      templateData.length !== 0 &&
      !isSelectStandardCertificate &&
      !isSelectFinancialGuaranteeCertificate
    ) {
      const defaultTemplateCertificate = templateData.find((element) => {
        return element.isDefault;
      });

      setUserCertificateTemplateId(userCertificateTemplateId || defaultTemplateCertificate?.id || templateData[0].id);
    }
  }, [isSuccessTemplate, templateData, selectedCertificateType]);

  if (isLoadingTemplateFree || isLoadingUserSubscriptions) {
    return <CertificateInfoSkeleton />;
  }

  return (
    <CertificateWrapper>
      <Title tag={TAG_NAME.h5} size={TITLE_SIZE.h5}>
        Certificate
      </Title>

      <>
        <RadioChipsCertificate
          isDisabledRadioChips={isDisabledRadioChips}
          selectedCertificateType={selectedCertificateType}
          setSelectedCertificateType={setSelectedCertificateType}
          isFinancialGuaranteeUsed={isFinancialGuaranteeUsed}
        />

        {isFinancialGuaranteeUsed && (
          <Text colorText={COLOR_MAP.text.darkGrey}>
            Authentications with enabled Financial Guarantee come with a special certificate.
          </Text>
        )}

        {!isFinancialGuaranteeUsed && isSelectCustomCertificate && (
          <CertificateFreeInfo templateFree={templateFree} userSubscriptions={userSubscriptions} />
        )}

        {!isFinancialGuaranteeUsed && isSelectCustomCertificate && (
          <div>
            <Text style={{ display: 'inline' }} tag={TAG_NAME.span} weight={TEXT_WEIGHT.bold}>
              Additional generation of a custom certificate will cost&nbsp;
            </Text>

            <WrapperIconStyled>
              <ArrowDownCircleIcon size="22" />

              <Text tag={TAG_NAME.span} weight={TEXT_WEIGHT.bold} colorText={COLOR_MAP.accent.red}>
                &nbsp;{costCustomCertificate}
              </Text>
            </WrapperIconStyled>

            <Text style={{ display: 'inline' }} tag={TAG_NAME.span} weight={TEXT_WEIGHT.bold}>
              &nbsp;credits.
            </Text>
          </div>
        )}

        {isMissingCertificateTemplates && !isFinancialGuaranteeUsed && (
          <Text colorText={COLOR_MAP.accent.red}>{locale.noCertificateTemplatesText}</Text>
        )}

        {!isMissingCertificateTemplates && isSelectCustomCertificate && (
          <CertificateTemplate
            setUserCertificateTemplateId={setUserCertificateTemplateId}
            userCertificateTemplateId={userCertificateTemplateId}
            templateData={templateData}
            isLoadingTemplate={isLoadingTemplate}
          />
        )}

        {!isFinancialGuaranteeUsed && (
          <WrapperSecondButtonStyle>
            <SecondButton isStretch onClick={() => navigate('/account-settings/certificate')}>
              MANAGE TEMPLATES
            </SecondButton>
          </WrapperSecondButtonStyle>
        )}

        {!isFinancialGuaranteeUsed && <SeparatorStyled />}

        {setItemLabelNeeded && !isFinancialGuaranteeUsed && (
          <IncludeQrLabel
            isCertificateCustom={isSelectCustomCertificate}
            itemLabelNeeded={itemLabelNeeded}
            setItemLabelNeeded={setItemLabelNeeded}
          />
        )}
      </>

      {isOpenDropDownList && (
        <AddCertificateFromCard
          setIsOpenCertificateInfoBtn={setIsOpenCertificateInfoBtn}
          onRefetch={onRefetch}
          userCertificateTemplateId={userCertificateTemplateId}
          idCard={idCard}
          isIssueCertificate={isIssueCertificate}
        />
      )}
    </CertificateWrapper>
  );
};

import { AccountTypeEnum, StepNumberEnum } from '../types';

const { business } = AccountTypeEnum;

const { step1, step2 } = StepNumberEnum;

/** текст для кнопки завершения регистрации в зависимости от выбранного типа
 * аккаунта и текущего шага регистрации
 */

export const getButtonText = (typeAcc: AccountTypeEnum | string, step: StepNumberEnum) => {
  if (typeAcc !== business) {
    return 'FINISH ACCOUNT SET-UP';
  }

  if (typeAcc === business && step === step1) {
    return 'NEXT';
  }

  if (typeAcc === business && step === step2) {
    return 'FINISH ACCOUNT SET-UP';
  }
};

/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';

import { useCancelUnableProvideMutate } from '../../../../api/product/hooks/useCancelUnableProvideMutate';

import { COLOR_MAP } from '../../../../componentUI/colorsMap';
import { Button, SecondButton } from '../../../../componentUI/simple/Button';
import { Text } from '../../../../componentUI/simple/Text';
import { TITLE_SIZE, TITLE_WEIGHT, Title } from '../../../../componentUI/simple/Title';

import { locale } from './locale';
import { CancellationOrderStyled, ContainerTextStyled, WrapperTextStyled } from './styled';
import { Props } from './types';

const CANCEL_COLOR_MAP = {
  clickable: COLOR_MAP.accent.red,
  clickableHover: COLOR_MAP.accent.red,
  clickablePressed: COLOR_MAP.accent.red,
  clickableDisabled: COLOR_MAP.accent.red,
};

const { title, information, quest } = locale;

export const CancellationOrder = ({ productId, onClose }: Props) => {
  const { mutate: deleteOrderCard, isSuccess: isSuccessDeleteOrderCard } = useCancelUnableProvideMutate();

  useEffect(() => {
    if (isSuccessDeleteOrderCard) {
      onClose();
    }
  }, [isSuccessDeleteOrderCard]);

  return (
    <CancellationOrderStyled>
      <WrapperTextStyled>
        <Title size={TITLE_SIZE.h4} weight={TITLE_WEIGHT.normal} style={{ textAlign: 'center' }} margins={[0, 0, 8, 0]}>
          {title}
        </Title>

        <ContainerTextStyled>
          <Text>{information}</Text>

          <Text>{quest}</Text>
        </ContainerTextStyled>
      </WrapperTextStyled>

      <Button onClick={() => deleteOrderCard({ productId })} colorsMap={CANCEL_COLOR_MAP} isStretch>
        CANCEL ORDER
      </Button>

      <SecondButton onClick={onClose} isStretch>
        GO BACK
      </SecondButton>
    </CancellationOrderStyled>
  );
};

import { createAction, createReducer } from '@reduxjs/toolkit';

export const setBrands = createAction('SET_BRANDS');

const initialState = {
  brands: [],
  order: null,
  balance: {
    data: [],
    isLoading: false,
    isError: false,
  },
  angles: [],
  productTypeId: null,
};

export const authRequestReducer = createReducer(initialState, (builder) => {
  builder.addCase('SET_BRANDS', (state = initialState, action) => {
    state.brands = action.payload;
  });
});

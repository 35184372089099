/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { sendUserEvent } from '../../analytics';

import { useNotificationQuery } from '../../api/notifications';
import { useGetUserSubscriptions } from '../../api/subscriptions/hooks/useGetUserSubscriptions';
import { useBalanceUserInfoQuery } from '../../api/userCredits/hooks';

import { COLOR_MAP } from '../../componentUI/colorsMap';
import { ButtonV2 } from '../../componentUI/simple/Button';
import { Text, TEXT_SIZE, TEXT_WEIGHT } from '../../componentUI/simple/Text';

import { CreditIcon } from '../../common/icons/SvgInline/SvgIcons_10';
import { AuthenticateIcon, HamburgerIcon } from '../../common/icons/SvgInline/SvgIcons_9';

import { BellNotification, Logo, LogoPlus } from '../../components';

import { getFilteredNotifications } from '../../helpers';

import { SkeletonBasic } from '../../componentUI/skeleton';

import { ButtonsWrapper, CreditsStyled, HeaderRedesignStyled, IconWrapper } from './styled';
import { Props } from './types';

export const HeaderRedesign = ({ setShowColumnMobileMenu, showColumnMobileMenu }: Props) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const { data: dataNotification } = useNotificationQuery();
  const { data: dataUserSubscriptions } = useGetUserSubscriptions();
  const { data: dataBalanceUserInfo, isLoading: isLoadingBalanceUserInfo } = useBalanceUserInfoQuery();

  const products = getFilteredNotifications(dataNotification?.notifications);

  const [hasNotification, setHasNotification] = useState(false);

  useEffect(() => {
    if (products?.length) {
      setHasNotification(true);
    } else {
      setHasNotification(false);
    }
  }, [products]);

  const newAuthenticationHandler = () => {
    sendUserEvent('New authentication button clicked');

    navigate('/authenticate');
  };

  const isSubscriptions = dataUserSubscriptions?.result;
  const creditsBalance = dataBalanceUserInfo?.balanceInfo.creditsVolume || 0;
  const isAuthenticatePage = pathname.includes('authenticate') && !pathname.includes('draft');

  return (
    <HeaderRedesignStyled>
      {isSubscriptions && <LogoPlus />}

      {!isSubscriptions && <Logo />}

      <ButtonsWrapper>
        {isLoadingBalanceUserInfo && (
          <SkeletonBasic width="60px" height="28px" bgColor={COLOR_MAP.controls.skeleton.content2} />
        )}

        {!isLoadingBalanceUserInfo && creditsBalance >= 0 && (
          <CreditsStyled>
            <CreditIcon size="18" />
            <Text weight={TEXT_WEIGHT.bold} size={TEXT_SIZE.sub} colorText={COLOR_MAP.text.white}>
              {creditsBalance}
            </Text>
          </CreditsStyled>
        )}

        {!isAuthenticatePage && (
          <ButtonV2 style={{ border: 'none' }} onClick={newAuthenticationHandler}>
            <AuthenticateIcon />
          </ButtonV2>
        )}

        <BellNotification
          fill={COLOR_MAP.controls.textAndIcon}
          hasNotification={hasNotification}
          showColumnMobileMenu={showColumnMobileMenu}
        />

        <IconWrapper onClick={() => (setShowColumnMobileMenu ? setShowColumnMobileMenu(true) : null)}>
          <HamburgerIcon />
        </IconWrapper>
      </ButtonsWrapper>
    </HeaderRedesignStyled>
  );
};

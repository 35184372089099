import { useQueryClient } from '@tanstack/react-query';
import Cookies from 'js-cookie';
import React from 'react';
import { useNavigate } from 'react-router-dom';

import { useAccountInfoQuery } from '../../../../api/auth/hooks';

import { useMatchMedia } from '../../../../hooks';

import { COLOR_MAP } from '../../../../componentUI/colorsMap';
import { TertiaryButton } from '../../../../componentUI/simple/Button';
import { TEXT_SIZE, TEXT_WEIGHT, Text } from '../../../../componentUI/simple/Text';

import { LogOutIcon } from '../../../../common/icons/SvgInline/SvgIcons_3';
import { SettingIcon } from '../../../../common/icons/SvgInline/SvgIcons_6';

import { Props } from './types';

import {
  HorizonLineStyled,
  UserAreaStyled,
  WrapperLogOutStyled,
  WrapperOtherStyled,
  WrapperUserSettingStyled,
} from './styled';

// функционал блока "Верифицированный партнер", на текущий момент не используется

// import { CheckmarkIcon } from '../../../common/icons/SvgInline/SvgIcons_8';
// import { useUserBusinessInfoQuery } from '../../../api/verified-partner/hooks/useUserBusinessInfoQuery';

// const { data: VerifiedPartner } = useUserBusinessInfoQuery();

// {
//   VerifiedPartner?.isVerified && (
//     <BieStyled>
//       <CheckmarkIcon />
//       <Text size={TEXT_SIZE.annotation} colorText={COLOR_MAP.text.light}>
//         Verified Partner
//       </Text>
//     </BieStyled>
//   );
// }

export const UserArea = ({ closeHandler, isSidebar }: Props) => {
  const navigate = useNavigate();
  const client = useQueryClient();
  const { isDesktopS } = useMatchMedia();

  const { data: AccountData } = useAccountInfoQuery(null, true);

  const closeDropDownMenu = () => {
    navigate('/account-settings/general-info');
    if (isSidebar && closeHandler) {
      closeHandler();
    }
  };

  const LogOutAccount = () => {
    client.removeQueries();
    Cookies.remove('jwt');
    Cookies.remove('jwt-refresh');
    navigate('/signin');
  };

  return (
    <UserAreaStyled>
      <HorizonLineStyled />

      {!isDesktopS && (
        <WrapperUserSettingStyled>
          <Text weight={TEXT_WEIGHT.bold} colorText={COLOR_MAP.text.white}>
            {AccountData?.firstName}
          </Text>

          <TertiaryButton
            style={{ padding: '0' }}
            colorsMap={{
              clickable: `${COLOR_MAP.controls.fillNormal}`,
              clickableHover: `${COLOR_MAP.controls.strokeHover}`,
              clickablePressed: `${COLOR_MAP.controls.strokeActive}`,
              clickableDisabled: `${COLOR_MAP.controls.fillDisabled}`,
            }}
            backgroundColor="transparent"
            onClick={closeDropDownMenu}
          >
            <SettingIcon size="28" />
          </TertiaryButton>
        </WrapperUserSettingStyled>
      )}

      <WrapperOtherStyled>
        <WrapperLogOutStyled onClick={LogOutAccount}>
          <LogOutIcon />
          <Text size={TEXT_SIZE.sub} colorText={COLOR_MAP.controls.clickableNormal}>
            Log Out
          </Text>
        </WrapperLogOutStyled>
      </WrapperOtherStyled>
      <HorizonLineStyled />
    </UserAreaStyled>
  );
};

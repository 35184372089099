import styled from 'styled-components';
import { truthfulAdaptive } from '../../../../../../../../componentUI/screen';

const FilterSelectTypeTwoStyled = styled.div`
  display: grid;
  grid-template-columns: 168px 1fr;
  gap: 12px;

  ${truthfulAdaptive.maxWidth.mobile} {
    grid-template-columns: 1fr;
    grid-template-rows: auto auto;
  }
`;

export { FilterSelectTypeTwoStyled };

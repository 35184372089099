/* eslint-disable react-hooks/exhaustive-deps */
import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { COLOR_MAP } from '../../../../componentUI/colorsMap';
import { TEXT_SIZE, TEXT_WEIGHT, Text } from '../../../../componentUI/simple/Text';
import { TITLE_SIZE, Title } from '../../../../componentUI/simple/Title';

import { CreditIcon, CreditIconV2 } from '../../../../common/icons/SvgInline/SvgIcons_10';

import { CreditsStyled, SelectionIndicatorStyled, TitleWrapperStyled, WrapperIconStyled } from './styled';

type Props = {
  Icon: ({ size, color }: { size?: string | undefined; color?: string | undefined }) => JSX.Element;
  text: string;
  onClick?: () => void;
  isDisabled?: boolean;
  isBalance?: boolean;
  creditsVolume?: number;
  isActiveBalanceLink?: Dispatch<SetStateAction<boolean>>;
};

export const SectionBlock = ({
  Icon,
  text,
  onClick,
  isDisabled,
  isBalance,
  creditsVolume,
  isActiveBalanceLink,
}: Props) => {
  const { pathname } = useLocation();
  const [bgColorPoint, setBgColorPoint] = useState('transparent');
  const [isActiveLink, setIsActiveLink] = useState(false);

  const arrAddressBarValues = pathname.split('/').slice(1);

  useEffect(() => {
    setIsActiveLink(arrAddressBarValues.includes(text.toLowerCase()));

    if (isActiveBalanceLink) {
      isActiveBalanceLink(arrAddressBarValues.includes(text.toLowerCase()));
    }
  }, [pathname]);

  return (
    <>
      {isDisabled && (
        <TitleWrapperStyled>
          <SelectionIndicatorStyled isActiveLink={isActiveLink} bgColorPoint={bgColorPoint} />

          <WrapperIconStyled>
            <Icon color={COLOR_MAP.text.whiteDisabled} />
          </WrapperIconStyled>

          <Title size={TITLE_SIZE.h6} colorText={COLOR_MAP.text.whiteDisabled}>
            {text}
          </Title>
        </TitleWrapperStyled>
      )}

      {!isDisabled && (
        <TitleWrapperStyled
          onClick={onClick}
          isOnClick={Boolean(onClick)}
          onMouseEnter={() => setBgColorPoint(COLOR_MAP.controls.clickableNormal)}
          onMouseLeave={() => setBgColorPoint('transparent')}
        >
          <SelectionIndicatorStyled isActiveLink={isActiveLink} bgColorPoint={bgColorPoint} />

          <WrapperIconStyled>
            {isActiveLink && <Icon color={COLOR_MAP.text.darkGrey} />}
            {!isActiveLink && <Icon color={COLOR_MAP.text.white} />}
          </WrapperIconStyled>

          <Title size={TITLE_SIZE.h6} colorText={isActiveLink ? COLOR_MAP.text.darkGrey : COLOR_MAP.text.white}>
            {text}
          </Title>

          {isBalance && (
            <CreditsStyled isActiveLink={isActiveLink}>
              {isActiveLink ? <CreditIconV2 size="18" /> : <CreditIcon size="18" />}
              <Text
                weight={TEXT_WEIGHT.bold}
                size={TEXT_SIZE.sub}
                colorText={isActiveLink ? COLOR_MAP.text.brandColor : COLOR_MAP.text.white}
              >
                {creditsVolume}
              </Text>
            </CreditsStyled>
          )}
        </TitleWrapperStyled>
      )}
    </>
  );
};

/* eslint-disable react/no-array-index-key */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';

import './photoFromClickUp.scss';
// import { LightgalleryProvider, LightgalleryItem } from 'react-lightgallery';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { getAlbum } from '../../api';
import { takeAnglesList } from '../../redux';
// import 'lightgallery.js/dist/css/lightgallery.css';
import { Logo } from '../../common';
import { adaptive } from '../../componentUI/screen';
import { useGoogleAnalytics } from '../../analytics';

const PhotoLabelContainer = styled.div`
  width: 111px;
  height: 100px;
  border: 1px solid #cbad73;
  box-shadow: 0 6px 32px rgb(0 0 0 / 4%);
  border-radius: 4px;
  margin: 0 0 0 0;
  font-size: 14px;
  font-weight: 600;
  line-height: 17px;

  ${adaptive.maxWidth.mobile} {
    max-width: 125px;
    max-height: 80px;
  }
`;

export const PhotoFromClickUp = () => {
  useGoogleAnalytics();
  const [album, setAlbum] = useState([]);
  const [added, setAdded] = useState([]);
  const [uploaded, setUploaded] = useState([]);
  const params = useParams();

  const getAlbumPhotos = async () => {
    try {
      const response = await getAlbum(params.id);
      setAlbum(response.data);
      setUploaded(response.data.photos.filter((i) => i.isAdditional === false));
      setAdded(response.data.photos.filter((i) => i.isAdditional === true));
    } catch (error) {
      // console.log(error);
    }
  };

  useEffect(() => {
    getAlbumPhotos();
  }, []);

  const anglesList = useSelector(takeAnglesList);

  const getAnglePublicName = (id) => {
    if (!anglesList || !id) return '';
    return anglesList.find((item) => item.id === id).publicName;
  };

  return (
    <div className="order__full-container">
      <div className="order__container">
        <div className="order__image">
          <img src={Logo} alt="legitgrails" />
        </div>
        <div className="order__header">
          <h1>Authentication #{album.orderNumber}</h1>
          <div className="order__header-details">
            <h3>Item category</h3>
            <span>{album.itemCategory}</span>
            <h3>Brand</h3>
            <span>{album.brand}</span>
          </div>
        </div>
        {/* <LightgalleryProvider> */}
        {uploaded.length >= 1 && <div className="order__text">Uploaded Images</div>}
        {uploaded.length >= 1 && (
          <div className="order__upload">
            {uploaded?.map((image, index) => (
              <div className="auth_request__form__photo-container">
                <div key={index} className="auth_request__form__photo-elem">
                  <PhotoLabelContainer>
                    {/* <LightgalleryItem group="group1" src={image.path}> */}
                    <img className="album-image" src={image.path} alt="" />
                    {/* </LightgalleryItem> */}
                  </PhotoLabelContainer>
                  <div className="auth_request__form__photo-name">{getAnglePublicName(image.angleId)}</div>
                </div>
              </div>
            ))}
          </div>
        )}
        {added.length >= 1 && <div className="order__text second">Added Images</div>}
        {added.length >= 1 && (
          <div className="order__upload second">
            {added?.map((image) => (
              <div className="auth_request__form__photo-container">
                <div key={image} className={`auth_request__form__photo-elem ${image}`}>
                  <div className="auth_request__form__photo-photolabel required">
                    {/* <LightgalleryItem group="group2" src={image.path}> */}
                    <img className="album-image" src={image.path} alt="" />
                    {/* </LightgalleryItem> */}
                  </div>
                  <div className="auth_request__form__photo-name">{getAnglePublicName(image.angleId)}</div>
                </div>
              </div>
            ))}
          </div>
        )}
        {/* </LightgalleryProvider> */}
      </div>
    </div>
  );
};

/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useMemo, useState } from 'react';
import Select, { CSSObjectWithLabel, GroupBase, StylesConfig } from 'react-select';

import { DropdownIndicator } from '../../../../../../../../common';

import { usePrevious } from '../../../../../../../../hooks';

import { useGetBrandsListQuery } from '../../../../../../../../api/brands/hooks';
import { useCheckStatusesQuery } from '../../../../../../../../api/product/hooks/useCheckStatusesQuery';

import { sortArrayOfObjectsForAscByField } from '../../../../../../../../helpers/sortArrayOfObjectsForAscByField';

import { getOptionsFilterSelectTypeTwo, objectFormation } from '../../helpers';
import { DropDownListType, NAME_OBJECT_GET_PRODUCT } from '../../types';
import { FilterSelectTypeTwoStyled } from './styled';
import { OptionsBrandType, Props } from './types';

// стилизация placeholder для скрытия его текста в три точки
const styles: StylesConfig<OptionsBrandType, false, GroupBase<OptionsBrandType>> | undefined = {
  placeholder: (inlineCss: CSSObjectWithLabel) => ({
    ...inlineCss,
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    width: '100%',
    overflow: 'hidden',
  }),
};

const { brand } = NAME_OBJECT_GET_PRODUCT;

/** Фильтр осуществляет поиск по Brand заказа или checkStatus
 * поля - Brand и  Outcome соответственно  */

export const FilterSelectTypeTwo = ({ selectDropDownList, setSelectedSetFilters }: Props) => {
  const { dropDownList, id, nameObject } = selectDropDownList;

  const isBrandSelect = nameObject === brand;

  const { data: dataBrandsList, isLoading: isLoadingBrandsList } = useGetBrandsListQuery();
  const { data: dataCheckStatuses, isLoading: isLoadingCheckStatuses } = useCheckStatusesQuery();

  const isLoadingQuery = isLoadingCheckStatuses || isLoadingBrandsList;

  // предыдущее значение nameObject
  const prevNameObject = usePrevious(nameObject);

  // содержит информацию о том, новое ли значение выбрано в текущем фильтре из мастер-селекта
  const [isNewMasterDropdownList, setIsNewMasterDropdownList] = useState(false);

  const [selectValue, setSelectValue] = useState<OptionsBrandType | null>(null);
  const [includeValue, setIncludeValue] = useState<DropDownListType>(dropDownList[0]);

  const optionsDropDownList: Array<OptionsBrandType> = useMemo(() => {
    const options = getOptionsFilterSelectTypeTwo({ dataBrandsList, dataCheckStatuses, nameObject });

    return options.sort(sortArrayOfObjectsForAscByField('label'));
  }, [dataBrandsList, dataCheckStatuses, nameObject]);

  useEffect(() => {
    if (!selectValue?.value) {
      return;
    }

    setSelectedSetFilters((prev) => {
      const settingSelect = objectFormation({
        nameObject,
        idFilter: id,
        parameterOne: selectValue?.value || '',
        parameterTwo: includeValue?.value || false,
      });

      return {
        ...prev,
        ...settingSelect,
      };
    });
  }, [selectValue, includeValue]);

  // сбрасываем ранее выбранные значения в текущем поле поиска
  useEffect(() => {
    // если значения пустое, обработку не запускаем
    if (!nameObject || !prevNameObject) {
      return;
    }

    setSelectValue(null);
    setIncludeValue(dropDownList[0]);
    setIsNewMasterDropdownList(true);
  }, [nameObject]);

  // если пользователь перевыбрал значение из мастер-селекта, то данные будут сброшены
  // на дефольтные, для текущего значения местер-селекта которое он выбрал
  useEffect(() => {
    if (isNewMasterDropdownList) {
      setSelectedSetFilters((prev) => {
        const settingSelect = objectFormation({
          nameObject,
          idFilter: id,
          parameterOne: selectValue?.value || '',
          parameterTwo: includeValue?.value || false,
        });

        return {
          ...prev,
          ...settingSelect,
        };
      });
      setIsNewMasterDropdownList(false);
    }
  }, [isNewMasterDropdownList]);

  return (
    <FilterSelectTypeTwoStyled>
      <Select
        components={{ DropdownIndicator }}
        options={dropDownList}
        classNamePrefix="custom-select"
        value={includeValue}
        onChange={(option) => {
          if (option) {
            setIncludeValue(option);
          }
        }}
        isSearchable={false}
      />

      <Select
        styles={styles}
        isDisabled={isLoadingQuery}
        components={{ DropdownIndicator }}
        options={optionsDropDownList}
        classNamePrefix="custom-select"
        placeholder={isBrandSelect ? ' Start typing the brand name here' : 'Select...'}
        value={selectValue}
        onChange={(option) => {
          if (option) {
            setSelectValue(option);
          }
        }}
      />
    </FilterSelectTypeTwoStyled>
  );
};

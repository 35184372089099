import { RANGE } from '../types';

const VALUE_RANGE: { [key: string]: number } = {
  last7: 7,
  last30: 30,
  last90: 90,
};

const { today, last7, last30, last90, custom } = RANGE;

type GetDateRangeType = {
  selectRange: string | undefined;
  fromDatePicker?: Date;
  untilDatePicker?: Date;
};

const generateRangeDate = () => {
  const untilDate = new Date(Date.now()).toISOString();
  const newDate = new Date(Date.now());
  newDate.setHours(0, 0, 0, 1);
  const fromDate = newDate.toISOString();

  return {
    fromDate,
    untilDate,
  };
};

const generateFixedRangeDate = (range: string) => {
  const untilDate = new Date().toISOString();
  const fromDate = new Date(Date.now() - 60 * 60 * 24 * 1000 * Number(VALUE_RANGE[range] - 1)).toISOString();

  return {
    fromDate,
    untilDate,
  };
};

const generateCustomRangeDate = (fromPicker: Date | undefined, untilPicker: Date | undefined) => {
  if (fromPicker && !untilPicker) {
    const fromDate = fromPicker.toISOString();
    return {
      fromDate,
      untilDate: '',
    };
  }

  if (untilPicker && !fromPicker) {
    const untilDate = untilPicker.toISOString();
    return {
      fromDate: '',
      untilDate,
    };
  }

  if (fromPicker && untilPicker) {
    const untilDate = untilPicker.toISOString();
    const fromDate = fromPicker.toISOString();

    return {
      fromDate,
      untilDate,
    };
  }

  return {
    fromDate: '',
    untilDate: '',
  };
};

export const getDateRange = ({ selectRange, fromDatePicker, untilDatePicker }: GetDateRangeType) => {
  switch (selectRange) {
    case today:
      return generateRangeDate();

    case last7:
    case last30:
    case last90:
      return generateFixedRangeDate(selectRange);

    case custom:
      return generateCustomRangeDate(fromDatePicker, untilDatePicker);
    default:
      return generateRangeDate();
  }
};

/* eslint-disable max-len */
import React from 'react';

/* eslint-disable max-len */

const ChevronUp = ({ size = '10' }: { size?: string }) => (
  <svg width={size} height={size} viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M7.5 6.25L5 3.75L2.5 6.25"
      stroke="#AAAAAA"
      strokeWidth="1.6"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

const ChevronDown = ({ size = '10' }: { size?: string }) => (
  <svg width={size} height={size} viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M2.5 3.75L5 6.25L7.5 3.75"
      stroke="#AAAAAA"
      strokeWidth="1.6"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

const PayPal = ({ width = '52', height = '16' }: { width?: string; height?: string }) => (
  <svg width={width} height={height} viewBox="0 0 52 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M6.24003 1.00052H2.19544C1.91862 1.00052 1.68326 1.20373 1.64007 1.47987L0.00424726 11.9585C-0.028288 12.1652 0.130243 12.3517 0.337794 12.3517H2.26875C2.54552 12.3517 2.78088 12.1486 2.82406 11.8719L3.26524 9.04567C3.30785 8.76896 3.54379 8.56579 3.81998 8.56579H5.10038C7.76466 8.56579 9.3023 7.26319 9.70391 4.68191C9.88485 3.55258 9.71157 2.66529 9.18816 2.04388C8.61332 1.36144 7.59374 1.00052 6.24003 1.00052ZM6.70666 4.82772C6.48547 6.29404 5.37657 6.29404 4.30435 6.29404H3.69404L4.1222 3.55561C4.14765 3.39008 4.28955 3.26819 4.45517 3.26819H4.73488C5.46526 3.26819 6.15428 3.26819 6.51028 3.6888C6.72262 3.93979 6.78768 4.31263 6.70666 4.82772Z"
      fill="#AAAAAA"
    />
    <path
      d="M18.3302 4.78059H16.3934C16.2285 4.78059 16.0859 4.90249 16.0604 5.06801L15.9748 5.61534L15.8393 5.41694C15.42 4.80207 14.4849 4.59657 13.5517 4.59657C11.4114 4.59657 9.58338 6.23435 9.22736 8.53182C9.04228 9.67785 9.30545 10.7737 9.94888 11.5379C10.5391 12.2406 11.3836 12.5334 12.3885 12.5334C14.113 12.5334 15.0693 11.4131 15.0693 11.4131L14.9829 11.9568C14.9503 12.1647 15.1089 12.3512 15.3153 12.3512H17.0599C17.3373 12.3512 17.5715 12.148 17.6153 11.8714L18.662 5.17375C18.6952 4.96759 18.5373 4.78059 18.3302 4.78059ZM15.6305 8.58919C15.4437 9.70711 14.5654 10.4577 13.4453 10.4577C12.8829 10.4577 12.4334 10.2754 12.1447 9.93001C11.8585 9.58703 11.7497 9.09886 11.8408 8.55509C12.0152 7.44671 12.9083 6.67177 14.0112 6.67177C14.5613 6.67177 15.0083 6.85638 15.3029 7.20468C15.598 7.55662 15.715 8.0478 15.6305 8.58919Z"
      fill="#AAAAAA"
    />
    <path
      d="M28.6455 4.78057H26.6993C26.5135 4.78057 26.3391 4.87379 26.2338 5.02978L23.5494 9.02478L22.4116 5.18571C22.34 4.94547 22.1206 4.78057 21.8722 4.78057H19.9596C19.7272 4.78057 19.5658 5.01 19.6397 5.23109L21.7835 11.5875L19.768 14.4622C19.6095 14.6886 19.7691 15 20.0431 15H21.987C22.1715 15 22.3442 14.9092 22.4489 14.7562L28.9223 5.31539C29.0773 5.08948 28.9182 4.78057 28.6455 4.78057Z"
      fill="#AAAAAA"
    />
    <path
      d="M35.0891 1.00052H31.0439C30.7678 1.00052 30.5324 1.20373 30.4892 1.47987L28.8534 11.9585C28.8209 12.1652 28.9793 12.3517 29.1858 12.3517H31.2616C31.4544 12.3517 31.6195 12.2095 31.6496 12.0159L32.1138 9.04567C32.1563 8.76896 32.3924 8.56579 32.6685 8.56579H33.9484C36.6132 8.56579 38.1503 7.26319 38.5524 4.68191C38.734 3.55258 38.5596 2.66529 38.0361 2.04388C37.4619 1.36144 36.4429 1.00052 35.0891 1.00052ZM35.5558 4.82772C35.3352 6.29404 34.2264 6.29404 33.1535 6.29404H32.5438L32.9726 3.55561C32.998 3.39008 33.1387 3.26819 33.3049 3.26819H33.5846C34.3145 3.26819 35.004 3.26819 35.36 3.6888C35.5723 3.93979 35.6368 4.31263 35.5558 4.82772Z"
      fill="#DDDDDD"
    />
    <path
      d="M47.1789 4.78059H45.2432C45.077 4.78059 44.9356 4.90249 44.9108 5.06801L44.825 5.61534L44.689 5.41694C44.2698 4.80207 43.3353 4.59657 42.4021 4.59657C40.2617 4.59657 38.4344 6.23435 38.0783 8.53182C37.8938 9.67785 38.1558 10.7737 38.7993 11.5379C39.3907 12.2406 40.2339 12.5334 41.2387 12.5334C42.9633 12.5334 43.9196 11.4131 43.9196 11.4131L43.8332 11.9568C43.8007 12.1647 43.9592 12.3512 44.1668 12.3512H45.9109C46.187 12.3512 46.4225 12.148 46.4657 11.8714L47.513 5.17375C47.5449 4.96759 47.3864 4.78059 47.1789 4.78059ZM44.479 8.58919C44.2933 9.70711 43.4139 10.4577 42.2939 10.4577C41.7325 10.4577 41.2819 10.2754 40.9933 9.93001C40.7072 9.58703 40.5994 9.09886 40.6894 8.55509C40.865 7.44671 41.7569 6.67177 42.8599 6.67177C43.4098 6.67177 43.8569 6.85638 44.1514 7.20468C44.4477 7.55662 44.5648 8.0478 44.479 8.58919Z"
      fill="#DDDDDD"
    />
    <path
      d="M49.4622 1.28801L47.8021 11.9585C47.7696 12.1652 47.9281 12.3517 48.1345 12.3517H49.8034C50.0808 12.3517 50.3162 12.1486 50.3588 11.8719L51.9957 1.39385C52.0283 1.18703 51.8699 1.00002 51.6634 1.00002H49.7945C49.6295 1.00052 49.4876 1.12257 49.4622 1.28801Z"
      fill="#DDDDDD"
    />
  </svg>
);

const Stripe = ({ width = '38', height = '16' }: { width?: string; height?: string }) => (
  <svg width={width} height={height} viewBox="0 0 38 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M38 8.26704C38 5.53273 36.6911 3.37518 34.1894 3.37518C31.6772 3.37518 30.1572 5.53273 30.1572 8.24568C30.1572 11.4606 31.9517 13.0841 34.5272 13.0841C35.7833 13.0841 36.7333 12.7957 37.4511 12.3899V10.2537C36.7333 10.6168 35.91 10.8411 34.865 10.8411C33.8411 10.8411 32.9333 10.478 32.8172 9.21764H37.9789C37.9789 9.07879 38 8.52338 38 8.26704ZM32.7856 7.25235C32.7856 6.04541 33.5139 5.54341 34.1789 5.54341C34.8228 5.54341 35.5089 6.04541 35.5089 7.25235H32.7856Z"
      fill="#AAAAAA"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M26.083 3.37518C25.0486 3.37518 24.3836 3.86651 24.0141 4.20829L23.8769 3.54608H21.5547V16L24.1936 15.4339L24.2041 12.4112C24.5841 12.6889 25.1436 13.0841 26.0725 13.0841C27.9619 13.0841 29.6825 11.5461 29.6825 8.16023C29.6719 5.06277 27.9302 3.37518 26.083 3.37518ZM25.4497 10.7343C24.8269 10.7343 24.4575 10.51 24.2041 10.2323L24.1936 6.26971C24.468 5.95996 24.848 5.74635 25.4497 5.74635C26.4102 5.74635 27.0752 6.8358 27.0752 8.235C27.0752 9.66624 26.4208 10.7343 25.4497 10.7343Z"
      fill="#AAAAAA"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17.9233 2.74499L20.5728 2.16822V0L17.9233 0.566088V2.74499Z"
      fill="#AAAAAA"
    />
    <path d="M20.5728 3.55673H17.9233V12.9025H20.5728V3.55673Z" fill="#AAAAAA" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.0837 4.34714L14.9148 3.55675H12.6348V12.9025H15.2737V6.56877C15.8964 5.74634 16.952 5.89587 17.2792 6.01336V3.55675C16.9414 3.42858 15.7064 3.1936 15.0837 4.34714Z"
      fill="#AAAAAA"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.80632 1.23898L7.23077 1.79439L7.22021 10.3498C7.22021 11.9306 8.39188 13.0948 9.9541 13.0948C10.8197 13.0948 11.453 12.9346 11.8013 12.7423V10.5741C11.4635 10.7129 9.79577 11.2043 9.79577 9.6235V5.83177H11.8013V3.55674H9.79577L9.80632 1.23898Z"
      fill="#AAAAAA"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.67056 6.26971C2.67056 5.85315 3.00833 5.69294 3.56778 5.69294C4.37 5.69294 5.38333 5.9386 6.18555 6.37652V3.8665C5.30944 3.51403 4.44389 3.37518 3.56778 3.37518C1.425 3.37518 0 4.50736 0 6.39788C0 9.34581 4.01111 8.87585 4.01111 10.1469C4.01111 10.6382 3.58889 10.7984 2.99778 10.7984C2.12167 10.7984 1.00278 10.4353 0.116111 9.94394V12.486C1.09778 12.9132 2.09 13.0948 2.99778 13.0948C5.19333 13.0948 6.70278 11.9947 6.70278 10.0828C6.69222 6.89988 2.67056 7.46597 2.67056 6.26971Z"
      fill="#AAAAAA"
    />
  </svg>
);

const Chain = ({ size = '20' }: { size?: string }) => (
  <svg width={size} height={size} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M12.5002 5.83334H15.0002C15.5473 5.83334 16.0892 5.94112 16.5947 6.15051C17.1002 6.35991 17.5595 6.66682 17.9464 7.05373C18.3334 7.44064 18.6403 7.89997 18.8497 8.4055C19.0591 8.91102 19.1668 9.45284 19.1668 10C19.1668 10.5472 19.0591 11.089 18.8497 11.5945C18.6403 12.1 18.3334 12.5594 17.9464 12.9463C17.5595 13.3332 17.1002 13.6401 16.5947 13.8495C16.0892 14.0589 15.5473 14.1667 15.0002 14.1667H12.5002M7.50016 14.1667H5.00016C4.45299 14.1667 3.91117 14.0589 3.40565 13.8495C2.90012 13.6401 2.4408 13.3332 2.05388 12.9463C1.27248 12.1649 0.833496 11.1051 0.833496 10C0.833496 8.89494 1.27248 7.83513 2.05388 7.05373C2.83529 6.27233 3.89509 5.83334 5.00016 5.83334H7.50016"
      stroke="#CBAD73"
      strokeWidth="1.6"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M6.6665 10H13.3332" stroke="#CBAD73" strokeWidth="1.6" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);

const ArrowUp = ({ size = '20' }: { size?: string }) => (
  <svg width={size} height={size} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M10 15.8333V4.16666" stroke="#020202" strokeWidth="1.6" strokeLinecap="round" strokeLinejoin="round" />
    <path
      d="M4.16699 9.99999L10.0003 4.16666L15.8337 9.99999"
      stroke="#020202"
      strokeWidth="1.6"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

const ArrowDown = ({ size = '20' }: { size?: string }) => (
  <svg width={size} height={size} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M10 4.16666V15.8333" stroke="#020202" strokeWidth="1.6" strokeLinecap="round" strokeLinejoin="round" />
    <path
      d="M15.8337 10L10.0003 15.8333L4.16699 10"
      stroke="#020202"
      strokeWidth="1.6"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

const Info = ({ color = '#2976EA', size = '24' }: { color?: string; size?: string }) => (
  <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z"
      stroke={color}
      strokeWidth="1.6"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M12 16V12" stroke={color} strokeWidth="1.6" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M12 8H12.01" stroke={color} strokeWidth="1.6" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);

const Plus = ({
  width = '22',
  height = '20',
  color = '#CBAD73',
}: {
  width?: string;
  height?: string;
  color?: string;
}) => (
  <svg width={width} height={height} viewBox="0 0 22 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M11 4.16666V15.8333" stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M5.16669 10H16.8334" stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);

const Minus = () => (
  <svg width="22" height="20" viewBox="0 0 22 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M5.16666 10H16.8333" stroke="#CBAD73" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);

export { ArrowDown, ArrowUp, Chain, ChevronDown, ChevronUp, Info, Minus, PayPal, Plus, Stripe };

import Cookies from 'js-cookie';
import { instance } from '../axios-instance';
import { GetInvoicesParamsRequest, InvoicesResponseType, uploadPhotoParamsRequestType } from './typeAuthRequest';
// @ts-ignore
export const getProductType = (id) => {
  // TODO
  return instance.get(`product-types/${id}`, {
    headers: { Authorization: `Bearer ${Cookies.get('jwt')}` },
  });
};

export const uploadPhotoForProduct = (data: uploadPhotoParamsRequestType) => {
  return instance.post('file/photo', data, {
    headers: { Authorization: `Bearer ${Cookies.get('jwt')}` },
  });
};
// @ts-ignore
export const uploadAvatarUser = (data) => {
  return instance.post('file/avatar', data, {
    headers: { Authorization: `Bearer ${Cookies.get('jwt')}` },
  });
};

export const removeAvatarUser = () => {
  return instance.delete('file/avatar', {
    headers: { Authorization: `Bearer ${Cookies.get('jwt')}` },
  });
};

export const billingInfo = (data: GetInvoicesParamsRequest) => {
  return instance.get<InvoicesResponseType>('invoices', {
    params: {
      ...data,
    },
    headers: { Authorization: `Bearer ${Cookies.get('jwt')}` },
  });
};

import React from 'react';

import { useMatchMedia } from '../../../hooks/useMatchMedia';

import { starsDesktopImg, starsMobileImg } from '../../../common/images';

import { COLOR_MAP } from '../../../componentUI/colorsMap';
import { TAG_NAME, Z_INDEX_LAYERS } from '../../../componentUI/constants';
import { Title, TITLE_SIZE, TITLE_WEIGHT } from '../../../componentUI/simple/Title';

import { WelcomeBannerNewUserStyled, WrapperImgStyled } from './styled';

export const WelcomeBannerNewUser = () => {
  const { isDesktopS } = useMatchMedia();

  return (
    <WelcomeBannerNewUserStyled isDesktopS={isDesktopS}>
      <Title
        style={{ zIndex: `${Z_INDEX_LAYERS.other}` }}
        size={TITLE_SIZE.h5}
        tag={TAG_NAME.h5}
        colorText={COLOR_MAP.text.darkGrey}
      >
        Welcome to the platform!
      </Title>

      <Title
        style={{ zIndex: `${Z_INDEX_LAYERS.other}` }}
        size={TITLE_SIZE.h6}
        tag={TAG_NAME.h6}
        weight={TITLE_WEIGHT.regular}
        colorText={COLOR_MAP.text.darkGrey}
      >
        Below you&apos;ll find a demo order that you can explore before diving into all the platform&apos;s features.
      </Title>

      <WrapperImgStyled>
        <img width="100%" height="100%" src={isDesktopS ? starsMobileImg : starsDesktopImg} alt="stars" />
      </WrapperImgStyled>
    </WelcomeBannerNewUserStyled>
  );
};

/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { useForgotEmailMutate } from '../../../../api/auth/hooks';

import { useFormField, useIsMobile } from '../../../../hooks';

import { Button } from '../../../../componentUI/simple/Button';

import { COLOR_MAP } from '../../../../componentUI/colorsMap';
import { TAG_NAME } from '../../../../componentUI/constants';
import { Text } from '../../../../componentUI/simple/Text';

import { useGoogleAnalytics } from '../../../../analytics';
import { InputWithError } from '../../../../componentUI/complex/InputWithError';

import { Loader, TermsBlock } from '../../../../components';

import { validateEmail } from '../../../../helpers/validate';

import { AuthContainer } from '../../Auth';
import { MailIcon } from '../../assets';

import { LogoBlock, TitleBlock } from '../../components';

import { ScreenResetPassRequestSuccess } from '../../components/InformationScreens';

import {
  LeftColumnStyled,
  LinkTextStyled,
  RightColumnStyled,
  WrapperInputStyled,
  WrapperResetPasswordStyled,
  WrapperSignInStyled,
  WrapperTextStyled,
} from './styled';

export const ForgotPassword = () => {
  useGoogleAnalytics();

  const navigate = useNavigate();
  const isMobile = useIsMobile();

  const {
    mutate: forgotEmail,
    isSuccess: isSuccessForgotEmailData,
    isError: isErrorForgotEmailData,
    isLoading: isLoadingForgotEmailData,
  } = useForgotEmailMutate();

  const [successChange, setSuccessChange] = useState(false);
  const [email, setEmail, emailError, setEmailError] = useFormField({ initValue: '', withError: true });

  const changeEmail = (email: string) => {
    if (!validateEmail(email) || email.length === 0) {
      setEmailError('Invalid email format');
      setEmail(email);
    } else {
      setEmailError('');
      setEmail(email);
    }
  };

  useEffect(() => {
    if (isSuccessForgotEmailData) {
      setSuccessChange(true);
    }

    if (isErrorForgotEmailData) {
      setSuccessChange(false);
    }
  }, [isSuccessForgotEmailData, isErrorForgotEmailData]);

  if (isLoadingForgotEmailData) {
    return <Loader style={{ margin: '45vh auto' }} />;
  }

  const isDisableSubmitBtn = Boolean(emailError || email.length === 0);

  return (
    <AuthContainer>
      <RightColumnStyled>
        <LogoBlock />

        {successChange && <ScreenResetPassRequestSuccess />}

        {!successChange && (
          <WrapperResetPasswordStyled>
            <TitleBlock titleText="Reset password" subtitleText="Enter your email address to reset password" />

            <WrapperInputStyled>
              <InputWithError
                value={email}
                onChange={(event) => {
                  changeEmail(event);
                }}
                onBlur={(event) => {
                  changeEmail(event.currentTarget.value);
                }}
                placeholder="Email"
                errorMessage={emailError}
                type="email"
                leftIcon={<MailIcon fill={email ? COLOR_MAP.controls.normal : COLOR_MAP.controls.placeholder} />}
              />

              <Button
                onClick={() => {
                  forgotEmail({ email });
                }}
                isStretch
                disabled={isDisableSubmitBtn}
              >
                SUBMIT
              </Button>
            </WrapperInputStyled>

            <WrapperSignInStyled>
              <WrapperTextStyled>
                <Text tag={TAG_NAME.span} colorText={COLOR_MAP.text.white}>
                  Click here to&nbsp;
                </Text>

                <LinkTextStyled
                  tag={TAG_NAME.span}
                  colorText={COLOR_MAP.text.brandColor}
                  onClick={() => {
                    navigate('/signin');
                  }}
                >
                  Sign In
                </LinkTextStyled>
              </WrapperTextStyled>
            </WrapperSignInStyled>
          </WrapperResetPasswordStyled>
        )}

        <TermsBlock />
      </RightColumnStyled>

      {!isMobile && <LeftColumnStyled />}
    </AuthContainer>
  );
};

/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useGoogleAnalytics } from '../../../../analytics';
import { useUpdateMeMutate } from '../../../../api/auth/hooks';
import { Loader, TermsBlock } from '../../../../components';
import { COLOR_MAP } from '../../../../componentUI/colorsMap';
import { Button, BUTTON_SIZE } from '../../../../componentUI/simple/Button';
import { RadioChips } from '../../../../componentUI/simple/Radio';
import { Text, TEXT_WEIGHT } from '../../../../componentUI/simple/Text';
import { CompletionRegistrationHeaderBlock, TitleBlock } from '../../components';
import { AdditionalBasicInfo, BusinessForm, UserFullName } from './components';
import { formingObjectEndpoint, getButtonText } from './helpers';
import { getButtonLockConditions } from './helpers/getButtonLockConditions';
import { locale } from './locale';
import {
  CompletionRegistrationStyled,
  RadioWrapperStyled,
  WrapperAccountInfoStyled,
  WrapperLoaderStyled,
  WrappingInputFieldsStyled,
} from './styled';
import { AccountTypeEnum, FormDataType, StepNumberEnum } from './types';

const { business, personal } = AccountTypeEnum;

const { step1, step2 } = StepNumberEnum;

const { InitFormData, checkpointUserAccountType, stepText } = locale;

export const CompletionRegistration = () => {
  useGoogleAnalytics();

  const navigate = useNavigate();

  // содержит объект с заполненными полями формы Completion Registration
  const [formData, setFormData] = useState<FormDataType>(InitFormData);

  // содержит выбранный пользователем тип аккаунта
  const [accountType, setAccountType] = useState<AccountTypeEnum | string>('');

  // содержит флаг принадлежит ли выбранная страна к EU
  const [isEU, setIsEU] = useState<boolean | null>(null);

  // содержит regex выражение для проверки корректности ввода индекса страны
  // уникальный для каждой страны
  const [postalRegex, setPostalRegex] = useState('');

  // содержит состояние ошибки для блока FullName
  const [errorUserFullName, setErrorUserFullName] = useState(true);

  // содержит состояние ошибки для блока BusinessForm
  const [errorBusinessForm, setErrorBusinessForm] = useState(true);

  // содержит состояние ошибки для выпадающего списка State
  const [stateError, setStateError] = useState(false);

  // содержит состояние ошибки для выпадающего списка Country
  const [countryError, setCountryError] = useState(true);

  // содержит состояние ошибки для выпадающего списка type Business
  const [businessError, setBusinessError] = useState(true);

  // содержит состояние ошибки для accountType
  const [accountTypeError, setAccountTypeError] = useState(true);

  // содержит состояние ошибки для accountType
  const [step, setStep] = useState<StepNumberEnum>(step1);

  const isBusinessAccount = accountType === business;
  const isPersonalAccount = accountType === personal;
  const isStep1 = step === step1;
  const isStep2 = step === step2;

  // пользователь выбрал штат на первом шаге, т.е. страна его проживания USA
  const isSelectState = Boolean(formData?.countryStateId);

  const {
    mutate: updateUserAccount,
    isSuccess: isSuccessUpdateUserAccountData,
    isLoading: isLoadingUpdateUserAccountData,
  } = useUpdateMeMutate();

  useEffect(() => {
    if (isSuccessUpdateUserAccountData) {
      navigate('/');
    }
  }, [isSuccessUpdateUserAccountData]);

  const isDisabledFinishBtn = getButtonLockConditions({
    typeAcc: accountType,
    selectStep: step,
    error: {
      errorUserFullName,
      errorBusinessForm,
      accountTypeError,
      countryError,
      businessError,
      stateError,
    },
  });

  return (
    <>
      <CompletionRegistrationStyled isLoading={isLoadingUpdateUserAccountData}>
        <CompletionRegistrationHeaderBlock />

        <WrapperAccountInfoStyled>
          <TitleBlock titleText="Set up your account" subtitleColor={COLOR_MAP.text.black} />

          <Text weight={TEXT_WEIGHT.bold} colorText={COLOR_MAP.text.brandColor}>
            {!isBusinessAccount && isStep1 && stepText.personal}
            {isBusinessAccount && isStep1 && stepText.businessStep1}
            {isBusinessAccount && isStep2 && stepText.businessStep2}
          </Text>

          {isStep1 && <UserFullName setFormData={setFormData} setErrorUserFullName={setErrorUserFullName} />}

          <WrappingInputFieldsStyled>
            {isStep1 && (
              <RadioWrapperStyled>
                {checkpointUserAccountType.map((element) => {
                  return (
                    <RadioChips
                      key={element.title}
                      name="AccountType"
                      label={element.title}
                      descriptions={element.descriptions}
                      value={element.title}
                      checkedValue={accountType}
                      onChange={(value) => {
                        setAccountType(value as AccountTypeEnum);

                        setAccountTypeError(value === '');
                      }}
                    />
                  );
                })}
              </RadioWrapperStyled>
            )}

            {isBusinessAccount && isStep2 && (
              <BusinessForm
                setFormData={setFormData}
                setErrorBusinessForm={setErrorBusinessForm}
                isEU={isEU}
                postalRegex={postalRegex}
                isSelectState={isSelectState}
              />
            )}

            {isStep1 && (
              <AdditionalBasicInfo
                setFormData={setFormData}
                setCountryError={setCountryError}
                setBusinessError={setBusinessError}
                setStateError={setStateError}
                setIsEU={setIsEU}
                setPostalRegex={setPostalRegex}
                isBusinessAccount={isBusinessAccount}
              />
            )}

            <Button
              size={BUTTON_SIZE.medium}
              isStretch
              disabled={isDisabledFinishBtn}
              onClick={() => {
                if (isBusinessAccount && isStep1) {
                  setStep(step2);
                  return;
                }

                if (isBusinessAccount && isStep2) {
                  updateUserAccount(formingObjectEndpoint({ accountType, formData }));
                  return;
                }

                if (isPersonalAccount && isStep1) {
                  updateUserAccount(formingObjectEndpoint({ accountType, formData }));
                }
              }}
            >
              {getButtonText(accountType, step)}
            </Button>
          </WrappingInputFieldsStyled>
        </WrapperAccountInfoStyled>

        <TermsBlock />
      </CompletionRegistrationStyled>

      {isLoadingUpdateUserAccountData && (
        <WrapperLoaderStyled>
          <Loader />
        </WrapperLoaderStyled>
      )}
    </>
  );
};

import styled from 'styled-components';

import { COLOR_MAP } from '../../../../componentUI/colorsMap';
import { truthfulAdaptive } from '../../../../componentUI/screen';
import { Text } from '../../../../componentUI/simple/Text';

import { BackgroundImage, BackgroundImage600 } from '../../assets';

const RightColumnStyled = styled.div`
  padding: 40px 20px 16px 20px;

  min-height: 100vh;
  width: 100%;

  display: grid;
  grid-template-rows: auto 1fr auto;
  justify-items: center;
  gap: 28px;

  background-color: ${COLOR_MAP.background.darkGrey};

  ${truthfulAdaptive.maxWidth.mobile} {
    padding: 24px 20px 16px 20px;
  }
`;

const WrapperResetPasswordStyled = styled.div`
  align-self: center;

  width: 100%;
  max-width: 484px;

  text-align: center;

  display: flex;
  flex-direction: column;
  gap: 24px;

  ${truthfulAdaptive.maxWidth.mobile} {
    align-self: start;
  }
`;

const WrapperInputStyled = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  gap: 20px;
`;

const WrapperSignInStyled = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 8px;
`;

const LeftColumnStyled = styled.div`
  background-image: url(${BackgroundImage});
  background-size: cover;

  ${truthfulAdaptive.maxWidth.desktop1440} {
    background-image: url(${BackgroundImage600});
  }
`;

const LinkTextStyled = styled(Text)`
  cursor: pointer;

  &:hover {
    opacity: 0.8;
  }
`;

export {
  LeftColumnStyled,
  LinkTextStyled,
  RightColumnStyled,
  WrapperInputStyled,
  WrapperResetPasswordStyled,
  WrapperSignInStyled,
};

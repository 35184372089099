import { COLOR_MAP } from '../componentUI/colorsMap';
import { ResultStatusName } from '../redux/types';

const {
  COMPLETED,
  AWAIT_CERTIFICATE,
  OPEN,
  UPDATED,
  UPDATE_NEEDED,
  ERROR,
  READY,
  DRAFT,
  AWAIT_QR_LABEL,
  DOUBLE_CHECK,
} = ResultStatusName;

export enum Status {
  'Photos needed' = 'Photos needed',
  'Under review' = 'Under review',
  'Completed' = 'Completed',
  'Drafts' = 'Drafts',
}

const STATUS_DICTIONARY: Array<[Array<ResultStatusName>, Status]> = [
  [[UPDATE_NEEDED], Status['Photos needed']],
  [[OPEN, UPDATED, ERROR, DOUBLE_CHECK], Status['Under review']],
  [[READY, COMPLETED, AWAIT_CERTIFICATE, AWAIT_QR_LABEL], Status.Completed],
  [[DRAFT], Status.Drafts],
];

/**
 * цвета в соответствии с категорией продукта
 */
export const STATUS_MAP_COLOR: Record<Status | 'Unknown', string> = {
  'Photos needed': COLOR_MAP.accent.red,
  'Under review': COLOR_MAP.accent.blue,
  Completed: COLOR_MAP.accent.green,
  Drafts: COLOR_MAP.accent.grey20,
  Unknown: '',
};

/**
 * выполняет проверку по словарю и возвращает категорию продукта.
 */
export const getStatusProduct = (status: ResultStatusName): Status | 'Unknown' => {
  return STATUS_DICTIONARY.find((statusItem) => statusItem[0].includes(status))?.[1] || 'Unknown';
};

import { configureStore } from '@reduxjs/toolkit';
import { answerTimeReducer, appReducer, authRequestReducer, productReducer, toastReducer } from './reducers';

export const store = configureStore({
  reducer: {
    app: appReducer,
    products: productReducer,
    authRequest: authRequestReducer,
    answerTime: answerTimeReducer,
    toast: toastReducer,
  },
});

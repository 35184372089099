import React from 'react';

import { COLOR_MAP } from '../../../../../componentUI/colorsMap';
import { SkeletonBasic } from '../../../../../componentUI/skeleton';
import {
  MainContainerGeneralInfoStyled,
  MainFooterContainerStyled,
  MainMainContainerBlockFourStyled,
  MainMainContainerBlockOneStyled,
  MainMainContainerBlockThreeStyled,
  MainMainContainerBlockTwoStyled,
  MainMainContainerGeneralInfoStyled,
} from './styled';

export const AccountSettingsGeneralInfoSkeleton = () => {
  return (
    <MainContainerGeneralInfoStyled>
      <MainMainContainerGeneralInfoStyled>
        <SkeletonBasic width="180px" height="38px" bgColor={COLOR_MAP.controls.skeleton.content2} />

        <MainMainContainerBlockOneStyled>
          <SkeletonBasic width="100px" height="100px" bgColor={COLOR_MAP.controls.skeleton.content1} />

          <MainMainContainerBlockThreeStyled>
            <SkeletonBasic width="200px" height="12px" bgColor={COLOR_MAP.controls.skeleton.content2} />
            <SkeletonBasic width="200px" height="12px" bgColor={COLOR_MAP.controls.skeleton.content2} />
          </MainMainContainerBlockThreeStyled>
        </MainMainContainerBlockOneStyled>

        <MainMainContainerBlockTwoStyled>
          <SkeletonBasic width="420px" height="38px" bgColor={COLOR_MAP.controls.skeleton.content2} />
          <SkeletonBasic width="420px" height="38px" bgColor={COLOR_MAP.controls.skeleton.content2} />
          <SkeletonBasic width="420px" height="38px" bgColor={COLOR_MAP.controls.skeleton.content2} />
        </MainMainContainerBlockTwoStyled>

        <MainMainContainerBlockFourStyled>
          <SkeletonBasic width="200px" height="36px" bgColor={COLOR_MAP.controls.skeleton.content2} />
          <SkeletonBasic width="420px" height="38px" bgColor={COLOR_MAP.controls.skeleton.content2} />
        </MainMainContainerBlockFourStyled>
      </MainMainContainerGeneralInfoStyled>

      <MainFooterContainerStyled>
        <SkeletonBasic width="300px" height="36px" bgColor={COLOR_MAP.controls.skeleton.content1} />
      </MainFooterContainerStyled>
    </MainContainerGeneralInfoStyled>
  );
};

/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';

import { COLOR_MAP } from '../../../componentUI/colorsMap';
import { InputWithError } from '../../../componentUI/complex/InputWithError';
import { Text, TEXT_SIZE, TEXT_WEIGHT } from '../../../componentUI/simple/Text';

import { SelectWithTitleWrapper } from './styled';
import { Props } from './types';

/** Поле для ввода произвольного название модели */

export const NewModelInfo = ({ setInputModelName, inputModelName }: Props) => {
  return (
    <SelectWithTitleWrapper>
      <Text size={TEXT_SIZE.sub} weight={TEXT_WEIGHT.bold} colorText={COLOR_MAP.text.darkGrey}>
        Model
      </Text>

      <InputWithError
        value={inputModelName || ''}
        onChange={(event) => {
          // если пользователь не чего не ввел или
          // ввел пробелы передаем в state ответвленный за
          // хранение значения - null
          if (event.trim() === '') {
            setInputModelName(null);
            return;
          }

          setInputModelName(event);
        }}
        placeholder="Enter name manually"
      />
    </SelectWithTitleWrapper>
  );
};

import React from 'react';

import { SortProduct } from '../../../../../api/product/type';
import { AuthPageType } from '../../../../../common/constants';
import { ArrowDown, ArrowUp } from '../../../../../common/icons/SvgInline/SvgIcons_5';

const {
  resultStatusUp,
  resultStatusDown,
  createdAtUp,
  createdAtDown,
  answerTimeUp,
  answerTimeDown,
  checkStatusUp,
  checkStatusDown,
} = SortProduct;

const ALL = [
  { value: checkStatusUp, label: 'Sort by status', icon: <ArrowUp /> },
  { value: checkStatusDown, label: 'Sort by status', icon: <ArrowDown /> },
  { value: createdAtUp, label: 'Sort by date & time', icon: <ArrowUp /> },
  { value: createdAtDown, label: 'Sort by date & time', icon: <ArrowDown /> },
];

const PHOTOS_NEEDED = [
  { value: createdAtUp, label: 'Sort by date & time', icon: <ArrowUp /> },
  { value: createdAtDown, label: 'Sort by date & time', icon: <ArrowDown /> },
  { value: answerTimeUp, label: 'Sort by answer time', icon: <ArrowUp /> },
  { value: answerTimeDown, label: 'Sort by answer time', icon: <ArrowDown /> },
];

const UNDER_REVIEW = [
  { value: createdAtUp, label: 'Sort by date & time', icon: <ArrowUp /> },
  { value: createdAtDown, label: 'Sort by date & time', icon: <ArrowDown /> },
  { value: answerTimeUp, label: 'Sort by answer time', icon: <ArrowUp /> },
  { value: answerTimeDown, label: 'Sort by answer time', icon: <ArrowDown /> },
];

const COMPLETED = [
  { value: createdAtUp, label: 'Sort by date & time', icon: <ArrowUp /> },
  { value: createdAtDown, label: 'Sort by date & time', icon: <ArrowDown /> },
  { value: resultStatusUp, label: 'Sort by outcome', icon: <ArrowUp /> },
  { value: resultStatusDown, label: 'Sort by outcome', icon: <ArrowDown /> },
];

const DRAFTS = [
  { value: createdAtUp, label: 'Sort by date & time', icon: <ArrowUp /> },
  { value: createdAtDown, label: 'Sort by date & time', icon: <ArrowDown /> },
  { value: answerTimeUp, label: 'Sort by answer time', icon: <ArrowUp /> },
  { value: answerTimeDown, label: 'Sort by answer time', icon: <ArrowDown /> },
];

const OTHER = [
  { value: createdAtUp, label: 'Sort by date & time', icon: <ArrowUp /> },
  { value: createdAtDown, label: 'Sort by date & time', icon: <ArrowDown /> },
];

// обозначение страниц на которых встречается элемент AuthenticationsTablet
const { all, photosNeeded, underReview, completed, drafts, dashboard } = AuthPageType;

/** создает объект содержащий параметры сортировки для таблицы
 *  AuthenticationsTablet доступных к выбору
 * в зависимости от расположения таблицы на различных страницах сайта
 * в виде выпадающего списка */

export const getSortingParameters = (currentPage: string | undefined) => {
  switch (currentPage) {
    case all:
      return ALL;
    case photosNeeded:
      return PHOTOS_NEEDED;
    case underReview:
      return UNDER_REVIEW;
    case completed:
      return COMPLETED;
    case drafts:
      return DRAFTS;
    case dashboard:
      return OTHER;
    default:
      return OTHER;
  }
};

import styled, { CSSProperties } from 'styled-components';

import { COLOR_MAP } from '../../../../componentUI/colorsMap';
import { truthfulAdaptive } from '../../../../componentUI/screen';
import { TEXT_WEIGHT } from '../../../../componentUI/simple/Text';

const PhotoCardV2Styled = styled.div`
  padding: 16px 24px 16px 16px;

  display: grid;
  grid-template-columns: 1fr auto;
  grid-template-rows: 1fr;
  align-content: center;
  gap: 24px;

  border: 1px dashed ${COLOR_MAP.text.grey};
  border-radius: 10px;

  ${truthfulAdaptive.maxWidth.tablet} {
    padding: 12px;

    grid-template-columns: 1fr;
    grid-template-rows: 1fr auto;
    gap: 16px;
  }
`;

const MainContainerStyled = styled.div<{ hasComment: boolean }>`
  display: grid;
  grid-template-columns: 1fr;

  grid-template-areas:
    'infoPhoto'
    ${(props) => props.hasComment && "'comment'"}
    'uploadPhotos';
  grid-gap: 16px;
`;

const InformationPhotoStyled = styled.div`
  grid-area: infoPhoto;
  display: flex;
  gap: 16px;

  ${truthfulAdaptive.maxWidth.mobile} {
    flex-direction: column;
    align-items: center;
    gap: 12px;
  }
`;

const ImageIndexStyled = styled.img`
  border: 2px solid ${COLOR_MAP.accent.orange};
  border-radius: 6px;

  ${truthfulAdaptive.maxWidth.mobile} {
    border-radius: 8px;
  }
`;

const InformationWrapperStyled = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;

  ${truthfulAdaptive.maxWidth.mobile} {
    text-align: center;
  }
`;

const ProblemWrapperStyled = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 12px;
`;

const DescriptionProblemPhotosStyled = styled.div`
  display: flex;
  gap: 4px;
  align-items: center;

  white-space: nowrap;
`;

const CommentWrapperStyled = styled.div`
  grid-area: comment;

  display: flex;
  flex-direction: column;
  gap: 6px;
`;

const CommentDescriptionStyled = styled.div`
  padding: 16px;
  border-radius: 8px;
  background: ${COLOR_MAP.background.lightGrey};

  word-break: break-all;
`;

const UploadPhotosWrapperStyled = styled.div`
  grid-area: uploadPhotos;

  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const WrapperSecondButton = styled.div`
  max-width: 232px;

  display: flex;
  justify-content: center;
  align-items: center;

  ${truthfulAdaptive.maxWidth.tablet} {
    max-width: 100%;
  }
`;

const WrapperLickStyled = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
`;

const stylesForButtons: CSSProperties = {
  textTransform: 'uppercase',
  fontWeight: TEXT_WEIGHT.bold,
};

export {
  CommentDescriptionStyled,
  CommentWrapperStyled,
  DescriptionProblemPhotosStyled,
  ImageIndexStyled,
  InformationPhotoStyled,
  InformationWrapperStyled,
  MainContainerStyled,
  PhotoCardV2Styled,
  ProblemWrapperStyled,
  UploadPhotosWrapperStyled,
  WrapperLickStyled,
  WrapperSecondButton,
  stylesForButtons,
};

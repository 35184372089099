const locale = {
  InitFormData: {
    firstName: '',
    lastName: '',
    country: null,
    typeBusiness: '',
    companyName: '',
    addressOne: '',
    addressTwo: '',
    city: '',
    postalCode: '',
    stateOrProvince: '',
    vatNumber: '',
    countryStateId: '',
  },
  checkpointUserAccountType: [
    { title: 'Personal', descriptions: 'Authenticate branded items for your personal use.' },
    { title: 'Business', descriptions: 'Take advantage of all the features, including invoicing for businesses.' },
  ],
  stepText: {
    personal: 'Step 1 of 1',
    businessStep1: 'Step 1 of 2',
    businessStep2: 'Step 2 of 2',
  },
};

export { locale };

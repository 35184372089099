import React from 'react';

import { TEXT_WEIGHT, Text } from '../../../../componentUI/simple/Text';

import { Props } from './types';

export const CertificateFreeInfo = ({ templateFree, userSubscriptions }: Props) => {
  const isShowInformation = Boolean(templateFree) && Boolean(!userSubscriptions?.result);

  if (!isShowInformation) {
    return null;
  }

  return (
    isShowInformation && (
      <Text
        weight={TEXT_WEIGHT.bold}
      >{`You have used ${templateFree?.used}/${templateFree?.limit} from your monthly free custom certificates.`}</Text>
    )
  );
};

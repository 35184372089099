/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { Text, TEXT_SIZE } from '../../../../componentUI/simple/Text';
import { Checkbox } from '../../../../componentUI/simple/Checkbox';
import { InfoTooltip } from '../../../../componentUI/simple/Tooltip';
import { locale } from './locale';
import { IncludeQrLabelStyled } from './styled';
import { Props } from './types';

export const IncludeQrLabel = ({ isCertificateCustom, itemLabelNeeded, setItemLabelNeeded }: Props) => {
  // динамическая установка и сброс значения includeQrLabel при смене типа сертификата
  useEffect(() => {
    if (isCertificateCustom) {
      setItemLabelNeeded(true);
      return;
    }
    setItemLabelNeeded(false);
  }, [isCertificateCustom]);

  return (
    <IncludeQrLabelStyled>
      <Checkbox label="Include Item label for print" checked={itemLabelNeeded} onChange={setItemLabelNeeded} />
      <InfoTooltip
        widthTooltip="320px"
        positionMobile="left"
        text={<Text size={TEXT_SIZE.sub}>{locale.IncludeQrLabelText}</Text>}
      />
    </IncludeQrLabelStyled>
  );
};

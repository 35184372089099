import { useMutation, useQueryClient } from '@tanstack/react-query';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { markedReadNotification } from '../../../../api/notifications';

import { useProductQuery } from '../../../../api/product/hooks/useProductQuery';
import { QUERY_KEY } from '../../../../common';

import { COLOR_MAP } from '../../../../componentUI/colorsMap';
import { BUTTON_SIZE } from '../../../../componentUI/simple/Button';
import { TEXT_SIZE, Text } from '../../../../componentUI/simple/Text';

import { useStyledModal } from '../../../../hooks';
import { NotificationType } from '../../../../redux/types';
import { UploadRequiredPhotos } from '../../../UploadRequiredPhotos';

import { MatchIcon } from '../../../../common/icons/SvgInline/SvgIcons_3';
import { RightArrow } from '../../../../common/icons/SvgInline/SvgIcons_7';

import { dataDotFormatterNotification } from '../../../../helpers/dataFormatter';
import { getProblematicIndexes } from '../../../../modules/AuthenticationCardV2/helpers';
import {
  SortingFilesIndexesType,
  getImgFilesIndexes,
} from '../../../../modules/AuthenticationCardV2/helpers/getImgFilesIndexes';

import { NotificationPageType } from '../../../../common/constants';

import {
  CheckIconWrapper,
  MessageInformationWrapper,
  NotificationMessageWrapper,
  TertiaryButtonStyled,
  stylesForButton,
} from './styled';

type Props = {
  changeShowNotification: () => void;
  product: NotificationType;
  currentTab: NotificationPageType;
  description: string;
  title: string;
};

const { notViewed } = NotificationPageType;

export const NotificationCard = ({ product, description, title, currentTab, changeShowNotification }: Props) => {
  const { id, productId, createdAt } = product;

  const client = useQueryClient();
  const navigate = useNavigate();

  const { data: product2, isSuccess } = useProductQuery(productId, Boolean(productId));

  const [orderIndexes, setOrderIndexes] = useState<Array<SortingFilesIndexesType>>([]);

  const { mutate: notificationReadMarked } = useMutation({
    mutationFn: markedReadNotification,
    onSuccess: () => {
      client.invalidateQueries({ queryKey: [QUERY_KEY.getNotification] });
    },
  });

  const [Modal, toggleModal] = useStyledModal();

  const onClose = () => {
    notificationReadMarked(`?id=${id}`);
    toggleModal();
  };

  useEffect(() => {
    if (product2) {
      setOrderIndexes(getImgFilesIndexes(product2));
    }
  }, [isSuccess]);

  const problematicIndexes = getProblematicIndexes(orderIndexes);

  return (
    <NotificationMessageWrapper>
      {product2 && (
        <Modal onClose={toggleModal}>
          <UploadRequiredPhotos problematicIndexes={problematicIndexes} onClose={onClose} />
        </Modal>
      )}
      {currentTab === notViewed && (
        <CheckIconWrapper>
          <MatchIcon color={COLOR_MAP.controls.clickableNormal} />
        </CheckIconWrapper>
      )}

      <MessageInformationWrapper>
        <Text size={TEXT_SIZE.annotation} colorText={COLOR_MAP.text.grey}>
          {dataDotFormatterNotification(createdAt)}
        </Text>
        <Text>{title}</Text>
        <Text size={TEXT_SIZE.sub} colorText={COLOR_MAP.text.darkGrey}>
          {description}
        </Text>
      </MessageInformationWrapper>

      <TertiaryButtonStyled
        style={stylesForButton}
        onClick={() => {
          notificationReadMarked(`?id=${id}`);

          navigate(`/card/${productId}`, {
            state: { var: 'completed', page: productId },
          });

          changeShowNotification();
        }}
        size={BUTTON_SIZE.extraSmall}
      >
        View order
        <RightArrow color={COLOR_MAP.accent.gold} size="16" />
      </TertiaryButtonStyled>
    </NotificationMessageWrapper>
  );
};

/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { useAccountInfoQuery } from '../../../api/auth/hooks';
import { useNotificationQuery } from '../../../api/notifications';
import { useGetStatistics } from '../../../api/product/hooks/useGetStatistics';
import { useGetUserSubscriptions } from '../../../api/subscriptions/hooks/useGetUserSubscriptions';
import { useBalanceUserInfoQuery } from '../../../api/userCredits/hooks';

import { getFilteredNotifications } from '../../../helpers';

import { TermsBlock } from '../../../components';

import {
  AcademyIcon,
  AuthenticationsIcon,
  BalanceIcon,
  DashboardIcon,
  HelpCenterIcon,
} from '../../../common/icons/SvgInline/SvgIcons_9';

import { ButtonBlock } from '../components/ButtonBlock';
import { IsNewUser } from '../components/IsNewUser';
import { OrderStatistics } from '../components/OrderStatistics';
import { SectionBlock } from '../components/SectionBlock';
import { Title } from '../components/Title';
import { UserArea } from '../components/UserArea';

import {
  ColumnBlockOneStyled,
  ColumnBlockTwoStyled,
  ContainerColumnMenu,
  HorizonLine,
  MenuWrapper,
  WrapperNavigateBlockStyled,
} from './styles';

type Props = {
  isSidebar?: boolean;
  closeHandler?: () => void;
};

export const ColumnMenu = ({ isSidebar, closeHandler }: Props) => {
  const navigate = useNavigate();

  const { data: dataUserSubscriptions } = useGetUserSubscriptions();
  const { data: statisticsData } = useGetStatistics();
  const { data: dataBalanceUserInfo } = useBalanceUserInfoQuery();
  const { isLoading: isLoadingAccountInfo, data: dataAccountInfo } = useAccountInfoQuery(null, true);

  const { data } = useNotificationQuery();
  const products = getFilteredNotifications(data?.notifications);

  const [hasNotification, setHasNotification] = useState(false);

  const isSubscriptions = dataUserSubscriptions?.result;

  useEffect(() => {
    if (products?.length) {
      setHasNotification(true);
    } else {
      setHasNotification(false);
    }
  }, [products]);

  const [fixedPosition, setFixedPosition] = useState(false);
  const menuWrapperRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleResize = () => {
      const firstColumnHeight = menuWrapperRef.current?.offsetHeight;
      const windowHeight = window.innerHeight;

      if (firstColumnHeight && windowHeight) {
        setFixedPosition(windowHeight > firstColumnHeight);
      }
    };

    handleResize();

    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const userAvatarImg = dataAccountInfo?.avatar?.path || null;
  const userAccountName = dataAccountInfo?.firstName || null;

  const isBlockAuthentications = Boolean(
    statisticsData?.photosNeed || statisticsData?.underReview || statisticsData?.completed,
  );

  return (
    <MenuWrapper isSidebar={isSidebar}>
      <ContainerColumnMenu isSidebar={isSidebar} fixedPosition={fixedPosition} ref={menuWrapperRef}>
        <ColumnBlockOneStyled>
          <Title
            closeHandler={closeHandler}
            isSubscriptions={isSubscriptions}
            isSidebar={isSidebar}
            hasNotification={hasNotification}
            userAvatarImg={userAvatarImg}
            isLoadingAccountInfo={isLoadingAccountInfo}
            userAccountName={userAccountName}
          />

          <WrapperNavigateBlockStyled>
            <SectionBlock
              text="Dashboard"
              Icon={DashboardIcon}
              onClick={() => {
                navigate('/dashboard');
                if (isSidebar && closeHandler) {
                  closeHandler();
                }
              }}
            />

            <SectionBlock
              isDisabled={!isBlockAuthentications}
              text="Authentications"
              Icon={AuthenticationsIcon}
              onClick={() => {
                navigate('/authentications/all');
                if (isSidebar && closeHandler) {
                  closeHandler();
                }
              }}
            />

            <OrderStatistics
              closeHandler={closeHandler}
              idExternalData={false}
              externalData={statisticsData}
              design="colum"
            />

            <SectionBlock
              isBalance
              creditsVolume={dataBalanceUserInfo?.balanceInfo.creditsVolume}
              text="Balance"
              Icon={BalanceIcon}
              onClick={() => {
                navigate('/balance');
                if (isSidebar && closeHandler) {
                  closeHandler();
                }
              }}
            />

            <SectionBlock
              text="Help center"
              Icon={HelpCenterIcon}
              onClick={() =>
                window.open(
                  'https://legitgrails.zendesk.com/hc/en-us/sections/25638946987409-Account',
                  '_blank',
                  'noopener,noreferrer',
                )
              }
            />

            <SectionBlock
              text="Academy"
              Icon={AcademyIcon}
              onClick={() => window.open('https://legitgrails.getlearnworlds.com', '_blank', 'noopener,noreferrer')}
            />

            <HorizonLine />
          </WrapperNavigateBlockStyled>
        </ColumnBlockOneStyled>

        <ColumnBlockTwoStyled>
          <ButtonBlock closeHandler={closeHandler} />

          <UserArea isSidebar={isSidebar} closeHandler={closeHandler} />

          <TermsBlock />
        </ColumnBlockTwoStyled>
      </ContainerColumnMenu>

      <IsNewUser />
    </MenuWrapper>
  );
};

/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { NotificationType } from '../../../../componentUI/types';
import { addToast } from '../../../../redux/reducers/toast-reducer';

import { useGetCSVMutate } from '../../../../api/product/hooks/useGetCSVMutate';
import { useGetUserSubscriptions } from '../../../../api/subscriptions/hooks/useGetUserSubscriptions';

import { DataBaseIcon } from '../../../../common/icons/SvgInline/SvgIcons_7';

import { TAG_NAME } from '../../../../componentUI/constants';
import { BUTTON_SIZE, CrossButton, SecondButton } from '../../../../componentUI/simple/Button';
import { Radio } from '../../../../componentUI/simple/Radio';
import { TITLE_SIZE, TITLE_WEIGHT, Title } from '../../../../componentUI/simple/Title';

import { exportCSVSettings } from './helpers';
import { locale } from './locale';
import { ControlStyled, ExportCSVStyled, WrapperTitleStyled } from './styled';
import { CVS_TYPE, Props } from './types';

const { CurrentTable, CurrentPage, SearchAndFilter } = CVS_TYPE;

export const ExportCSV = ({ paramsGetProductQuery, page }: Props) => {
  const dispatch = useDispatch();

  // содержит текущую таблицу которую выбрал пользовать
  // в блоке Pagination таблицы AuthenticationsTablet
  const selectedTablePage = paramsGetProductQuery.page;

  const [showChosenBlock, setShowChosenBlock] = useState(false);
  const [checkedImport, setCheckedImport] = useState('');

  const paramsGetCSVQuery = exportCSVSettings(paramsGetProductQuery, checkedImport);

  const { data: userSubscriptionsData } = useGetUserSubscriptions();

  const isSubscriptions = userSubscriptionsData?.result;

  const {
    mutate: exportCSV,
    data: dataExportCSV,
    isSuccess: isSuccessExportCSV,
    isLoading: isLoadingExportCSV,
  } = useGetCSVMutate();

  useEffect(() => {
    if (isSuccessExportCSV) {
      const blob = new Blob([dataExportCSV as unknown as BlobPart], { type: 'text/csv' });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.setAttribute('href', url);
      a.setAttribute('download', 'legitGrails.csv');
      a.click();
      setShowChosenBlock(false);
    }
  }, [isSuccessExportCSV]);

  useEffect(() => {
    if (isSuccessExportCSV) {
      dispatch(
        addToast({
          // eslint-disable-next-line prettier/prettier
          text: 'Your export is ready. If you don`t see your file, you can try downloading it again',
          type: NotificationType.Success,
        }),
      );
    }
  }, [isSuccessExportCSV]);

  // если подписки нет, функционал экспорта не показываем
  if (!isSubscriptions) {
    return null;
  }

  return (
    isSubscriptions && (
      <ExportCSVStyled>
        <SecondButton
          style={{ whiteSpace: 'nowrap' }}
          onClick={() => setShowChosenBlock((prev) => !prev)}
          size={BUTTON_SIZE.extraSmall}
          isStretch
        >
          EXPORT CSV
        </SecondButton>

        <ControlStyled showChosenBlock={showChosenBlock}>
          <WrapperTitleStyled>
            <Title tag={TAG_NAME.h5} size={TITLE_SIZE.h5} weight={TITLE_WEIGHT.normal}>
              Export CSV
            </Title>

            <CrossButton onClick={() => setShowChosenBlock(false)} />
          </WrapperTitleStyled>

          <Radio
            value={CurrentTable}
            checkedValue={checkedImport}
            onChange={setCheckedImport}
            name="CVS"
            label={`Current table: ${page && locale[page]}`}
            style={{ gap: '8px' }}
          />

          <Radio
            value={CurrentPage}
            checkedValue={checkedImport}
            onChange={setCheckedImport}
            name="CVS"
            label={`Current page: Page ${selectedTablePage}`}
            style={{ gap: '8px' }}
          />

          <Radio
            value={SearchAndFilter}
            checkedValue={checkedImport}
            onChange={setCheckedImport}
            name="CVS"
            label="Matching your search & filter"
            style={{ gap: '8px' }}
          />

          <SecondButton
            onClick={() => exportCSV(paramsGetCSVQuery)}
            size={BUTTON_SIZE.extraSmall}
            disabled={!checkedImport || isLoadingExportCSV}
          >
            <DataBaseIcon />
            EXPORT
          </SecondButton>
        </ControlStyled>
      </ExportCSVStyled>
    )
  );
};

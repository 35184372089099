import React from 'react';

import { useIsMobile } from '../../../../hooks';

import { LogoMain } from '../../../../common/images';

import { Picture } from '../../../../componentUI/complex/Picture';

export const LogoBlock = () => {
  const isMobile = useIsMobile();

  return <Picture url={LogoMain} width={isMobile ? 48 : 72} height={isMobile ? 48 : 72} alt="main logo" />;
};

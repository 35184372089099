/* eslint-disable max-len */
import React, { lazy, Suspense } from 'react';
import { Navigate } from 'react-router-dom';
import { SvgFromDevelopers } from '../common/icons/SvgInline/SvgFromDevelopers';
import { AppRouterSkeleton } from '../components/AppRouter/skeleton';
import { BaseLayout } from '../components/BaseLayout';
import { Promo } from '../components/Promo';
import { PhotoFromClickUp } from '../modules';
import { ConditionsSubscription } from '../modules/AccountSettings/containers/Subscriptions/containers/ConditionsSubscription';
import { AccountSettingsMainSkeleton } from '../modules/AccountSettings/skeleton';
import { ConfirmEmailRedesign } from '../modules/AuthRedisign/components/ConfirmEmail/ConfirmEmail';
import {
  ScreenSignUpEmailConfirmed,
  ScreenSignUpEmailSuccess,
  ScreenSignUpError,
} from '../modules/AuthRedisign/components/InformationScreens';
import { ForgotPassword } from '../modules/AuthRedisign/componentsRedisign/ForgotPassword';
import { ResetPasswordRedisSign } from '../modules/AuthRedisign/componentsRedisign/ResetPasswordRedisSign';
import { SignIn } from '../modules/AuthRedisign/componentsRedisign/SignIn';
import { SignUp } from '../modules/AuthRedisign/componentsRedisign/SignUp';
import { BalanceWrapperMainSkeleton } from '../modules/Balance/skeleton/WrapperSkeleton';
import { FailureTopUp } from '../modules/TopUpV3/components/FailureTopUp';
import { TopUpMainSkeleton } from '../modules/TopUpV3/skeleton';
import { Transactions } from '../modules/Transactions/modules';

const Dashboard = lazy(() => import('../modules/DashboardV2/containers/Dashboard'));
const AuthenticationsV2 = lazy(() =>
  import('../modules/AuthenticationsV2/containers/AuthenticationsV2/AuthenticationsV2'),
);
const AuthenticateNew = lazy(() => import('../modules/Authenticate/containers/AuthenticateNew'));
const AuthenticateDraft = lazy(() => import('../modules/Authenticate/containers/AuthenticateDraft'));
const TopUp = lazy(() => import('../modules/TopUpV3/container/TopUp/TopUp'));
const AccountSettings = lazy(() => import('../modules/AccountSettings/AccountSettings'));
const Balance = lazy(() => import('../modules/Balance/containers/Balance/Balance'));
const AuthenticationCard = lazy(() => import('../modules/AuthenticationCardV2/containers/AuthenticationCard'));
const SuccessTopUp = lazy(() => import('../modules/TopUpV3/components/SuccessTopUp/SuccessTopUp'));
const Conditions = lazy(() => import('../modules/Conditions/Conditions'));
const SuccessPayment = lazy(() => import('../modules/Authenticate/components/SuccessPayment/SuccessPayment'));

export const publicRoutes = [
  { path: '/promo/:id', component: <Promo /> },

  { path: '/signin', component: <SignIn /> },

  { path: '/signup', component: <SignUp /> },

  { path: '/confirmed-registered', component: <ScreenSignUpEmailSuccess /> },

  {
    path: '/conditions',
    component: (
      <Suspense fallback={<AppRouterSkeleton />}>
        <Conditions />
      </Suspense>
    ),
  },

  { path: '/forgot-password', component: <ForgotPassword /> },

  { path: '/password-change/:hash', component: <ResetPasswordRedisSign /> },

  { path: '/album-private/:id', component: <PhotoFromClickUp /> },

  { path: '/confirm-email/:hash', component: <ConfirmEmailRedesign /> },

  { path: '/confirm-email/EmailConfirmed', component: <ScreenSignUpEmailConfirmed /> },

  { path: '/confirm-email/EmailError', component: <ScreenSignUpError /> },
];

export const privateRoutes = [
  { path: '/signin', component: <Navigate to="/dashboard" /> },

  { path: '/signup', component: <Navigate to="/dashboard" /> },

  {
    path: '/conditions',
    component: (
      <Suspense fallback={<AppRouterSkeleton />}>
        <Conditions />
      </Suspense>
    ),
  },

  { path: '/conditions-subscription', component: <ConditionsSubscription /> },

  { path: '', component: <Navigate to="dashboard" /> },

  { path: '/promo/:id', component: <Promo isAuth /> },

  {
    path: 'authentications/:page',
    component: (
      <Suspense>
        <BaseLayout rubber>
          <AuthenticationsV2 />
        </BaseLayout>
      </Suspense>
    ),
  },

  {
    path: 'dashboard',
    component: (
      <Suspense fallback={<AppRouterSkeleton />}>
        <BaseLayout rubber>
          <Dashboard />
        </BaseLayout>
      </Suspense>
    ),
  },

  {
    path: 'card/:id',
    component: (
      <Suspense fallback={<AppRouterSkeleton />}>
        <BaseLayout>
          <AuthenticationCard />
        </BaseLayout>
      </Suspense>
    ),
  },

  {
    path: 'top-up',
    component: (
      <Suspense fallback={<TopUpMainSkeleton />}>
        <BaseLayout isSummaryBlock rubber>
          <TopUp />
        </BaseLayout>
      </Suspense>
    ),
  },

  {
    path: 'success',
    component: (
      <Suspense fallback={<AppRouterSkeleton />}>
        <BaseLayout rubber>
          <SuccessTopUp />
        </BaseLayout>
      </Suspense>
    ),
  },
  {
    path: 'success-order',
    component: (
      <Suspense fallback={<AppRouterSkeleton />}>
        <BaseLayout rubber>
          <SuccessPayment />
        </BaseLayout>
      </Suspense>
    ),
  },
  {
    path: 'unsuccess',
    component: (
      <BaseLayout rubber>
        <FailureTopUp />
      </BaseLayout>
    ),
  },

  {
    path: 'authenticate',
    component: (
      <Suspense>
        <BaseLayout isSummaryBlock isNotDvh>
          <AuthenticateNew />
        </BaseLayout>
      </Suspense>
    ),
  },

  {
    path: '/authenticate/draft/:id',
    component: (
      <Suspense>
        <BaseLayout isSummaryBlock isNotDvh>
          <AuthenticateDraft />
        </BaseLayout>
      </Suspense>
    ),
  },

  {
    path: 'transactions',
    component: (
      <BaseLayout>
        <Transactions />
      </BaseLayout>
    ),
  },

  {
    path: 'balance',
    component: (
      <Suspense fallback={<BalanceWrapperMainSkeleton />}>
        <BaseLayout>
          <Balance />
        </BaseLayout>
      </Suspense>
    ),
  },

  { path: 'album-private/:id', component: <PhotoFromClickUp /> },

  { path: '/confirm-email/EmailConfirmed', component: <ScreenSignUpEmailConfirmed /> },

  {
    path: 'account-settings/:page',
    component: (
      <Suspense fallback={<AccountSettingsMainSkeleton />}>
        <BaseLayout rubber>
          <AccountSettings />
        </BaseLayout>
      </Suspense>
    ),
  },
  // костыль так как были вырезаны необязательные параметры в 6м react-router - вроде в версии 6.5.0 вернули нужно апать
  {
    path: 'account-settings/:page/:paymentSubscription',
    component: (
      <Suspense fallback={<AccountSettingsMainSkeleton />}>
        <BaseLayout rubber>
          <AccountSettings />
        </BaseLayout>
      </Suspense>
    ),
  },
];

if (process.env.REACT_APP_SVG) {
  privateRoutes.push({ path: 'SvgFromDevelopers', component: <SvgFromDevelopers /> });
  publicRoutes.push({ path: 'SvgFromDevelopers', component: <SvgFromDevelopers /> });
}

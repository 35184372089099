import { NAME_OBJECT_GET_PRODUCT, OptionsMasterDropdownListType } from './types';

const { publicId, date, brand, modelName, sku, checkStatus } = NAME_OBJECT_GET_PRODUCT;

export const OPTIONS_MASTER_DROPDOWN_LIST: Array<OptionsMasterDropdownListType> = [
  { key: 'Unique code', value: publicId, label: 'Unique code' },
  { key: 'date', value: date, label: 'Date' },
  { key: 'Brand', value: brand, label: 'Brand' },
  { key: 'Model name', value: modelName, label: 'Model name' },
  { key: 'SKU', value: sku, label: 'SKU' },
  { key: 'Outcome', value: checkStatus, label: 'Outcome' },
];

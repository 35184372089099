/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { sendUserEvent, useGoogleAnalytics } from '../../../../analytics';

import { useFormField, useMatchMedia } from '../../../../hooks';

import { HASH_KEY, TermsBlock, ThemeEnum } from '../../../../components';
import { VisualizationPassSecurity } from '../../../../components/CheckPassword/components';
import { usePasswordIconManage } from '../../../../components/CheckPassword/helpers/usePasswordIconManage';

import { getAuthGoogle } from '../../../../api/auth/auth-api';
import { useRegisterMutate } from '../../../../api/auth/hooks';

import { COLOR_MAP } from '../../../../componentUI/colorsMap';
import { InputWithError } from '../../../../componentUI/complex/InputWithError';
import { Picture } from '../../../../componentUI/complex/Picture';
import { TAG_NAME } from '../../../../componentUI/constants';
import { Button, SecondButton } from '../../../../componentUI/simple/Button';
import { Checkbox } from '../../../../componentUI/simple/Checkbox';
import { LinkClassic } from '../../../../componentUI/simple/Link';
import { Text } from '../../../../componentUI/simple/Text';

import { AuthContainer } from '../../Auth';
import { GoogleLogo, LockIcon } from '../../assets';

import { DescriptionList, LogoBlock, TitleBlock } from '../../components';

import { validateEmail, validatePassword, verificationConditionsPassword } from '../../../../helpers/validate';

import {
  ContainerSignUpStyled,
  LeftColumnStyled,
  RightColumnStyled,
  SeparatorStyled,
  WrapperBtnSingUpStyled,
  WrapperConditionsStyled,
  WrapperSeparatorStyled,
  WrapperSignInStyled,
  WrapperSignUpStyled,
  WrapperTextStyled,
} from './styled';

/** Содержит страницу регистрации
 * нового пользователя */

export const SignUp = () => {
  useGoogleAnalytics();

  const navigate = useNavigate();
  const { isMobile } = useMatchMedia();

  const {
    mutate: registerUser,
    isLoading: isLoadingRegisterUser,
    isSuccess: isSuccessRegisterUser,
    data: dataRegisterUser,
  } = useRegisterMutate();

  const [email, setEmail, emailError, setEmailError] = useFormField({
    initValue: '',
    withError: true,
  });

  const [newPassword, setNewPassword, newPasswordError, setNewPasswordError] = useFormField({
    initValue: '',
    withError: true,
  });

  const [termsAndConditions, setTermsAndConditions] = useState(false);
  const { rightIconPassword, passTypeInput } = usePasswordIconManage(newPassword);
  const [infoValidPass, setInfoValidPass] = useState({
    isLength: false,
    isNumber: false,
    isSpecialSymbol: false,
    isLowerCase: false,
    isUpperCase: false,
  });

  const changeEmail = (dataEmail: string) => {
    if (!validateEmail(dataEmail) || dataEmail.length === 0) {
      setEmailError('Invalid email address format');
      setEmail(dataEmail);
    } else {
      setEmailError('');
      setEmail(dataEmail);
    }
  };

  const changePassword = (newPassword: string) => {
    setInfoValidPass((prev) => {
      const resultCheck = verificationConditionsPassword(newPassword);
      return { ...prev, ...resultCheck };
    });

    setNewPasswordError(validatePassword(newPassword) ? '' : 'Password doesn’t pass requirements');
    setNewPassword(newPassword);
  };

  const handleSubmit = () => {
    // шлем хэш при переходе с пополнением через лэндинг
    const hash = window.localStorage.getItem(HASH_KEY);

    registerUser({
      email,
      password: newPassword,
      registrationSourceHash: hash && hash !== 'undefined' ? hash : null,
    });

    sendUserEvent('Sign up button clicked');
  };

  useEffect(() => {
    if (dataRegisterUser) {
      navigate('/confirmed-registered');
    }
  }, [isSuccessRegisterUser]);

  const isDisabledSubmitAndSend = Boolean(
    !termsAndConditions || isLoadingRegisterUser || !newPassword || newPasswordError || !email || emailError,
  );

  return (
    <AuthContainer>
      <RightColumnStyled>
        <LogoBlock />

        <WrapperSignUpStyled>
          <TitleBlock titleText="Welcome to LegitGrails!" subtitleText="Sign up using email address" />

          <ContainerSignUpStyled>
            <InputWithError
              value={email}
              onChange={(event) => {
                changeEmail(event);
              }}
              onBlur={(event) => {
                changeEmail(event.currentTarget.value);
              }}
              placeholder="Email address *"
              errorMessage={emailError}
              type="email"
            />

            <InputWithError
              value={newPassword}
              onChange={(event) => {
                changePassword(event);
              }}
              onBlur={(event) => {
                changePassword(event.currentTarget.value);
              }}
              placeholder="Password *"
              errorMessage={newPasswordError}
              type={passTypeInput}
              leftIcon={<LockIcon fill={newPassword ? COLOR_MAP.controls.normal : COLOR_MAP.controls.placeholder} />}
              rightIcon={rightIconPassword}
            />

            <VisualizationPassSecurity
              infoValidPass={infoValidPass}
              password={newPassword}
              designTheme={ThemeEnum.white}
            />

            <Checkbox
              onChange={() => setTermsAndConditions(!termsAndConditions)}
              checked={termsAndConditions}
              id="conditions"
              label={
                <WrapperConditionsStyled>
                  <Text colorText={COLOR_MAP.text.white}>I accept&nbsp;</Text>

                  <LinkClassic href="/conditions" target="_blank" rel="noopener noreferrer">
                    Terms & Conditions
                  </LinkClassic>
                </WrapperConditionsStyled>
              }
            />

            <Button isStretch disabled={isDisabledSubmitAndSend} onClick={() => handleSubmit()}>
              CREATE A NEW ACCOUNT
            </Button>

            <WrapperSeparatorStyled>
              <SeparatorStyled />
              <Text colorText={COLOR_MAP.text.white}>or</Text>
              <SeparatorStyled />
            </WrapperSeparatorStyled>

            <WrapperBtnSingUpStyled>
              <SecondButton
                style={{ gap: '10px' }}
                isStretch
                onClick={() => getAuthGoogle()}
                linkButton="/auth/google/oauth-link-create"
              >
                <Picture url={GoogleLogo} width={24} height={24} alt="google logo" />
                SIGN UP WITH GOOGLE
              </SecondButton>

              <WrapperSignInStyled>
                <Text tag={TAG_NAME.span} colorText={COLOR_MAP.text.white}>
                  Already have an account?
                </Text>

                <WrapperTextStyled>
                  <Text tag={TAG_NAME.span} colorText={COLOR_MAP.text.white}>
                    Click here to&nbsp;
                  </Text>

                  <LinkClassic href="/signin">Sign In</LinkClassic>
                </WrapperTextStyled>
              </WrapperSignInStyled>
            </WrapperBtnSingUpStyled>
          </ContainerSignUpStyled>
        </WrapperSignUpStyled>

        <TermsBlock />
      </RightColumnStyled>

      {!isMobile && (
        <LeftColumnStyled>
          <DescriptionList />
        </LeftColumnStyled>
      )}
    </AuthContainer>
  );
};

import styled from 'styled-components';

import { COLOR_MAP } from '../../../../../componentUI/colorsMap';

const MainMainContainerGeneralInfoStyled = styled.div`
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  gap: 36px;
`;

const MainMainContainerBlockOneStyled = styled.div`
  display: flex;

  gap: 10px;
`;

const MainMainContainerBlockTwoStyled = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const MainMainContainerBlockThreeStyled = styled.div`
  width: 100%;

  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 10px;
`;

const MainMainContainerBlockFourStyled = styled.div`
  width: 100%;

  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const MainFooterContainerStyled = styled.div`
  padding: 20px 0;
  width: 100%;

  display: flex;
  flex-direction: column;
  gap: 20px;

  border-top: 1px solid ${COLOR_MAP.controls.skeleton.content1};
`;

const MainContainerGeneralInfoStyled = styled.div`
  min-height: 115%;

  display: flex;
  flex-direction: column;
`;

export {
  MainContainerGeneralInfoStyled,
  MainFooterContainerStyled,
  MainMainContainerBlockFourStyled,
  MainMainContainerBlockOneStyled,
  MainMainContainerBlockThreeStyled,
  MainMainContainerBlockTwoStyled,
  MainMainContainerGeneralInfoStyled,
};

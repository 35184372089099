import React, { Dispatch, SetStateAction, useState } from 'react';

import { COLOR_MAP } from '../../componentUI/colorsMap';

import { InputWithError } from '../../componentUI/complex/InputWithError';
import { VisualizationPassSecurity } from './components';

import {} from '../../common/icons/SvgInline/SvgIcons_3';
import { validatePassword, verificationConditionsPassword } from '../../helpers/validate';
import { usePasswordIconManage } from './helpers/usePasswordIconManage';

import { LockIcon, PasswordMatchVerificationIcon } from './assets';

import { ResetPasswordStyled } from './styled';

export enum ThemeEnum {
  black = 'black',
  white = 'white',
}

type Props = {
  newPassword: string;
  setNewPassword: Dispatch<SetStateAction<string>>;
  newPasswordError: string;
  setNewPasswordError: Dispatch<SetStateAction<string>>;

  confirmPassword: string;
  setConfirmPassword: Dispatch<SetStateAction<string>>;
  confirmPasswordError: string;
  setConfirmPasswordError: Dispatch<SetStateAction<string>>;

  designTheme: ThemeEnum;
  placeholderText: {
    placeholderOne: string;
    placeholderTwo: string;
  };
};

export const CheckPassword = ({
  newPassword,
  setNewPassword,
  newPasswordError,
  setNewPasswordError,
  confirmPassword,
  setConfirmPassword,
  confirmPasswordError,
  setConfirmPasswordError,

  designTheme = ThemeEnum.black,
  placeholderText,
}: Props) => {
  const { rightIconPassword, passTypeInput } = usePasswordIconManage(newPassword);

  const [infoValidPass, setInfoValidPass] = useState({
    isLength: false,
    isNumber: false,
    isSpecialSymbol: false,
    isLowerCase: false,
    isUpperCase: false,
  });

  const changePassword = (newPassword: string) => {
    setInfoValidPass((prev) => {
      const resultCheck = verificationConditionsPassword(newPassword);
      return { ...prev, ...resultCheck };
    });

    setNewPasswordError(validatePassword(newPassword) ? '' : 'Password doesn’t pass requirements');
    setNewPassword(newPassword);
  };

  const changeConfirmPassword = (confirmPassword: string, newPassword: string) => {
    setConfirmPasswordError(newPassword === confirmPassword ? '' : 'Password doesn’t match');
    setConfirmPassword(confirmPassword);
  };

  return (
    <ResetPasswordStyled>
      <InputWithError
        value={newPassword}
        onChange={(event) => {
          changePassword(event);
          changeConfirmPassword(confirmPassword, event);
        }}
        onBlur={(event) => {
          changePassword(event.currentTarget.value);
          changeConfirmPassword(confirmPassword, event.currentTarget.value);
        }}
        placeholder={placeholderText.placeholderOne}
        errorMessage={newPasswordError}
        type={passTypeInput}
        leftIcon={<LockIcon fill={newPassword ? COLOR_MAP.controls.normal : COLOR_MAP.controls.placeholder} />}
        rightIcon={rightIconPassword}
      />

      <InputWithError
        value={confirmPassword}
        onChange={(event) => {
          changeConfirmPassword(event, newPassword);
        }}
        onBlur={(event) => {
          changeConfirmPassword(event.currentTarget.value, newPassword);
        }}
        placeholder={placeholderText.placeholderTwo}
        errorMessage={confirmPasswordError}
        type="password"
        leftIcon={<LockIcon fill={newPassword ? COLOR_MAP.controls.normal : COLOR_MAP.controls.placeholder} />}
        rightIcon={<PasswordMatchVerificationIcon isValid={confirmPasswordError} password={newPassword} />}
      />

      <VisualizationPassSecurity infoValidPass={infoValidPass} password={newPassword} designTheme={designTheme} />
    </ResetPasswordStyled>
  );
};

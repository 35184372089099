/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Route, Routes, useNavigate } from 'react-router-dom';

import { getAnglesListThunk, getResultsStatusesThunk, takeResultStatuses } from '../../redux';

import { privateRoutes } from '../../router';

import { CompletionRegistration } from '../../modules/AuthRedisign/componentsRedisign/CompletionRegistration';
import { ErrorPage404 } from '../ErrorsPage';

type Props = {
  isProfileFull: boolean;
};

export const StartPrivateSession = ({ isProfileFull }: Props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const resultStatuses = useSelector(takeResultStatuses);

  useEffect(() => {
    dispatch(getAnglesListThunk());
    if (!resultStatuses) {
      dispatch(getResultsStatusesThunk());
    }
  }, []);

  // перекидываем на страницу заполнения профиля
  // если он не заполнен до конца, актуально для новых пользователей
  useEffect(() => {
    if (!isProfileFull) {
      navigate('/completion-registration');
    }
  }, []);

  return (
    <Routes>
      {privateRoutes.map((el) => (
        <Route key={el.path} element={el.component} path={el.path} />
      ))}
      {!isProfileFull && <Route path="/completion-registration" element={<CompletionRegistration />} />}
      <Route path="*" element={<ErrorPage404 />} />
    </Routes>
  );
};

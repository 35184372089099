/**
 * Возвращает дату в виде - 05.02.2023 18:33
 */
export const dataFormatterViewFive = (date: string): string => {
  return new Date(date)
    .toLocaleDateString('en-GB', {
      day: 'numeric',
      month: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      year: 'numeric',
    })
    .replace(',', ' ')
    .replaceAll('/', '.');
};

/**
 * Возвращает дату в виде - 05.02.2023 at  18:33
 */
export const dataFormatterFromTable = (date: string): string => {
  return new Date(date)
    .toLocaleDateString('en-GB', {
      day: 'numeric',
      month: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      year: 'numeric',
    })
    .replace(',', ' at ')
    .replaceAll('/', '.');
};

/**
 * Возвращает дату в виде - 18/01/2023
 */
export const dataFormatter = (date: string): string => {
  return new Date(date)
    .toLocaleDateString('en-GB', {
      day: 'numeric',
      month: 'numeric',
      year: 'numeric',
    })
    .replace(/ /g, '/');
};

/**
 * Возвращает дату в виде - 18.01.2023
 */
export const dataDotFormatter = (date: string): string => {
  return new Date(date)
    .toLocaleDateString('en-GB', {
      day: 'numeric',
      month: 'numeric',
      year: 'numeric',
    })
    .replaceAll('/', '.');
};

/**
 * Возвращает дату в виде - 9:18, April 26, 2023
 */
export const dataDotFormatterNotification = (date: string): string => {
  const time = new Date(date).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
  const monthDay = new Date(date).toLocaleDateString('en-US', { month: 'long', day: 'numeric' });
  const year = new Date(date).toLocaleDateString([], { year: 'numeric' });

  return `${time}, ${monthDay}, ${year}`;
};

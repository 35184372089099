/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';

import { useFormField } from '../../../../../../hooks';

import { InputWithError } from '../../../../../../componentUI/complex/InputWithError';

import { emptyStringCheck, textErrorCheck } from '../../helpers';

import { CompanyInformationBlockStyled, WrapperCityPostalStated } from './styled';
import { Props } from './types';

export const BusinessForm = ({ setFormData, setErrorBusinessForm, isEU, postalRegex, isSelectState }: Props) => {
  const [companyName, setCompanyName, companyNameError, setCompanyNameError] = useFormField({
    initValue: '',
    withError: true,
  });

  const [addressOne, setAddressOne, addressOneError, setAddressOneError] = useFormField({
    initValue: '',
    withError: true,
  });

  const [city, setCity, cityError, setCityError] = useFormField({
    initValue: '',
    withError: true,
  });

  const [postalCode, setPostalCode, postalCodeError, setPostalCodeError] = useFormField({
    initValue: '',
    withError: true,
  });

  const [addressTwo, setAddressTwo] = useState('');
  const [stateOrProvince, setStateOrProvince] = useState('');
  const [vatNumber, setVatNumber] = useState('');

  const validatePostalCode = (postalCode: string, regExp: string | undefined) => {
    if (!postalCode) {
      setPostalCodeError('Please fill in your company postal code');
    } else {
      setPostalCodeError('');
    }

    if (regExp) {
      const regex = new RegExp(regExp);

      if (!regex.test(postalCode)) {
        setPostalCodeError('Please fill in your company postal code');
      } else {
        setPostalCodeError('');
      }
    }
  };

  const changeCompanyName = (value: string) => {
    setCompanyNameError(value ? '' : 'Please fill in your company name');
    setCompanyName(value);
  };

  const changeAddressOne = (value: string) => {
    setAddressOneError(value ? '' : 'Please fill in your company address');
    setAddressOne(value);
  };

  const changeCity = (value: string) => {
    setCityError(value ? '' : 'Please fill in your company city');
    setCity(value);
  };

  const changePostalCode = (value: string) => {
    setPostalCodeError('');
    setPostalCode(value);
  };

  const onBlurPostalCode = (value: string) => {
    validatePostalCode(value, postalRegex);
  };

  // передаем актуальные данные которые ввел пользователь
  // в поля input в родительский компонент в виде одного объекта
  useEffect(() => {
    setFormData((prev) => {
      return { ...prev, companyName, addressOne, addressTwo, city, postalCode, stateOrProvince, vatNumber };
    });
  }, [companyName, addressOne, addressTwo, city, postalCode, stateOrProvince, vatNumber]);

  // проверяем корректность ввода данных и если они не корректны
  // передаем буллевый флаг ошибки в родительский компонент
  useEffect(() => {
    const isEmptyString = emptyStringCheck([companyName, addressOne, city, postalCode]);
    const isError = textErrorCheck([companyNameError, addressOneError, cityError, postalCodeError]);

    if (isEmptyString || isError) {
      setErrorBusinessForm(true);
      return;
    }

    setErrorBusinessForm(false);
  }, [companyName, addressOne, city, postalCode, companyNameError, addressOneError, cityError, postalCodeError]);

  return (
    <>
      <CompanyInformationBlockStyled>
        <InputWithError
          value={companyName}
          errorMessage={companyNameError}
          onChange={(event) => changeCompanyName(event)}
          onBlur={(event) => changeCompanyName(event.currentTarget.value)}
          placeholder="Company name *"
        />

        <InputWithError
          value={addressOne}
          errorMessage={addressOneError}
          onChange={(event) => changeAddressOne(event)}
          onBlur={(event) => changeAddressOne(event.currentTarget.value)}
          placeholder="Address 1 *"
        />

        <InputWithError value={addressTwo} onChange={setAddressTwo} placeholder="Address 2" />

        <WrapperCityPostalStated>
          <InputWithError
            value={city}
            errorMessage={cityError}
            onChange={(event) => changeCity(event)}
            onBlur={(event) => changeCity(event.currentTarget.value)}
            placeholder="City *"
            maxLength={20}
          />

          <InputWithError
            value={postalCode}
            errorMessage={postalCodeError}
            onChange={(event) => changePostalCode(event)}
            onBlur={(event) => onBlurPostalCode(event.currentTarget.value)}
            placeholder="Postal code*"
            maxLength={20}
          />

          {!isSelectState && (
            <InputWithError
              value={stateOrProvince}
              onChange={setStateOrProvince}
              placeholder="State/Province"
              maxLength={20}
            />
          )}
        </WrapperCityPostalStated>

        {isEU && (
          <InputWithError
            value={vatNumber}
            onChange={(value) => setVatNumber(value)}
            placeholder="VAT number"
            errorMessage=""
          />
        )}
      </CompanyInformationBlockStyled>
    </>
  );
};

import { createAction, createReducer } from '@reduxjs/toolkit';

export const setAnswerTime = createAction('SET_ANSWER_TIME');

const initialState = {
  answerTime: [],
};

export const answerTimeReducer = createReducer(initialState, (builder) => {
  builder.addCase('SET_ANSWER_TIME', (state = initialState, action) => {
    state.answerTime = action.payload;
  });
});

import { CheckStatus, GetProductResponseType, ProductReasonsType } from '../../../api/product/type';
import { FileTemplateHEIC } from '../../../common/images';
import { isHeicOrHeif } from '../../../helpers/isHeicOrHeif';

export type FileImageType = {
  angleId: string;
  mainUrl: string | null;
  reserveUrl: string | null;
  mainName: string | null;
  reserveName: string | null;
  orderIndex: number;
};

type problemsIndexType = {
  angleId: string;
  productId: string;
  orderIndex: number;
  comment: string | null;
  problems: Array<string>;
  unclearPhoto: boolean;
  unclearPhotoUrl: string | null;
  missingPhoto: boolean;
  zendeskUrl: string | null;
};

export type SortingFilesIndexesType = problemsIndexType & {
  name: string;
  fileImage: Array<FileImageType>;
  imageSamples?: Array<string> | null;
};

export type OrIndexType = {
  mainOrIndex: number | null;
  reserveOrIndex: number | null;
};

const { LEGIT } = CheckStatus;

// поле zendeskUrl нужно только индексам по которым есть проблемы

// предустановленное значение для обработки problemsIndex
const PROBLEMS_INDEX_DEFAULT_VALUE = {
  angleId: '',
  productId: '',
  orderIndex: 1,
  comment: null,
  problems: [],
  unclearPhoto: false,
  unclearPhotoUrl: null,
  missingPhoto: false,
  zendeskUrl: null,
};

// получаем наименьший OrderIndex из двух возможных,
// если не найден возвращаем единицу как универсальную позицию.
const getMainOrderIndex = ({ mainOrIndex, reserveOrIndex }: OrIndexType) => {
  const orIndex = [mainOrIndex, reserveOrIndex].sort()[0];

  if (typeof orIndex === 'number') {
    return orIndex;
  }

  return 1;
};

// сортируем OrderIndex от 0 по возрастающей
const sortedOrderIndex = (value: SortingFilesIndexesType[]) => {
  return value.sort((a, b) => a.orderIndex - b.orderIndex);
};

export const getImgFilesIndexes = (product: GetProductResponseType) => {
  // есть ли у заказа статус LEGIT
  const isLegit = product.checkStatus === LEGIT;

  // все файлы/индексы прикрепленные к заказу
  const indexFiles = product.files;

  // массив с описание проблем у файлов/индексов прикрепленных к заказу
  let problematicIndexes: ProductReasonsType[] | null = null;

  // если в reasons есть элементы и этот заказ не имеет статуса LEGIT
  // добавляем данные в переменную problematicIndexes
  if (product.reasons && product.reasons.length > 0 && !isLegit) {
    problematicIndexes = product.reasons;
  }

  // создаем коллекцию с названиями индексов, исключая элементы не содержащии данных в поле angle (файлы pdf)
  const allNameIndex = indexFiles.reduce<Array<string>>((accNameIndex, element) => {
    if (element.angle) {
      return [...accNameIndex, element.angle.publicName];
    }
    return accNameIndex;
  }, []);

  // создаём коллекцию уникальных значений индексов
  const uniqueNamesIndex = Array.from(new Set(allNameIndex));

  // создаем массив объектов с фото удовлетворявшие условию missingPhoto т.е.
  // файл/индекс из поля reasons не встречается среди files
  const missingPhoto = problematicIndexes?.reduce<Array<SortingFilesIndexesType>>((storage, element) => {
    const mainOrderIndex = getMainOrderIndex({
      mainOrIndex: element.angle?.productTypeAngles[0]?.orderIndex,
      reserveOrIndex: element.angle.orderIndex,
    });

    if (element.angle.publicName && uniqueNamesIndex.includes(element.angle.publicName)) {
      return storage;
    }

    const addObject = {
      name: element.angle.publicName || '',
      angleId: element.angleId,
      productId: element.productId,
      // orderIndex используется для сортировки элементов, если в данных полях значение не найдено, будет 1
      orderIndex: mainOrderIndex,
      comment: null,
      problems: [],
      unclearPhoto: false,
      unclearPhotoUrl: null,
      missingPhoto: true,
      zendeskUrl: element.angle?.productTypeAngles[0]?.zendeskUrl || null,
      fileImage: [
        {
          angleId: element.angleId,
          mainUrl: element.angle?.productTypeAngles[0]?.iconUrl || '',
          reserveUrl: element.angle?.iconUrl || '',
          mainName: element.angle?.productTypeAngles[0]?.publicName || '',
          reserveName: element.angle.publicName,
          // в связи с особенностью отбора ниже по функции,
          // дублируем данные orderIndex так же ждя каждого файла фотографии
          orderIndex: mainOrderIndex,
        },
      ],
      imageSamples: element.angle?.imageSamples || null,
    };

    return [...storage, addObject];
  }, []);

  // создаем массив объектов, наполнение согласно типа SortingFilesIndexesType
  const arrAllPhotosSameIndex = uniqueNamesIndex.reduce<Array<SortingFilesIndexesType>>((acc, nameIndex) => {
    // отбираем фотографии принадлежащие одному индексу из общего массива фотографий
    const AllPhotosSameIndex: FileImageType[] = indexFiles.reduce<FileImageType[]>((acc, file) => {
      if (file.angle?.publicName === nameIndex) {
        const mainOrderIndex = getMainOrderIndex({
          mainOrIndex: file.angle.orderIndex,
          reserveOrIndex: file.angle.orderIndex,
        });

        let mainUrl = file.resizedFilePath || file.path;
        if (isHeicOrHeif(file.path) && !file.resizedFilePath) {
          mainUrl = FileTemplateHEIC;
        }

        return [
          ...acc,
          {
            angleId: file.angleId || '',
            mainUrl,
            reserveUrl: null,
            mainName: file.angle.publicName,
            reserveName: null,
            orderIndex: mainOrderIndex,
          },
        ];
      }
      return acc;
    }, []);

    // если по данному файлу/индексу есть проблемы, формируем из них объект,
    // если нет то поля создаем со значениями по умолчанию и заменяем orderIndex на актуальный
    const problemsIndex = problematicIndexes?.reduce<problemsIndexType>((result, element) => {
      const { angleId } = AllPhotosSameIndex[0];

      if (element.angleId === angleId) {
        const mainOrderIndex = getMainOrderIndex({
          mainOrIndex: element.angle?.productTypeAngles[0]?.orderIndex,
          reserveOrIndex: element.angle.orderIndex,
        });

        return {
          ...result,
          ...{
            angleId: element.angleId,
            productId: element.productId,
            orderIndex: mainOrderIndex,
            comment: element.comment,
            problems: element.problems,
            unclearPhoto: true,
            unclearPhotoUrl: element.angle?.iconUrl || element.angle?.productTypeAngles[0]?.iconUrl || '',
            missingPhoto: false,
            zendeskUrl: element.angle?.productTypeAngles[0]?.zendeskUrl || null,
          },
        };
      }

      return result;
    }, PROBLEMS_INDEX_DEFAULT_VALUE);

    const matchingProblematicIndex = problematicIndexes?.find(
      (problem) => problem.angleId === AllPhotosSameIndex[0]?.angleId,
    );
    const imageSamples = matchingProblematicIndex?.angle?.imageSamples || null;

    if (problemsIndex) {
      return [...acc, { ...{ name: nameIndex, fileImage: AllPhotosSameIndex, imageSamples }, ...problemsIndex }];
    }

    return [
      ...acc,
      {
        ...{ name: nameIndex, fileImage: AllPhotosSameIndex, imageSamples },
        ...{ ...PROBLEMS_INDEX_DEFAULT_VALUE, orderIndex: AllPhotosSameIndex[0].orderIndex },
      },
    ];
  }, []);

  // если есть фотографии удовлетворяющие условию отбора missingPhoto
  // подмешиваем их к основному массиву
  if (missingPhoto) {
    return sortedOrderIndex([...arrAllPhotosSameIndex, ...missingPhoto]);
  }

  return sortedOrderIndex(arrAllPhotosSameIndex);
};

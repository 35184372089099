import React, { useState } from 'react';
import styled from 'styled-components';
import { Info } from '../../../common/icons/SvgInline/SvgIcons_5';
import { COLOR_MAP } from '../../colorsMap';
import { Z_INDEX_LAYERS } from '../../constants';
import { adaptive } from '../../screen';
import { Text } from '../Text';
import { TriangleIcon } from './TriangleIcon';

const StyledTooltip = styled(Text)<{ widthTooltip: string; positionMobile?: 'default' | 'left' }>`
  position: absolute;
  box-sizing: border-box;
  font-family: Roboto, sans-serif;
  font-size: 11px;
  line-height: 14px;

  ${({ widthTooltip }) => `width: ${widthTooltip};`}

  bottom: 30px;
  left: -5px;

  padding: 10px 15px 10px 10px;

  background-color: ${COLOR_MAP.text.white};
  filter: drop-shadow(0px 5px 14px rgba(0, 0, 0, 0.08)) drop-shadow(0px 4px 9px rgba(150, 112, 38, 0.05));

  border-radius: 7px;
  z-index: ${Z_INDEX_LAYERS.other};

  ${adaptive.maxWidth.mobile} {
    bottom: 28px;
    left: -110px;

    ${({ positionMobile }) => positionMobile === 'left' && 'left: -240px;'}

    font-size: 9px;
    line-height: 11px;
  }
`;

const TriangleIconWrapper = styled.div`
  position: absolute;

  bottom: 14px;
  right: 7px;
  transform: rotate(180deg);
  filter: drop-shadow(0px 5px 14px rgba(0, 0, 0, 0.08)) drop-shadow(0px 4px 9px rgba(150, 112, 38, 0.05));

  ${adaptive.maxWidth.mobile} {
    bottom: 12px;
  }
`;

type Props = {
  text: string | JSX.Element;
  widthTooltip?: string;
  positionMobile?: 'default' | 'left';
};

export const InfoTooltip = ({ text, widthTooltip = '156px', positionMobile }: Props) => {
  const [isShow, setIsShow] = useState(false);

  return (
    <div style={{ position: 'relative' }}>
      {/* eslint-disable-next-line jsx-a11y/mouse-events-have-key-events */}
      <div
        onMouseOver={() => setIsShow(true)}
        onMouseOut={() => setIsShow(false)}
        style={{ cursor: 'pointer', height: '21px' }}
      >
        <Info size="20" />
      </div>
      {isShow && (
        <>
          <TriangleIconWrapper>
            <TriangleIcon />
          </TriangleIconWrapper>

          <StyledTooltip widthTooltip={widthTooltip} positionMobile={positionMobile}>
            {text}
          </StyledTooltip>
        </>
      )}
    </div>
  );
};

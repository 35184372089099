import React from 'react';
import styled, { CSSProperties } from 'styled-components';

import { COLOR_MAP } from '../componentUI/colorsMap';
import { TEXT_SIZE, Text } from '../componentUI/simple/Text';

import { SkipOutcomeIcon } from '../common/icons/SvgInline/SvgIcons_1';

import { NameStatus } from '../api/products/type';

const StatusProductInfoStyled = styled.div<{ isCard: boolean }>`
  display: grid;
  grid-template-columns: auto 1fr;
  gap: 8px;
  flex-direction: ${({ isCard }) => (isCard ? 'row-reverse' : 'row')};
  align-items: center;

  white-space: nowrap;
`;

const StatusIndicatorStyled = styled.div<{ colorIndicator: string }>`
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: ${({ colorIndicator }) => colorIndicator};
`;

const stylesForText: CSSProperties = {
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
};

const STATUS_DICTIONARY = {
  'Photos needed': COLOR_MAP.accent.red,
  'Under review': COLOR_MAP.accent.blue,
  Completed: COLOR_MAP.accent.green,
  Drafts: COLOR_MAP.accent.grey20,
};

/**
 * получение информации для поля Status заказа пользователя,
 * формируется пара цвет/текст исходя из полученного resultStatus: { name } заказа
 *
 * isCard - флаг который меняет оформление на то которое используется в
 * карточке заказа
 * isBigSize - флаг, выставляет размер шрифта равным TEXT_SIZE.regular
 */

export const getStatusProductInfo = (status: NameStatus | null, isCard = false, isBigSize = false) => {
  if (status) {
    return (
      <StatusProductInfoStyled isCard={isCard}>
        <StatusIndicatorStyled colorIndicator={STATUS_DICTIONARY[status]} />

        <Text style={stylesForText} size={isCard || isBigSize ? TEXT_SIZE.regular : TEXT_SIZE.sub}>
          {status}
        </Text>
      </StatusProductInfoStyled>
    );
  }

  return (
    <StatusProductInfoStyled isCard={isCard}>
      <SkipOutcomeIcon />
    </StatusProductInfoStyled>
  );
};

import React from 'react';
import { useNavigate } from 'react-router-dom';

import { sendUserEvent } from '../../../../analytics';

import { Button, BUTTON_SIZE, SecondButton } from '../../../../componentUI/simple/Button';

import { AuthButtonIcon } from '../../../../common/icons/SvgInline/SvgIcons_6';
import { PlusIcon } from '../../../../common/icons/SvgInline/SvgIcons_9';

import { ButtonBlockStyled } from './styled';
import { Props } from './types';

export const ButtonBlock = ({ closeHandler }: Props) => {
  const navigate = useNavigate();

  const addBalanceHandler = () => {
    sendUserEvent('Top up now button clicked');
    navigate('../top-up');

    if (closeHandler) {
      closeHandler();
    }
  };

  const newAuthenticationHandler = () => {
    sendUserEvent('New authentication button clicked');
    navigate('../authenticate');

    if (closeHandler) {
      closeHandler();
    }
  };

  return (
    <ButtonBlockStyled>
      <Button isStretch size={BUTTON_SIZE.small} onClick={newAuthenticationHandler}>
        <AuthButtonIcon /> AUTHENTICATE
      </Button>

      <SecondButton isStretch size={BUTTON_SIZE.small} onClick={addBalanceHandler}>
        <PlusIcon /> ADD BALANCE
      </SecondButton>
    </ButtonBlockStyled>
  );
};

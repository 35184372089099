import React from 'react';
import Select from 'react-select';

import { DropdownIndicator } from '../../common';

import { usePagination } from '../../hooks';

import { COLOR_MAP } from '../../componentUI/colorsMap';
import { Text } from '../../componentUI/simple/Text';

import { Dotted } from './Dotted';
import { ItemPag, PaginationItemsWrapper, PaginationSelectWrapper, PaginationWrapper } from './styled';
import { Props } from './types';

const PAG_OPTIONS = [
  { key: '10', value: '10', label: '10' },
  { key: '20', value: '20', label: '20' },
  { key: '30', value: '30', label: '30' },
  { key: '40', value: '40', label: '40' },
] as const;

/** Пагинация (разделение документа на отдельные страницы)
 * для таблицы AuthenticationsTablet */

export const Pagination = ({ pageNumber, setPageNumber, limit, setLimit, itemCount = 0 }: Props) => {
  const paginationRange = usePagination({
    currentPage: pageNumber,
    totalCount: itemCount,
    siblingCount: 1,
    pageSize: Number(limit.value),
  });

  if (!itemCount) return null;

  return (
    <PaginationWrapper>
      <PaginationSelectWrapper>
        <Select
          components={{ DropdownIndicator }}
          classNamePrefix="custom-select"
          options={PAG_OPTIONS}
          value={limit}
          onChange={(option) => {
            if (option) {
              setLimit(option);
            }
          }}
          menuPlacement="auto"
        />

        <Text colorText={COLOR_MAP.text.darkGrey}>Show on page</Text>
      </PaginationSelectWrapper>

      <PaginationItemsWrapper>
        <ItemPag
          onClick={() => {
            if (pageNumber !== 1) {
              setPageNumber(pageNumber - 1);
            }
          }}
          isActive={pageNumber === 1}
        >
          {'<'}
        </ItemPag>

        {paginationRange?.map((pagValue) => {
          if (typeof pagValue === 'number') {
            return (
              <ItemPag
                onClick={() => {
                  setPageNumber(pagValue);
                }}
                isActive={pagValue === pageNumber}
                key={pagValue}
              >
                {pagValue}
              </ItemPag>
            );
          }

          return (
            <div style={{ display: 'flex', alignItems: 'center' }} key={pagValue}>
              <Dotted />
            </div>
          );
        })}

        <ItemPag
          onClick={() => {
            if (paginationRange && pageNumber !== paginationRange[paginationRange.length - 1]) {
              setPageNumber(pageNumber + 1);
            }
          }}
          isActive={paginationRange && pageNumber === paginationRange[paginationRange.length - 1]}
        >
          {'>'}
        </ItemPag>
      </PaginationItemsWrapper>
    </PaginationWrapper>
  );
};

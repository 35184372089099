import styled, { CSSProperties } from 'styled-components';
import { COLOR_MAP } from '../../../../../../componentUI/colorsMap';

const UploadingNewRequestedPhotoStyled = styled.div`
  grid-area: uploadPhotos;

  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const WrapperElementsStyled = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
`;

const ContainerPictureStyled = styled.div`
  position: relative;
  display: flex;
`;

const ImageIndexStyled = styled.img<{ isBorder: boolean }>`
  border: ${({ isBorder }) => (isBorder ? `2px solid ${COLOR_MAP.accent.gold}` : 'none')};
  border-radius: 6px;
  object-fit: cover;
`;

const stylesForButtons: CSSProperties = {
  position: 'absolute',
  right: '-4px',
  top: '-4px',

  padding: '4px',

  backgroundColor: COLOR_MAP.background.white,
  boxShadow: '0px 4px 9px rgba(150, 112, 38, 0.05), 0px 5px 14px rgba(0, 0, 0, 0.08)',
  borderRadius: '4px',
};

export {
  ContainerPictureStyled,
  ImageIndexStyled,
  stylesForButtons,
  UploadingNewRequestedPhotoStyled,
  WrapperElementsStyled,
};

import { NAME_OBJECT_GET_PRODUCT, SelectDropDownListType } from '../types';

const INCLUDE_VALUER_DROPDOWNLIST_ONE = [
  { key: 'is', value: true, label: 'is' },
  { key: 'is not', value: false, label: 'is not' },
];

const INCLUDE_VALUER_DROPDOWNLIST_TWO = [
  { key: 'contains', value: true, label: 'contains' },
  { key: 'doesn’t contain', value: false, label: 'doesn’t contain' },
];

const VALUER_DROPDOWNLIST_DATE = [
  { key: 'Today', value: 'today', label: 'Today' },
  { key: 'Last 7 days', value: 'last7', label: 'Last 7 days' },
  { key: 'Last 30 days', value: 'last30', label: 'Last 30 days' },
  { key: 'Last 90 days', value: 'last90', label: 'Last 90 days' },
  { key: 'Custom', value: 'custom', label: 'Custom' },
];

const { publicId, date, brand, modelName, sku, checkStatus } = NAME_OBJECT_GET_PRODUCT;

type formingDropDownListType = {
  masterFilter: NAME_OBJECT_GET_PRODUCT | null | undefined;
  filterID: string;
};

/** формирует объект который используется для отрисовки выпадающих списков
 * в зависимости от выбора сделанного в мастер списке  */

export const formingDropDownList = ({
  masterFilter,
  filterID,
}: formingDropDownListType): SelectDropDownListType | null => {
  switch (masterFilter) {
    case publicId:
      return {
        id: filterID,
        nameObject: publicId,
        dropDownList: INCLUDE_VALUER_DROPDOWNLIST_TWO,
      };
    case date:
      return {
        id: filterID,
        nameObject: date,
        dropDownList: VALUER_DROPDOWNLIST_DATE,
      };
    case brand:
      return {
        id: filterID,
        nameObject: brand,
        dropDownList: INCLUDE_VALUER_DROPDOWNLIST_ONE,
      };
    case modelName:
      return {
        id: filterID,
        nameObject: modelName,
        dropDownList: INCLUDE_VALUER_DROPDOWNLIST_TWO,
      };
    case sku:
      return {
        id: filterID,
        nameObject: sku,
        dropDownList: INCLUDE_VALUER_DROPDOWNLIST_TWO,
      };
    case checkStatus:
      return {
        id: filterID,
        nameObject: checkStatus,
        dropDownList: INCLUDE_VALUER_DROPDOWNLIST_ONE,
      };
    default:
      return null;
  }
};

import styled from 'styled-components';

import { LogoMain } from '../../common/images';

const LogoStyled = styled.div`
  width: 40px;
  height: 40px;

  background-image: url(${LogoMain});
  background-size: cover;

  cursor: pointer;
`;

export { LogoStyled };

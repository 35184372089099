/* eslint-disable max-len */
export const CONTENT = [
  {
    id: 1,
    title: 'Designer Item Authentication',
    text: 'Gain assurance through product verification, enabling confident use or seamless resale of branded items.',
  },
  {
    id: 2,
    title: 'Hybrid Authentication Solution',
    text: 'Receive fast, accurate authentication results through our unique combination of human expertise and AI technology.',
  },
  {
    id: 3,
    title: 'Extensive Brand Coverage',
    text: 'Ensure the authenticity of items across more than 250 designer brands, covering all product categories.',
  },
];

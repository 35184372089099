import Cookies from 'js-cookie';
import { instance } from '../axios-instance';
import {
  GetBrandListRequestType,
  GetBrandTypeRequestType,
  GetBrandTypeResponseType,
  GetBrandsListResponseType,
} from './types';

export const getBrandsList = (data: GetBrandListRequestType) => {
  return instance.get<GetBrandsListResponseType>('brands', {
    params: { limit: data?.limit || 400, page: data?.page || 1 },
    headers: { Authorization: `Bearer ${Cookies.get('jwt')}` },
  });
};

export const getBrandType = (data: GetBrandTypeRequestType) => {
  return instance.get<GetBrandTypeResponseType>('brand-types', {
    params: { limit: data?.limit || 400, page: data?.page || 1 },
    headers: { Authorization: `Bearer ${Cookies.get('jwt')}` },
  });
};

/* eslint-disable react/no-array-index-key */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';

import { FileTemplateHEIC } from '../../../../../../common/images';

import { useUpdatePhotoProductMutate } from '../../../../../../api/file/hooks/useUpdatePhotoProductMutate';

import { useMatchMedia } from '../../../../../../hooks';

import { Skeleton } from '../../../../../../componentUI/complex/Skeleton';
import { BrandedButton } from '../../../../../../componentUI/simple/Button';
import { Text, TEXT_WEIGHT } from '../../../../../../componentUI/simple/Text';

import { TrashIcon } from '../../../../../../common/icons/SvgInline/SvgIcons_2';

import { inputPhotoClear } from '../../../../../../helpers';

import { handleDeleteImage } from '../../../../helpers';
import { UploadPhotosIndexType } from '../../../../types';

import {
  ContainerPictureStyled,
  ImageIndexStyled,
  stylesForButtons,
  UploadingNewRequestedPhotoStyled,
  WrapperElementsStyled,
} from './styled';
import { Props } from './types';

export const UploadingNewRequestedPhoto = ({
  inputRef,
  angleId,
  productId,
  files,
  setFiles,
  setAllUploadedPhotos,
  setIndexUploadedPhotos,
  setIsErrorLimitUploadedImages,
  setIsLoadingUploadedPhotos,
}: Props) => {
  const { isMobile } = useMatchMedia();

  // количество загруженных фотографий на бэк в текущем индексе
  const [uploadPhotos, setUploadPhotos] = useState<Array<UploadPhotosIndexType>>([]);

  const {
    mutate: updatePhoto,
    data: dataUpdatePhoto,
    isLoading: isLoadingUpdatePhoto,
    isSuccess: isSuccessUpdatePhoto,
    isError: isErrorUploadingPhoto,
  } = useUpdatePhotoProductMutate();

  useEffect(() => {
    if (files.length > 0) {
      const idempotencyKey = crypto.randomUUID();
      updatePhoto({ productFileInfo: { angleId, productId, photos: files }, idempotencyKey });
    }
  }, [files]);

  // если выбранные файлы загрузились в индекс то отчищаем массив с
  // ранее выбранными файлами пользователем (files) и добавляем их
  // к общим загруженным файлам для заказа
  useEffect(() => {
    if (dataUpdatePhoto) {
      const photoId: Array<string> = [];

      const photos = dataUpdatePhoto.map(({ id, url }) => {
        photoId.push(id);
        return { path: url, fileId: id, angleId, isUnableProvide: false };
      });

      setAllUploadedPhotos((prev) => [...prev, ...photos]);

      setUploadPhotos((prev) => [...prev, ...photos]);

      setIndexUploadedPhotos((prev) => [...prev, ...photoId]);

      setFiles([]);
    }
  }, [isSuccessUpdatePhoto]);

  useEffect(() => {
    setIsLoadingUploadedPhotos(isLoadingUpdatePhoto);
  }, [isLoadingUpdatePhoto]);

  // при возникновении ошибки удаляем массив с выбранными пользователем файлами
  // и сбрасываем состояние поля input
  useEffect(() => {
    if (isErrorUploadingPhoto) {
      inputPhotoClear(inputRef);
      setFiles([]);
    }
  }, [isErrorUploadingPhoto]);

  return (
    <UploadingNewRequestedPhotoStyled>
      <Text weight={TEXT_WEIGHT.bold}>Upload photos</Text>

      <WrapperElementsStyled>
        {uploadPhotos.length > 0 &&
          uploadPhotos.map((photo) => {
            const fileExtension = photo.path.split('.').pop();

            return (
              <ContainerPictureStyled key={photo.fileId}>
                <BrandedButton
                  style={stylesForButtons}
                  onClick={() =>
                    handleDeleteImage({
                      setIndexUploadedPhotos,
                      setAllUploadedPhotos,
                      setUploadPhotos,
                      setIsErrorLimitUploadedImages,
                      inputRef,
                      fileId: photo.fileId || '',
                    })
                  }
                >
                  <TrashIcon />
                </BrandedButton>

                {fileExtension && fileExtension.toLowerCase() !== 'heic' && (
                  <ImageIndexStyled
                    isBorder={false}
                    height={isMobile ? 128 : 90}
                    width={isMobile ? 128 : 90}
                    src={photo.path}
                    alt="loaded picture"
                  />
                )}

                {fileExtension && fileExtension.toLowerCase() === 'heic' && (
                  <ImageIndexStyled
                    isBorder={false}
                    height={isMobile ? 128 : 90}
                    width={isMobile ? 128 : 90}
                    src={FileTemplateHEIC}
                    alt="loaded picture"
                  />
                )}
              </ContainerPictureStyled>
            );
          })}

        {isLoadingUpdatePhoto &&
          files.map((_, index) => {
            return (
              <Skeleton
                key={index}
                variant="rounded"
                height={isMobile ? '128px' : '90px'}
                width={isMobile ? '128px' : '90px'}
              />
            );
          })}
      </WrapperElementsStyled>
    </UploadingNewRequestedPhotoStyled>
  );
};

import React, { useEffect } from 'react';

import './App.css';

import * as analytics from './analytics/analytics';

import { AppRouter } from './components';

export const App = () => {
  useEffect(() => {
    analytics.init();
  }, []);

  return (
    <div className="App">
      <AppRouter />
    </div>
  );
};

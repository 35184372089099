import { SelectedSetFiltersType } from '../../../../../containers/AuthenticationsTablet/types';
import { OPTIONS_MASTER_DROPDOWN_LIST } from '../constants';

/** убирает уже выбранные фильтры из мастер выпадашки  */

export const getOptionsMasterSelect = (selectedSetFilters: SelectedSetFiltersType) => {
  const keys = Object.keys(selectedSetFilters);

  const nameObject = keys.map((key) => {
    return selectedSetFilters[key].nameObject;
  });

  const options = OPTIONS_MASTER_DROPDOWN_LIST.filter(({ value }) => {
    return !nameObject.includes(value);
  });

  return options;
};

/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import Select from 'react-select';

import { usePrevious } from '../../../../../../../../hooks';

import { DropdownIndicator } from '../../../../../../../../common';

import { Input } from '../../../../../../../../componentUI/simple/Input';

import { objectFormation } from '../../helpers';
import { DropDownListType } from '../../types';

import { FilterSelectTypeOneStyled } from './styled';
import { Props } from './types';

const DELAY_TIME_SENDING = 2000; // ms

/** Фильтр осуществляет поиск по id заказа - поле publicId */

export const FilterSelectTypeOne = ({ selectDropDownList, setSelectedSetFilters }: Props) => {
  const { dropDownList, id, nameObject } = selectDropDownList;

  // содержит информацию о том, новое ли значение выбрано в текущем фильтре из мастер-селекта
  const [isNewMasterDropdownList, setIsNewMasterDropdownList] = useState(false);

  // текущее значение из поля ввода
  const [inputValue, setInputValue] = useState('');

  // выбранный параметр поиска пользователем
  const [includeValue, setIncludeValue] = useState<DropDownListType>(dropDownList[0]);

  // предыдущее значение из поля ввода
  const prevInputValue = usePrevious(inputValue);

  // предыдущее значение nameObject
  const prevNameObject = usePrevious(nameObject);

  // задержка по времени, перед отправкой данных в запрос products/get-products
  useEffect(() => {
    // условие проверяет текущее значение пользователя которое он ввел
    // и предыдущее, отрабатывает кейс когда надо перезапросить данные
    // для пустого поля, но при этом не нужно что бы при добавлении нового поля
    // уходил пустой запрос
    if (inputValue === prevInputValue) {
      return;
    }

    const delaySending = setTimeout(
      () =>
        setSelectedSetFilters((prev) => {
          const settingSelect = objectFormation({
            nameObject,
            idFilter: id,
            parameterOne: inputValue,
            parameterTwo: includeValue?.value || false,
          });

          return {
            ...prev,
            ...settingSelect,
          };
        }),
      DELAY_TIME_SENDING,
    );

    return () => clearTimeout(delaySending);
  }, [inputValue]);

  useEffect(() => {
    setSelectedSetFilters((prev) => {
      const settingSelect = objectFormation({
        nameObject,
        idFilter: id,
        parameterOne: inputValue,
        parameterTwo: includeValue?.value || false,
      });

      return {
        ...prev,
        ...settingSelect,
      };
    });
  }, [includeValue]);

  // сбрасываем ранее выбранные значения в текущем поле поиска
  useEffect(() => {
    // если значения пустое, обработку не запускаем
    if (!nameObject || !prevNameObject) {
      return;
    }

    setInputValue('');
    setIncludeValue(dropDownList[0]);
    setIsNewMasterDropdownList(true);
  }, [nameObject]);

  // если пользователь перевыбрал значение из мастер-селекта, то данные будут сброшены
  // на дефольтные, для текущего значения местер-селекта которое он выбрал
  useEffect(() => {
    if (isNewMasterDropdownList) {
      setSelectedSetFilters((prev) => {
        const settingSelect = objectFormation({
          nameObject,
          idFilter: id,
          parameterOne: inputValue,
          parameterTwo: includeValue?.value || false,
        });

        return {
          ...prev,
          ...settingSelect,
        };
      });
      setIsNewMasterDropdownList(false);
    }
  }, [isNewMasterDropdownList]);

  return (
    <FilterSelectTypeOneStyled>
      <Select
        components={{ DropdownIndicator }}
        options={dropDownList}
        classNamePrefix="custom-select"
        value={includeValue}
        onChange={(option) => {
          if (option) {
            setIncludeValue(option);
          }
        }}
        isSearchable={false}
      />

      <Input
        onChange={(event) => {
          setInputValue(event);
        }}
        value={inputValue}
      />
    </FilterSelectTypeOneStyled>
  );
};
